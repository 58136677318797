import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { Component } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminProtectedRoute from "../../AdminProtectedRoute";
import HomePage from "../../pages/home";
import ProtectedRoute from "../../ProtectedRoute";
import NewCenterForm from "./Centre/NewCenterForm";
import AdminNav from "./Nav/AdminNav";
import PatientsInfo from "./Patients/Patient";
import UserProfile from "./Profile/UserProfile";
import AllUsers from "./User/AllUsers";
import NewUserForm from "./User/NewUserForm";

export default function AdminDashBoard(props) {


  return (
    <>
      <div>
        <AdminNav />
      </div>
      <div>
        <Routes>
          <Route exact path="/" element={<ProtectedRoute  component={<UserProfile />} />} />
          <Route path="/create-center" element={<AdminProtectedRoute component={<NewCenterForm />} /> } />
          <Route path="/create-user" element={<AdminProtectedRoute component={<NewUserForm />} /> } />
          <Route path="/users" element={<AdminProtectedRoute component={<AllUsers />} /> } />
          <Route path="/patients" element={<ProtectedRoute component={<PatientsInfo />} /> } />

        </Routes>
      </div>
    </>
  );
}
