import { Button, Collapse, IconButton } from '@material-ui/core';
import { ActionTypes } from '@mui/base';
import { Alert } from '@mui/material';
import { create } from '@mui/material/styles/createTransitions';
import { height } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateableSelect from 'react-select/creatable';
import { createCenter, fetchCenters } from '../../../api_calls/CallList';
import LoadingGif from '../../../LoadingGif';

export default function NewCenterForm() {
    const [data, setData] = useState(null);
    const navigate = useNavigate()
    const [Input, setInput] = useState({ country: null, state: null, center_unique: null });

    const [alert, setAlert] = useState({ display: false, msg: null, severe: 'success' })
    const createNewCenter = async () => {
        console.log(Input);
        if (!Input.center_unique || !Input.country || !Input.state) {
            setAlert({ display: true, msg: "An important field was not filled", severe: 'error' })
        }
        else {
            createCenter(Input.country, Input.state, Input.center_unique).then(e => {
                setAlert({ display: true, msg: "Center created successfully", severe: 'success' })

            }).catch(error => {
                setAlert({ display: true, msg: "An Error Occurred", severe: 'error' })

            })

        }

    }



    useEffect(() => {
        fetchCenters().then((data) => {


            const finalData = { country: [], state: [], center_unique: [] }
            data.country.forEach(e => finalData.country.push({ value: e.name, label: e.name }));
            data.state.forEach(e => finalData.state.push({ value: e.name, label: e.name }));
            data.center_unique.forEach(e => finalData.center_unique.push({ value: e.name, label: e.name }));

            setData(finalData);
        }).catch(e => navigate('/'))

    }, []);
    const items = [{ text: "Enter the name of the Country", type: 'country' }, { text: "Enter the name of the State", type: 'state' }, { text: "Enter the Unique Identifier of the Center", type: 'center_unique' }];

    return <>
        <Collapse in={alert.display}>
            <Alert
                severity={alert.severe}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setAlert({ display: false });
                        }}
                    >
                        Close
                    </IconButton>
                }
                sx={{ mb: 2 }}
            >
                {alert.msg}
            </Alert>
        </Collapse>

        {data ?
            <div className='h-screen align-middle flex flex-col items-center justify-center' >
                <div className='relative w-full text-center font-bold text-3xl pt-5 pb-3 align-middle justify-center'>NEW CENTER</div>

                <div className='p-10 m-10 relative rounded-2xl shadow items-center align-middle  justify-center flex border-2'>
                    <div className='mt-5' >
                        {items.map(e => <> <p>{e.text}</p> <CreateableSelect

                            isClearable={true}
                            options={data[e.type]}
                            onChange={(ip, action) => {
                                if (action.action == 'select-option') {
                                    setInput({ ...Input, [e.type]: ip.label })
                                }
                                if (action.action === 'create-option') {
                                    data[e.type].push({ value: ip.value, label: ip.label })
                                    setData(data)
                                    setInput({ ...Input, [e.type]: ip.label })

                                }
                            }}

                        /><br /></>)}

                        <button
                            onClick={createNewCenter}
                            className="w-full text-white bg-indigo-500 border-0 py-2 focus:outline-none hover:bg-indigo-600 rounded mb-10 text-lg"
                        >Create Center</button>
                    </div>

                </div>
            </div>

            : <LoadingGif />}

    </>



}