import axios from "axios";
import { getAuth } from "firebase/auth";
import {RouteResponse} from "./RouteResponse";

class APIController {
  static async makeAPIRequest({
    url = '',
    body = {},
    headers = {},
    type = "GET",
    authRequired = true,
  }) {
    try {
      let token = null;
      if (authRequired) {
        if (!getAuth().currentUser)
          return [
            null,
            { message: "You're not logged in", redirect: "/login" },
          ];
        token = await getAuth().currentUser.getIdToken();
        headers.Authorization = token;
      }
      let response;
      if (type == 'GET') {
        response = await axios.get(url, { headers: headers })
      }
      else {
        response = await axios.post(url, body, { headers: headers })


      }
      return new RouteResponse(response.data);
    } catch (error) {
      throw error;
    }
  };
}

export default APIController;

