import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CardLeft from "../components/home/cardLeft";
import CardRight from "../components/home/cardRight";
import Home from "../components/home/home";
import Navbar from "../components/navbar";

function HomePage() {
  let nav = useNavigate();

  return (
    <div>

      <Home />
      <div className="flex flex-wrap justify-center">
        <div className="w-11/12">
          <CardLeft
            content="Manage Treatment more efficiently , more personally.
          Follow up the progress of your patient in your ambulatory clinic
          All previous stone history stored in one database"
            title="UROLOGIST"
            img="./assets/home/first.jpg"
            button="START THE METABOLIC WORK UP"
            onButton1Click={() => {
              nav("/terms");
            }}
          />{" "}
          <CardLeft
            content="Be part of the Metabolic clinic and the management of Urinary Stone Patients"
            title="NURSE / ASSISTANT"
            img="./assets/home/third.jpg"
            button="START PATIENT'S EVALUATION"
            onButton1Click={() => {
              console.log("HELLo");
              nav("/terms");
            }}
          />
          <CardRight
            content="Perform your own Dietary assessment and Set your own Limits
      Keep track with your results and medical History"
            title="PATIENT"
            subtitle="6 Things You Need Right Now"
            img="./assets/home/second.jpg"
            button="START YOUR OWN DIETARY ASSESSMENT"
            onButton1Click={() => {
              nav("/dietry-survey");
            }}
            onButton2Click={() => {
              nav("/urine-survey");
            }}
            buttonTwo="MORNING URINE ANALYSIS RESULTS"
          />
          <CardRight
            content="Get to know the Background of Urinary stone disease
          See which Habits might put you in risk
          Learn about the different types of Treatments with clear and simple graphics"
            title="URINARY STONE DISEASE"
            //img="./assets/home/forth.jpg"
            button="LEARN NOW"
            buttonTwo=""
            onButton1Click={() => {
              nav("/background");
            }}
          />
        </div>
        <div className=" w-10/12 mt-12 border-2 font-bold p-6 text-gray-900 text-center border-gray-900 hover:text-white hover:bg-gray-900 ease-out duration-1000">
          <p>
            WAIVER AND RELEASE OF LIABILITY: THIS WEBSITE IS TO BE USED AS AN
            ADVISORY TOOL AND ONLY AS SUCH. ALL FINAL MEDICAL DECISIONS
            REGARDING MANAGEMENT OF THE PATIENT'S DISEASE AND TREATMENT MUST BE
            TAKEN BY THE TREATING PHYSICIAN. THE WEBSITE DOES NOT HOLD ANY
            RESPONSIBILITY REGARDING THE ACTIONS TAKEN BY THE USERS OF THE
            WEBSITE.
          </p>
        </div>
        {/* <div className="mt-6 mb-12 text-center">
          <p className="font-bold text-gray-900 mb-2 text-xl">Our Partners</p>
          <p className="text-md px-6">
            Edit this text to make it your own. To edit, click directly on the
            text to start adding your own words.
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default HomePage;
