import { Collapse, DialogContent, IconButton } from "@material-ui/core";
import Select from "react-select";

import { Alert, Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { adminUpdateUser, getAllCenters } from "../../../api_calls/CallList";
import LoadingGif from "../../../LoadingGif";
import Input from "../../Input/input";

export default function ModifyUserDialog({ user, callback, isOpen = false }) {
  const [selectable, setselectable] = useState(undefined);

  const [ip, setip] = useState(undefined);
  const [alert, setAlert] = useState({ display: false });
  const [buttonDisabled, setDisabled] = useState(false);
  const originalData = useRef({});
  const updateUser = async (input) => {
    setDisabled(true);
    try {
      const updateData = {};
      for (const [key, value] of Object.entries(ip)) {
          if (value !== originalData.current[key]) {
            updateData[key] = value;
          }
      }

      if (Object.keys(updateData).length > 0) {
        updateData.uid = ip.uid;
        await adminUpdateUser(updateData);

        setAlert({ severe: "success", display: true, msg: "Success" });
      } else {
        setAlert({ severe: "info", display: true, msg: "Update any field" });
      }
    } catch (error) {
      setAlert({ severe: "error", display: true, msg: error.toString() });
    } finally {
      setDisabled(false);
    }
  };

  useEffect(
    (e) => {
      if (isOpen) {
        getAllCenters()
          .then((data) => {
            const finalData = [];
            data.forEach((e) => {
              finalData.push({
                label: e.countryId + " - " + e.stateId + " - " + e.centerUnique,
                value: e.id,
              });
            });

            originalData.current = user;
            setip(user);
            setselectable(finalData);
          })
          .catch(callback);
      }
    },
    [isOpen]
  );
  const items = [{ text: "Select the Center", type: "center" }];

  if (!isOpen) return <></>;
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <div>
          <Collapse in={alert.display}>
            <Alert
              severity={alert.severe}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlert({ display: false });
                  }}
                >
                  Close
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alert.msg}
            </Alert>
          </Collapse>

          {selectable ? (
            <div className="p-10 mt-30 flex flex-col w-full items-center justify-center mb-10">
              <div className="mt-10 w-full text-center font-bold text-3xl pb-5 align-middle justify-center">
                EDIT USER
              </div>

              <div className="rounded-2xl shadow items-center align-middle p-10 justify-center flex-col flex border-2">
                <div>
                  <Input
                    isRequired={true}
                    Name={"name"}
                    defaultValue={ip.name}
                    OnChange={(e) => setip({ ...ip, name: e.target.value })}
                    htmlFor={"name"}
                    TextVal={"Name of the User"}
                    Placeholder={"Enter the name"}
                  />
                  <label
                    htmlFor="sex"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Gender <span className="text-red-500">*</span>
                  </label>
                  <br />
                  <select
                    defaultValue={ip.gender}
                    onChange={(e) => setip({ ...ip, gender: e.target.value })}
                    name="sex"
                    className="bg-gray-100 w-full rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  >
                    <option value="" disabled hidden>
                      Select your gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                  <div className="mt-4"></div>
                  <Input
                    Type={"email"}
                    isRequired={true}
                    Name={"email"}
                    defaultValue={ip.email}
                    OnChange={(e) => setip({ ...ip, email: e.target.value })}
                    htmlFor={"email"}
                    TextVal={"Email of the User"}
                    Placeholder={"Enter the Email"}
                  />
                  <Input
                    Type={"password"}
                    isRequired={true}
                    Name={"password"}
                    OnChange={(e) => setip({ ...ip, password: e.target.value })}
                    htmlFor={"password"}
                    TextVal={"Update password"}
                    Placeholder={"Enter a password"}
                  />{" "}
                  {items.map((e) => (
                    <>
                      {" "}
                      <p className="text-sm">{e.text}</p>{" "}
                      <Select
                        style={{
                          width: "100%",
                        }}
                        defaultValue={
                          ip.country +
                          " - " +
                          ip.state +
                          " - " +
                          ip.center_unique
                        }
                        defaultInputValue={
                          ip.country +
                          " - " +
                          ip.state +
                          " - " +
                          ip.center_unique
                        }
                        isClearable={true}
                        options={selectable}
                        onChange={(input, action) => {
                          if (action.action === "select-option") {
                            setip({ ...ip, center: input.value });
                          }
                        }}
                      />
                      <br />
                    </>
                  ))}
                  <button
                    disabled={buttonDisabled}
                    onClick={updateUser}
                    className="mt-6 disabled:bg-red-500 w-full text-white bg-indigo-500 border-0 py-2 focus:outline-none hover:bg-indigo-600 rounded mb-2 text-lg"
                  >
                    Update User
                  </button>
                  <button
                    onClick={callback}
                    //  disabled={buttonDisabled}
                    //onClick={createNewUser}
                    className="disabled:bg-red-500 w-full text-white bg-indigo-500 border-0 py-2 focus:outline-none hover:bg-indigo-600 rounded mb-10 text-lg"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <LoadingGif />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
