import { Checkbox } from "@material-ui/core";
import { useState } from "react";

let CustomCheckBox = (props) => {
  return props.content.map((item, index) => {
    const handleval = () => {
      if (item.value == undefined) {
        item.value = true;
      } else item.value = !item.value;
      item.handleChange(item);
    };
    console.log(item);

    return (
      <div onClick={handleval} id={index}>
        <div key={index} id={index} className="flex mt-6 items-center">
          <Checkbox
            id={item.name}
            name={item.name}
            checked={item.value}
            value={item.value}
            className="mr-4"
          ></Checkbox>
          

          <div>
            <label htmlFor={item.name} className="font-semibold">
              {item.name}
            </label>
          </div>
        </div>
      </div>
    );
  });
};

export default CustomCheckBox;
