import axios from "axios";
import { Route } from "react-router-dom";
import APIController from "./APIController";
import { RouteResponse } from "./RouteResponse";

const fetchUser = async () => {
  try {
    const response = await APIController.makeAPIRequest({ url: '/api/getUser', authRequired: true });
    return response.data;
  }
  catch (error) {
    throw error;
  }
};

async function createCenter(
  country,
  state,
  center_unique
) {
  try {
    const response = await APIController.makeAPIRequest({
      type: "POST", url: '/api/createCenter', body: {
        country, state, center_unique
      }, authRequired: true
    });

    return response;
  } catch (error) {
    throw error;
  }
}

async function createUser(data) {
  try {
    const res = await APIController.makeAPIRequest({ url: '/api/createUser', authRequired: true , type: "POST", body: data});
    if (res.hasError) throw res.message;
    console.log(res);
    return res.data;

  }
  catch (error) {
    console.log(error);
    throw error;
  }

}

async function getAllCenters() {
  try {
    const response = await APIController.makeAPIRequest({ url: '/api/getAllCenters', authRequired: true });

    if (response.hasError) {
      throw response.message;
    }
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
}
async function fetchCenters() {
  try {
    let data = {
      center_unique: undefined,
      country: undefined,
      state: undefined,
    };
    const unique = APIController.makeAPIRequest({ url: '/api/getPlace?place=center_unique', authRequired: true });
    const state = APIController.makeAPIRequest({ url: '/api/getPlace?place=state', authRequired: true });
    const country = APIController.makeAPIRequest({ url: '/api/getPlace?place=country', authRequired: true });

    let res = await unique;

    data["center_unique"] = res.data;
    res = await state
    data["state"] = res.data;
    res = await country;
    data["country"] = res.data;
    return data;
  } catch (error) {
    throw error;
  }
}


async function submitPatientInfo({ user, final_recommendations, user_diet }) {
  try {
    const res = await APIController.makeAPIRequest({
      type: "POST",
      url: '/api/submitpatientinfo', body: {
        user, final_recommendations, user_diet

      }, authRequired: true
    })
    if (res.hasError) {
      throw res.message;
    }
    return res.data;


  }
  catch (error) {
    throw error;
  }

}


async function getPatientInfo(id) {
  try {
    const res = await APIController.makeAPIRequest({ url: '/api/getpatient?id=' + id, authRequired: true });
    if (res.hasError) {
      throw res.message;
    }
    return res.data;

  }
  catch (error) {
    throw error;
  }

}


async function fetchAllUsers() {
  try {
    const res = await APIController.makeAPIRequest({ url: '/api/fetchAllUsers', authRequired: true });
    if (res.hasError) {
      throw res.message;
    }
    return res.data;

  }
  catch (error) {
    throw error;
  }
}

async function fetchPatientRecords(type) {
  try {

    const res = await APIController.makeAPIRequest({ url: '/api/fetchPatientRecords?type=' + type, authRequired: true, });
    if (res.hasError) {
      throw res.message;
    }
    return res.data;
  }
  catch (error) {
    throw error;
  }

}

async function updateUserProfile(body) {
  try {

    const res = await APIController.makeAPIRequest({ url: '/api/updateProfile', authRequired: true, body: body, type: 'POST' });
    if (res.hasError) {
      throw res.message;
    }
    return 'Success';
  }
  catch (error) {
    throw error;
  }

}

async function adminUpdateUser(body) {
  try {
    const res = await APIController.makeAPIRequest({ url: '/api/updateUser', authRequired: true, body: body, type: 'POST' });
    if (res.hasError) {
      throw res.message;
    }
    return 'Success';
  }
  catch (error) {
    throw error;
  }

}

async function updateSuperAdmin(body) {
  try {
    const res = await APIController.makeAPIRequest({ url: '/api/updateSuperAdmin', authRequired: true, body: body, type: 'POST' });
    if (res.hasError) {
      throw res.message;
    }
    return 'Success';
  }
  catch (error) {
    throw error;
  }

}



export {updateSuperAdmin, adminUpdateUser,  fetchAllUsers, getPatientInfo, fetchUser, fetchCenters, createCenter, getAllCenters, createUser, updateUserProfile, fetchPatientRecords, submitPatientInfo };
