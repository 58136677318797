import React from "react";

function CardLeft(props) {
  const { content, title, img, button } = props;
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font text-xl lg:text-4xl mb-4 font-bold text-gray-900">
            {title}
          </h1>
          <p className="mb-8 leading-relaxed">{content}</p>
          <div className="flex w-full md:justify-start justify-center items-end">
            <button 
            onClick={props.onButton1Click}
            className="inline-flex text-white font-semibold bg-gray-900 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 rounded md:text-xs lg:text-lg">
              {button}
            </button>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={img}
          />
        </div>
      </div>
    </section>
  );
}

export default CardLeft;
