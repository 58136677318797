import { useNavigate } from "react-router-dom";
import Footer from "../footer";
import Navbar from "../navbar";
const About = () => {
  let nav = useNavigate();
  return (
    <div
      className="h-full mt-5 mx-10 overflow-auto "
      style={{ height: "100vh" }}
    >
      <h1 className=" text-4xl text-bold mb-10">ABOUT US</h1>
      <p className="text-2xl space-x-2">
        GlobalStoneClinic.com is an open-access free tool to help treat and
        prevent the recurrence of urinary stones. The website serves Urologists
        by providing an easy to use and fast metabolic work up, and gives the
        patients tools to follow up their nutrition and risk for urinary stone
        formation. Read more{" "}
        <a
          onClick={() => {
            nav("/introduction-rational");
          }}
          style={{ fontWeight: "bold", color: "green" }}
        >
          Here
        </a>{" "}
        <br />
        <br />
        However, these are only recommendations for treatment and the decisions
        taken are only the treating doctor's responsibility.
        <br />
        <br /> There is no alternative to self-education, experience and good
        communication with the patient.
      </p>
    </div>
  );
};

export default About;
