//TODO ALGO 5

import React from "react";
import CustomCheckBox from "../custom_checkbox";
import CustomPopup from "../custom_popup";
import CustomRadioBox from "../custom_radio_box";
import Input from "../Input/input";
import {
  handleCystinePopupClose,
  handleDairyIntakePostSubmission,
  handleProtienIntakeSubmission,
  handleSaltIntakeSubmission,
  handleuricfators,
  handleUricSubmission,
} from "./PopSubmissionHandlers";
import user_diet from "./user_diet";

const ResultUrineData = ({ type, urinedata, setuc, emptyList }) => {
  const addlowSaltRecommendation = () => {
    setuc({
      ...urinedata,
      salt_recommendation: {
        ...urinedata.salt_recommendation,
        ["low_salt_recommendation"]:
          "Avoid foods and beverages with high salt content: \n-Table Salt\n-Sparkling water: Vichy, St Yorre, San Peligrino\n-Sauces (Ketchup, Soya)",
      },
    });
  };

  const ProtienIntake = () => {
    return (
      <div>
        <p>
          {
            "Verify cause of high urine urea- Probably high daily protein intake (>1.1gr/kg/day)"
          }
        </p>
        <CustomCheckBox
          content={[
            {
              name: "Animal Meat Products - A 100 gram steak has ~25% protein in it",
              value: urinedata.intake.animal_meat,
              handleChange: (val) => {
                // TODO ADD POINT SYSTEM FOR UREA NITOGEN
                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,

                    ["animal_meat"]: val.value,
                  },
                });
              },
            },

            {
              name: "Dairy Products and Vegetable Protein",
              value: urinedata.intake.dairy,
              handleChange: (val) => {
                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,

                    ["dairy"]: val.value,
                  },
                });
              },
            },

            {
              name: "Eggs (1 egg is ~30 grams of Protein)",
              value: urinedata.intake.egg,
              handleChange: (val) => {
                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,

                    ["egg"]: val.value,
                  },
                });
              },
            },
            // {
            //   name: "Charcuterie",
            //   value: urinedata.intake.charcuterie,
            //   handleChange: (val) => {
            //     setuc({
            //       ...urinedata,
            //       intake: {
            //         ...urinedata.intake,

            //         ["charcuterie"]: val.value,
            //       },
            //     });
            //   },
            // },
          ]}
        ></CustomCheckBox>
      </div>
    );
  };
  const SaltIntakeData = () => {
    return (
      <div>
        <p>{"Verify cause of high daily salt intake (>6gr daily"})</p>
        <CustomCheckBox
          content={[
            {
              name: "Table Salt",
              value: urinedata.intake.salt,
              handleChange: (val) => {
                addlowSaltRecommendation();
                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,
                    ["salt"]: val.value,
                  },
                });
              },
            },

            {
              name: "Sparkling Water (Vichy , St Yorre, San Pellegrino)",
              value: urinedata.intake.sparkling_water,
              handleChange: (val) => {
                addlowSaltRecommendation();

                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,
                    ["sparkling_water"]: val.value,
                  },
                });
              },
            },

            {
              name: "Sauces (Ketchup, Soya)",
              value: urinedata.intake.sauces,
              handleChange: (val) => {
                addlowSaltRecommendation();

                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,
                    ["sauces"]: val.value,
                  },
                });
              },
            },
            {
              name: "Pickles",
              value: urinedata.intake.pickle,
              handleChange: (val) => {
                addlowSaltRecommendation();

                setuc({
                  ...urinedata,
                  intake: {
                    ...urinedata.intake,
                    ["pickle"]: val.value,
                  },
                });
              },
            },
          ]}
        ></CustomCheckBox>
        <Input
          Value={urinedata.intake.salt_others}
          TextVal="Others ( Please Specify )"
          OnChange={(val) => {
            urinedata.intake.others = val.target.value;
          }}
          htmlFor="salt_intake"
          Type={"text"}
        ></Input>
      </div>
    );
  };

  const Suppliment_recommendation_potassium_citrate = () => {
    return (
      <div className="text-left">
        <p>Check Urine PH</p>
        {
          "If necessary (pH is <5.9-6.0): consider supplementation of K-Citrate (50-200mEq/d)"
        }{" "}
        <CustomRadioBox
          data={
            urinedata.calcuria_recommendation
              .suppliment_recommendation_potassium_citrate
          }
          content={[
            {
              name: "Ignore recommendation for supplementation of Potassium Citrate",

              value: "None",
            },
            {
              name: "Confirm recommendation for supplementation of Potassium Citrate",

              value:
                "Treatment with Potassium Citrate 20 mEq X3/day and follow up urine pH",
            },
          ]}
          id={1}
          handleChange={(val) => {
            urinedata.calcuria_recommendation.suppliment_recommendation_potassium_citrate =
              val.target.value;
            setuc({
              ...urinedata,
              calcuria_recommendation: {
                ...urinedata.calcuria_recommendation,
                ["suppliment_recommendation_potassium_citrate"]:
                  val.target.value,
              },
            });
          }}
        ></CustomRadioBox>
      </div>
    );
  };

  // DAIRY

  // URIC

  const DairyProductData = () => (
    <div>
      <h4 className="font-bold">
        Evaluation of Dietary sources for HyperCalciuria
      </h4>
      <p>Dairy Products</p>
      <Input
        TextVal="Milk[One Glass (~200mg)]"
        Value={urinedata.intake.milk}
        OnChange={(val) => {
          urinedata.intake.milk = val.target.value;
          // TODO ADD USER intake for urine calc assignment
        }}
        htmlFor="milk"
        Type={"number"}
      ></Input>
      <Input
        TextVal="Yogurt [One Piece (~200mg)]"
        Value={urinedata.intake.yog}
        OnChange={(val) => {
          urinedata.intake.yog = val.target.value;
        }}
        htmlFor="yogurt"
        Type={"number"}
      ></Input>
      <Input
        Value={urinedata.intake.cheese}
        TextVal="Cheese [200mg Ca per ~30gr cheese (1 Product)]"
        OnChange={(val) => {
          urinedata.intake.cheese = val.target.value;
        }}
        htmlFor="cheese"
        Type={"number"}
      ></Input>
      <Input
        Value={urinedata.intake.supp}
        TextVal="Calcium Supplements 0.5 , 1 grams"
        OnChange={(val) => {
          urinedata.intake.supp = val.target.value * 3;
        }}
        htmlFor="suppliments"
        Type={"number"}
      ></Input>
      <Input
        Value={urinedata.intake.mineral_water_calc}
        TextVal="Intake of Calcium rich bottled water [Vittel, Badoit (~200mg/Liter)] (Number of liters)"
        OnChange={(val) => {
          urinedata.intake.mineral_water_calc = val.target.value;
        }}
        htmlFor="mineral-calcium"
        Type={"number"}
      ></Input>{" "}
      <Input
        Value={urinedata.intake.tap_water_calc}
        TextVal="Tap Water Ca (mg)/day (~100mg/L) Number of liters"
        OnChange={(val) => {
          urinedata.intake.tap_water_calc = val.target.value / 2;
        }}
        htmlFor="tap-calcium"
        Type={"number"}
      ></Input>
    </div>
  );

  // TODO ADD GENERAL RECOMMENDATIONS FOR URINE CALCIUM
  let resultTable = {
    hyper_calc_w_normocalcemia: (
      <CustomPopup
        emptyList={emptyList}
        onpopclose={() => {
          handleDairyIntakePostSubmission(urinedata);
        }}
        onPrevCallback={() => {}}
        onNextCallback={() => {}}
        content={[
          //TODO [POPUP 1]
          <DairyProductData />,
          //TODO [POPUP 2]
          <Suppliment_recommendation_potassium_citrate />,
          //TODO [POPUP 3]
          <div>
            <p>
              {" "}
              If Hypercalciuria continuous despite good control of calcium
              intake:
            </p>
            Consider performing Pak Test
            <a
              target={"_blank"}
              className="text-bold italic underline"
              href="/#/pak-test"
            >
              {" "}
              (Calcium Load Test){" "}
              {/* // TODO UPDATE PAK TEST LINK explanation on Pak test) */}
            </a>
            to differentiate between: Intestinal Level (Absorptive) or Renal
            Level (Renal leak) or masked 1°HyperPTH
            <br />
            Alternatively refer to nephrologist for Pak test performance.
            <CustomRadioBox
              handleChange={(val) => {
                console.log("S");

                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["pak_test_recommendation"]: val.target.value,
                  },
                });
              }}
              id={221}
              data={urinedata.calcuria_recommendation.pak_test_recommendation}
              content={[
                {
                  name: "Ignore recommendation for Pak test performance",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Pak test performance",
                  value: "Patient referred to perform Pak test",
                },
              ]}
            ></CustomRadioBox>
          </div>,
          //TODO [POPUP 4]

          <div>
            If Hypercalciuria continues - After Pak test – Treat with Thiazides
            (e.g. Hydrochlorothiazide 25-50mg/d) To reduce HyperCalciuria <br/> Warn
            pts against Side Effects: headache, lethargy & Hypokalemia
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation
                  .hydrochlorothiazide_recommendation
              }
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["hydrochlorothiazide_recommendation"]: val.target.value,
                  },
                });
              }}
              id={3}
              content={[
                {
                  name: "Ignore recommendation for Treatment with Hydrochlorothiazide 25 mg/day ",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Treatment with Hydrochlorothiazide 25 mg/day",
                  value: "Treatment with Hydrochlorothiazide 25mgX1/day",
                },
              ]}
            />
          </div>,

          //TODO [POPUP 5]
          <div>
            If long-term Hypercalciuria: Do not neglect the risk of
            Osteoporosis/ Osteopenia and consider performing a rheumatologic
            exam and DEXA scan
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation.rheumatologic_recommendation
              }
              id={5}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["rheumatologic_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for referral to Rheumatologist and DEXA Scan",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for referral to Rheumatologist and DEXA Scan",
                  value:
                    "Patient referred to rheumatologist to consider performance of DEXA scan to rule out osteoporosis",
                },
              ]}
            />
          </div>,
        ]}
      ></CustomPopup>
    ),

    hyper_calc_w_hypernatruria: (
      //TODO [POPUP 1]
      <CustomPopup
        content={[
          <DairyProductData />,

          //TODO [POPUP 2]
          <SaltIntakeData />,
          //TODO [POPUP 3]
          <Suppliment_recommendation_potassium_citrate />,
          // TODO [POPUP 4]
          <div>
            If Hypercalciuria continuous despite good control of calcium intake:
            Consider performing Pak Test
            <a
              target={"_blank"}
              className="text-bold underline italic"
              href="/#/pak-test"
            >
              {" "}
              (Calcium Load Test){" "}
              {/* // TODO UPDATE PAK TEST LINK explanation on Pak test) */}
            </a>
            to differentiate between: Intestinal Level (Absorptive) or Renal
            Level (Renal leak) or masked 1°HyperPTH
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.pak_test_recommendation}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["pak_test_recommendation"]: val.target.value,
                  },
                });
              }}
              id={12}
              content={[
                {
                  name: "Ignore recommendation for Pak test performance",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Pak test performance",
                  value: "Patient referred to perform Pak test",
                },
              ]}
            ></CustomRadioBox>
          </div>,

          //TODO [POPUP 5]
          <div>
            If Hypercalciuria continues - After Pak test – Treat with Thiazides
            (e.g. Hydrochlorothiazide 25-50mg/d) To reduce HyperCalciuria Warn
            pts against Side Effects: headache, lethargy & Hypokalemia
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation
                  .hydrochlorothiazide_recommendation
              }
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["hydrochlorothiazide_recommendation"]: val.target.value,
                  },
                });
              }}
              id={3}
              content={[
                {
                  name: "Ignore recommendation for Treatment with Hydrochlorothiazide 25 mg/day ",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for referral to Rheumatologist and DEXA Scan",
                  value: "Treatment with Hydrochlorothiazide 25mgX1/day",
                },
              ]}
            />
          </div>,

          //TODO [POPUP 6]
          <div>
            If long-term Hypercalciuria: Do not neglect the risk of
            Osteoporosis/ Osteopenia and consider performing a rheumatologic
            exam and DEXA scan
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation.rheumatologic_recommendation
              }
              id={4}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["rheumatologic_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for referral to Rheumatologist and DEXA Scan",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for referral to Rheumatologist and DEXA Scan",
                  value:
                    "Patient referred to rheumatologist to consider performance of DEXA scan to rule out osteoporosis",
                },
              ]}
            />
          </div>,
        ]}
        onNextCallback={() => {}}
        onPrevCallback={() => {}}
        onpopclose={() => {
          handleDairyIntakePostSubmission(urinedata);
          handleSaltIntakeSubmission(urinedata);
        }}
      />
    ),

    hyper_calc_w_high_urine_urea: (
      <CustomPopup
        onNextCallback={() => {}}
        onPrevCallback={() => {}}
        onpopclose={() => {
          handleDairyIntakePostSubmission(urinedata);
          handleProtienIntakeSubmission(urinedata);
          // TODO ADD PROTIEN
        }}
        content={[
          //TODO [POPUP 1]
          <DairyProductData />,
          //TODO [POPUP 2]
          <ProtienIntake />, // TODO [POPUP 3]
          <Suppliment_recommendation_potassium_citrate />,

          //TODO [POPUP 4]
          <div>
            If Hypercalciuria continuous despite good control of calcium intake:
            Consider performing Pak Test
            <a
              target={"_blank"}
              className="text-bold italic underline"
              href="/#/pak-test"
            >
              {" "}
              (Calcium Load Test){" "}
              {/* // TODO UPDATE PAK TEST LINK explanation on Pak test) */}
            </a>
            to differentiate between: Intestinal Level (Absorptive) or Renal
            Level (Renal leak) or masked 1°HyperPTH 
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.pak_test_recommendation}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["pak_test_recommendation"]: val.target.name,
                  },
                });
              }}
              id={6}
              content={[
                {
                  name: "Ignore recommendation for Pak test performance",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Pak test performance",
                  value: "Patient referred to perform Pak test",
                },
              ]}
            ></CustomRadioBox>
          </div>,
          //TODO [POPUP 5]

          <div>
            If Hypercalciuria continues - After Pak test – Treat with Thiazides
            (e.g. Hydrochlorothiazide 25-50mg/d) To reduce HyperCalciuria Warn
            pts against Side Effects: headache, lethargy & Hypokalemia
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation
                  .hydrochlorothiazide_recommendation
              }
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["hydrochlorothiazide_recommendation"]: val.target.value,
                  },
                });
              }}
              id={6}
              content={[
                {
                  name: "Ignore recommendation for Treatment with Hydrochlorothiazide 25 mg/day ",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Treatment with Hydrochlorothiazide 25 mg/day",
                  value: "Treatment with Hydrochlorothiazide 25mgX1/day",
                },
              ]}
            />
          </div>,

          //TODO [POPUP 6]
          <div>
            If long-term Hypercalciuria: Do not neglect the risk of
            Osteoporosis/ Osteopenia and consider performing a rheumatologic
            exam and DEXA scan
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation.rheumatologic_recommendation
              }
              id={7}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["rheumatologic_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for referral to Rheumatologist and DEXA Scan",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for referral to Rheumatologist and DEXA Scan",
                  value:
                    "Patient referred to rheumatologist to consider performance of DEXA scan to rule out osteoporosis",
                },
              ]}
            />
          </div>,
        ]}
      />
    ),

    hyper_calcuria_w_high_hypercalcemia: (
      <CustomPopup
        onNextCallback={() => {}}
        onPrevCallback={() => {}}
        onpopclose={() => {
          handleDairyIntakePostSubmission(urinedata);
        }}
        content={[
          //TODO [POPUP 1]
          <div className="text-left">
            <p className="font-bold"> Calcium in the blood: </p>
            <p> • 99% of body Ca is in the bone </p>
            <p> • not included 1%  are in the blood-tested </p>
            <p> • Normal levels are 8.5-10.5 mg/dL or 2.1-2.6 mmol/L </p>
            <p className="mt-2 font-bold">
              Recommended Hypercalcemia Evaluation:{" "}
            </p>
            <p> • Repeat Calcium test and Correct for albumin </p>
            <p>
              {" "}
              • Consider a Chest X-Ray to rule out a lung mass/sarcoidosis{" "}
            </p>
            <p>
              {" "}
              {/* // TODO ADD LINK */}• Evaluate Parathyroid Hormone levels
              (link to text below): (The most common cause for resorptive
              hypercalciruia and hypercalcemia){" "}
            </p>
            <p>
              {" "}
              • Consider performing the Pak Test if Parathyroid hormone levels
              normal: to diagnose early stage of hyperparathyroidism
            </p>
            <a
              target={"_blank"}
              className="text-bold italic underline"
              href="/#/pak-test"
            >
              {" "}
              - Why do Pak test?{" "}
              {/* // TODO UPDATE PAK TEST LINK explanation on Pak test) */}
            </a>
            <p className="font-bold"> </p>
            {/* //TODO ADD LINK */}
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation.hypercalcemia_recommendation
              }
              id={8}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["hypercalcemia_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Hypercalcemia Evaluation and refer to treating physician for Hypercalcemia Evaluation",
                  value:
                    "Patient referred to treating physician for Hypercalcemia Evaluation",
                },
                {
                  name: "Confirm recommendations to Hypercalcemia Evaluation",
                  value:
                    "Repeat Calcium test with liver function tests and Correct for albumin, Consider a Chest X-Ray to rule out a lung mass/sarcoidosis, Evaluate Parathyroid Hormone levels, If parathyroid elevated refer to endocrinologic evaluation to rule out parathyroid adenoma or cancer, Consider performing the Pak Test if Parathyroid hormone levels normal",
                },
              ]}
            />
          </div>,
          //TODO [POPUP 2]
          <DairyProductData />,

          // TODO [POPUP 3]
          <div className="text-left">
            <p className="font-bold">
              {" "}
              Differential Diagnoses for hypercalciuria due to Increased Bone
              resorption:
            </p>
            <p>• Prolonged immobilization </p>
            <p>
              {`• Increased constant acid load in the body – (Insulin resistance/high protein intake etc..) -> The suggested mechanism is that
                       it causes the release of Calcium from bone to buffer the acid`}
            </p>
            <p>• Chronic steroid use </p>
            <p>• HyperThy roidism </p>
            <p>• Oncologic patients: Osteolytic bone Metastasis </p>
            <p>
              • Oncologic patients: Parathyroid-related protein secreting
              malignancy{" "}
            </p>
            <p>
              {
                "• Paget’s disease of bone (accelerated bone remodeling -> deformities, bone pain"
              }
              )
            </p>
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation
                  .bone_resporption_recommendation
              }
              id={9}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["bone_resporption_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "If no cause suspected- Ignore recommendation for Patient referred to treating physician for evaluation of secondary bone resorption.",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referred to treating physician for evaluation of secondary bone resorption.",
                  value:
                    "Patient referred to treating physician for evaluation of secondary bone resorption.",
                },
              ]}
            />
          </div>,
          // TODO [POPUP 4]
          <div className="text-left">
            <p className="font-bold">
              Other Differential Diagnoses for hypercalciuria:
            </p>
            <p>• Prolonged immobilization </p>
            <p>or</p>
            <p>
              • Ectopic Production: Granulamatous Disorders: Sarcoidosis,
              Tuberculosis, lymphoma
            </p>
            <p>• HyperThy roidism </p>

            <p className="font-bold mt-2">
              If patient takes vitamin D supplement or oncologic patient: Check
              vitamin D levels and if high refer to treating physician and
              nephrologist
            </p>
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.vit_d_recommendation}
              id={10}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["vit_d_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referred to treating physician for evaluation of Vitamin D toxicity",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referred to treating physician for evaluation of Vitamin D toxicity",
                  value:
                    "Patient referred to treating physician for evaluation of Vitamin D toxicity",
                },
              ]}
            />
          </div>,

          // TODO [POPUP 5]
          <div className="text-left">
            <p className="font-bold">
              {
                "If patient on chronic lithium treatment- refer to treating physician for further follow up and treatment- to rule out Potential Lithium Treatment toxicity (Increases suppression threshold of PTH by calcium -> takes a higher Calcium level to suppress PTH)"
              }
            </p>
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.lithium_recommendation}
              id={11}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["lithium_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referred to treating physician for evaluation of Lithium toxicity",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referred to treating physician for evaluation of Lithium toxicity",
                  value:
                    "Patient referred to treating physician for evaluation of Lithium toxicity.",
                },
              ]}
            />
          </div>,
          // TODO [POPUP 6]
          <div className="text-left">
            <p>If Chronic Thiazide use: </p>
            <p className="font-bold">
              • Chronic treatment with thiazide diuretic might be a cause for
              hypercalcemia- refer to nephrologist and consider stopping
              thiazide.
            </p>
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.thizize_recommendation}
              id={12}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["thizize_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referral to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referral to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
                  value:
                    "Patient referral to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
                },
              ]}
            />
          </div>,
          // TODO [POPUP 7]
          <div className="text-left">
            <p className="font-bold">
              {
                "Very rare diagnosis: If patient has family members with urinary stones and hypercalcemia:"
              }
            </p>
            <p>
              - Consider the diagnosis of Familial Hypocalciuria-Hypercalcemia
              syndrome (Increases suppression threshold of PTH by calcium){" "}
            </p>
            <p>
              If suspected refer to nephrologist for further work up and
              diagnosis.
            </p>
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation.hypocalciuria_hypercalcemia
              }
              id={13}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["hypocalciuria_hypercalcemia"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referral to nephrologist for further follow up.",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referral to nephrologist for further follow up.",
                  value:
                    "Patient referral to nephrologist for further follow up.",
                },
              ]}
            />
          </div>,
        ]}
      />
    ),

    hypernaturia: (
      <CustomPopup
        content={[<SaltIntakeData />]}
        onNextCallback={() => {}}
        onpopclose={() => {
          handleSaltIntakeSubmission(urinedata);
          handleDairyIntakePostSubmission(urinedata);
        }}
        onPrevCallback={() => {}}
      />
    ),

    hyperoxaluria: (
      <CustomPopup
        onNextCallback={() => {}}
        onpopclose={() => {
          handleSaltIntakeSubmission(urinedata);
        }}
        onPrevCallback={() => {}}
        content={[
          //TODO POPUP 1
          <div>
            Is patient taking the recommended daily Calcium (1gram/day)? Ask for
            dairy products intake and calcium supplements: Low calcium intake
            will increase oxalate excretion in the urine instead of through the
            intestines.{" "}
            <CustomRadioBox
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  hyperoxaluria_recommendation: {
                    ...urinedata.hyperoxaluria_recommendation,
                    ["calcium"]: val.target.value,
                  },
                });
              }}
              data={urinedata.hyperoxaluria_recommendation.calcium}
              content={[
                { name: "Normal calcium intake per day", value: "None" },
                {
                  name: "Low calcium intake suspected: Confirm the Recommendation of : Normal daily calcium intake (1 gram/day): dairy products, calcium supplements.",
                  value:
                    "Maintain normal daily Calcium intake in the diet (1000-1200 mg/day)",
                },
              ]}
            />
          </div>,
          // TODO POPUP 2
          <div>
            Check for Excess Dietary Oxalate Intake in the dietary survey.{" "}
            <CustomCheckBox
              content={[
                {
                  name: "Spinach",
                  value: urinedata.intake.spinach,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["spinach"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Rhubarb",
                  value: urinedata.intake.rhubarb,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["rhubarb"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Cacao",
                  value: urinedata.intake.cacao,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["cacao"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Cashew / Nuts/ Hazelnuts/ Pistachios",
                  value: urinedata.intake.cashew,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["cashew"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Sesame",
                  value: urinedata.intake.seasame,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["seasame"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Tea (mainly Black Tea)",
                  value: urinedata.intake.tea,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["tea"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
            <Input
              TextVal="Orange Juice (Glasses/Day)"
              Value={urinedata.intake.orange_juice}
              OnChange={(val) => {
                urinedata.intake.orange_juice = val.target.value;
              }}
              htmlFor="orange_juice"
              Type={"number"}
            ></Input>
          </div>,
          // TODO POPUP 3
          <div>
            Rule out excess Vitamin C Consumption? Vitamin C metabolism
            increases oxalate levels in the body{" "}
            <CustomRadioBox
              data={urinedata.hyperoxaluria_recommendation.vit_c}
              id={123}
              content={[
                { name: "No excess Vitamin C Consumption", value: "None" },
                {
                  name: "Suspicion of excess Vitamin C Consumption",
                  value: "Avoid over consumption  of Vitamin C ",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  hyperoxaluria_recommendation: {
                    ...urinedata.hyperoxaluria_recommendation,
                    ["vit_c"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
            ,
          </div>,

          //TODO POPUP 4
          <div>
            Check for possible Short bowel syndrome / Inflammatory Bowel Disease
            or Previous Gastric bypass surgery.
            <CustomRadioBox
              data={urinedata.hyperoxaluria_recommendation.gastric}
              id={123}
              content={[
                {
                  name: "No Short bowel syndrome/Inflammatory Bowel Disease or previous Gastric bypass surgery",
                  value: "None",
                },
                {
                  name: "Known Short bowel syndrome or previous Gastric bypass surgery",
                  value:
                    "Maintain high fluid intake, Maintain sufficient Calcium intake (1 gram per day) , Treatment with Calcium Citrate 1 gram/day, with meals, and adjust dose according to results. Keep patient on Low fat die ",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  hyperoxaluria_recommendation: {
                    ...urinedata.hyperoxaluria_recommendation,
                    ["gastric"]: val.target.value,
                  },
                });
                if (val.target.value != "None") {
                  urinedata.hyperoxaluria_recommendation.oxalate_adv =
                    "Avoid Excessive consumption of foods/beverages with high oxalate content\n-Spinach\n- Rhubarb\n- Cacao\n- Cashew / Nuts/ Hazelnuts/ Pistachios\n- Sesame\n- Tea (mainly Black Tea) \n- (Orange) Juice\n- Avoid excess Vitamin C Consumption\nMaintain the recommended daily Calcium intake (1gr/d)";
                }
              }}
            ></CustomRadioBox>
          </div>,
          // TODO POPUP 5
          <div>
            Rule out recent long-term Antibiotic treatment: Long-term antibiotic
            treatment eradicates the bacteria Oxalobacter formigenes in the
            intestine and might indirectly cause increased oxalate resorption.{" "}
            <CustomRadioBox
              data={urinedata.hyperoxaluria_recommendation.oxalate_res}
              id={123}
              content={[
                {
                  name: "No recent long-term Antibiotic treatment",
                  value: "None",
                },
                {
                  name: "Recent long-term Antibiotic treatment - Patient referred to treating physician to discuss alternatives to long-term antibiotic therapy",
                  value:
                    "Patient referred to treating physician to discuss alternatives to long-term antibiotic therapy",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  hyperoxaluria_recommendation: {
                    ...urinedata.hyperoxaluria_recommendation,
                    ["oxalate_res"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          //TODO POPUP 6
          <div>
            Rule out known history of Primary HyperOxaluria in the family:
            Search for family members with early calcium oxalate stone disease
            and renal insufficiency due to stone disease.{" "}
            <CustomRadioBox
              data={urinedata.hyperoxaluria_recommendation.hist_hyperox}
              id={123}
              content={[
                {
                  name: "No family members / history with Primary HyperOxaluria",
                  value: "None",
                },
                {
                  name: "Family members / history with primary hyperOxaluria- Patient referred to genetic analysis to rule out Primary HyperOxaluria",
                  value:
                    "Avoid Excessive consumption of foods/beverages with high oxalate content\n-Spinach\n- Rhubarb\n- Cacao\n- Cashew / Nuts/ Hazelnuts/ Pistachios\n- Sesame\n- Tea (mainly Black Tea) \n- (Orange) Juice\n- Avoid excess Vitamin C Consumption\nMaintain the recommended daily Calcium intake (1gr/d)",
                },
              ]}
              handleChange={(val) => {
                if (val.target.value != "None") {
                  urinedata.hyperoxaluria_recommendation.nephro_rec =
                    "Referred to nephrologic and genetic evaluation to rule out Primary HyperOXaluria, \n -Maintain High fluid intake to produce a urinary volume of 3 L/day\n -Treatment with Potassium citrate to inhibit precipitation of calcium oxalate in the urine \n -To Consider: treatment trial for 3- to 6-months with Pyridoxine: At a starting dose of 5 mg/kg if diagnosis made for type 1 Primary Hyperoxaluria\n";
                } else {
                  urinedata.hyperoxaluria_recommendation.nephro_rec = "None";
                }
                setuc({
                  ...urinedata,
                  hyperoxaluria_recommendation: {
                    ...urinedata.hyperoxaluria_recommendation,
                    ["hist_hyperox"]: val.target.value,
                  },
                });

                // TODO FIX THIS ONE!!
              }}
            ></CustomRadioBox>
          </div>,
        ]}
      />
    ),
    urea: (
      <CustomPopup
        onNextCallback={() => {}}
        onpopclose={() => {
          handleProtienIntakeSubmission(urinedata);
        }}
        onPrevCallback={() => {}}
        content={[
          <div>
            <p>
              {
                "Rule out a dietary cause of high urine urea: High daily protein intake (>1.1gr/kg/day):"
              }
            </p>
            <ProtienIntake></ProtienIntake>
            <CustomRadioBox
              data={urinedata.urea_recommendation.protien}
              id={13}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  urea_recommendation: {
                    ...urinedata.urea_recommendation,
                    ["protien"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Normal protein intake per day",
                  value: "None",
                },
                {
                  name: "High daily protein intake: confirm recommendations to lower daily protein intake ",
                  value:
                    "Avoid high daily protein intake (<0.8gr/kg/day): Animal Meat Products, Excessive intake of Dairy Products and Vegetables rich in Protein, Eggs",
                },
              ]}
            />
          </div>,
        ]}
      ></CustomPopup>
    ),
    citrate: (
      <div>
        <CustomPopup
          content={[
            <div>
              {" "}
              <p>
                {
                  "Low urine citrate and renal insufficiency or acidic urine pH: Refer to nephrologist to rule out causes for metabolic acidosis or possible renal tubular acidosis"
                }
              </p>
              <CustomRadioBox
                handleChange={(val) => {
                  setuc({
                    ...urinedata,
                    citrate_recommendation: {
                      ...urinedata.citrate_recommendation,
                      ["acdiosis"]: val.target.value,
                    },
                  });
                }}
                data={urinedata.citrate_recommendation.acdiosis}
                content={[
                  {
                    name: "Confirm referral to nephrologist.",
                    value: "Refer to nephrologist",
                  },
                ]}
              ></CustomRadioBox>
            </div>,

            <div>
              <p>
                {
                  "Urine pH<6 without other abnormalities: treat with Potassium Citrate"
                }
              </p>
              <CustomRadioBox
                handleChange={(val) => {
                  setuc({
                    ...urinedata,
                    citrate_recommendation: {
                      ...urinedata.citrate_recommendation,
                      ["potassium_citrate"]: val.target.value,
                    },
                  });
                }}
                data={urinedata.citrate_recommendation.potassium_citrate}
                content={[
                  {
                    name: "Confirm recommendation for Treatment with Potassium Citrate",
                    value:
                      "Treatment with Potassium Citrate 20 mEq X3/day and follow up urine pH",
                  },
                ]}
              />
            </div>,
          ]}
        ></CustomPopup>
      </div>
    ),

    cystine: (
      <CustomPopup
        onpopclose={() => {
          handleCystinePopupClose(urinedata);
        }}
        content={[
          <div>
            <p>Check dietary intake responsible for high urine Cystine:</p>
            <CustomCheckBox
              content={[
                {
                  name: "Parmesan Cheese",
                  value: urinedata.intake.parmesan_cheese,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["parmesan_cheese"]: val.value,
                      },
                    });
                  },
                },

                {
                  name: "Eggs",
                  value: urinedata.intake.egg,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["egg"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Fluid Intake < 3.5 Liters day",
                  value: urinedata.intake.low_fluid,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["low_fluid"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "High protein Intake/ High salt intake",
                  value: urinedata.intake_salt,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["protien_salt"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
          </div>,
          <div>
            <p>
              {
                "Check urine pH (needs to be> 6.5) and warn against calcium potential development of calcium phosphate stones"
              }
            </p>
            <p>{"If Urine pH<6.5: treat with Potassium Citrate"}</p>
            <p>Low urine pH can increase HyperCalciuria</p>
            <CustomRadioBox
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  cystine_recommendation: {
                    ...urinedata.cystine_recommendation,
                    ["potassium_citrate"]: val.target.value,
                  },
                });
              }}
              id={21}
              data={urinedata.cystine_recommendation.potassium_citrate}
              content={[
                {
                  name: "Ignore recommendation for Treatment with Potassium Citrate",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Treatment with Potassium Citrate",
                  value:
                    "Treatment with Potassium Citrate 20 mEq X3/day and follow up urine pH",
                },
              ]}
            ></CustomRadioBox>
          </div>,
        ]}
      ></CustomPopup>
    ),
    uric: (
      <CustomPopup
        content={[
          <div className="text-left">
            Rule out a diet rich in purines (source of uric acid)
            <CustomCheckBox
              content={[
                {
                  name: "High Animal Protein Intake",
                  value: urinedata.intake.animal_protien,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["animal_protien"]: val.value,
                      },
                    });
                  },
                },
                // {
                //   name: "Charcuterie",
                //   value: urinedata.intake.charcuterie,
                //   handleChange: (val) => {
                //     setuc({
                //       ...urinedata,
                //       intake: {
                //         ...urinedata.intake,

                //         ["charcuterie"]: val.value,
                //       },
                //     });
                //   },
                // },
                {
                  name: "High Fish/Anchovies Consumption",
                  value: urinedata.intake.fish,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["fish"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "High Alcohol Consumption",
                  value: urinedata.intake.alcohol,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["alcohol"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "High Carbohydrate Intake",
                  value: urinedata.intake.carbs,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["carbs"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Soda Drinks/Corn Syrup based beverages (e.g. Coca-Cola 330ml = 36.25 grams)",
                  value: urinedata.intake.soda,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["soda"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Juices (~22 gram/200mL -> 4-5 sugar peices)",
                  value: urinedata.intake.juice,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["juice"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
            <Input
              TextVal="Chocolate (Peices/day)"
              Value={urinedata.intake.chocolate}
              OnChange={(val) => {
                urinedata.intake.chocolate = val.target.value;

                // TODO ADD USER intake for urine calc assignment
              }}
              htmlFor="Chocolate"
              Type={"number"}
            ></Input>
            <Input
              TextVal="Table Sugar (grams/day)"
              Value={urinedata.intake.sugar}
              OnChange={(val) => {
                urinedata.intake.sugar = val.target.value;
              }}
              htmlFor="sugar"
              Type={"number"}
            ></Input>
            <CustomRadioBox
              data={urinedata.uric_recommendation.urine_uric}
              content={[
                {
                  name: "No dietary source for increased urine uric acid",

                  value: "None",
                },
                {
                  name: "Suspected high daily intake of urine uric acid: confirm recommendations to lower daily uric acid intake",
                  value:
                  "Avoid intake of foods with high uric acid content:\n-  Animal Meat Products/Eggs/Fish/Anchovie\n-  Alcoholic beverage\n-  Avoid excessive carbohydrate intake / Fructose / Sweetened beverage\n-  Keep urine pH in normal value and not acidic (Acidic urine can promote the formation of uric acid stones)"

                  //   "Avoid high daily protein intake (<0.8gr/kg/day): Animal Meat Products, Excessive intake of Dairy Products and Vegetables rich in Protein, Eggs",
                },
              ]}
              id={1}
              handleChange={(val) => {
                urinedata.uric_recommendation.urine_uric = val.target.value;
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["urine_uric"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,

          <div>
            Rule out Acidic urine pH (can promote formation of uric acid
            stones): check urine pH level in morning spot urine and keep it
            between 6-6.5
            <CustomRadioBox
              data={urinedata.uric_recommendation.ph_level}
              content={[
                {
                  name: "Refer patient to morning spot urine/ urine pH evaluation",

                  value:
                    "Refer patient to morning spot urine/ urine pH evaluation",
                },
                {
                  name: "Normal urine pH: Ignore recommendation for supplementation of Potassium Citrate",
                  value: "None",
                },
                {
                  name: "If acidic urine pH: Confirm recommendation for supplementation of Potassium Citrate",
                  value:
                    "Treatment with Potassium Citrate 20 mEq X3/day and follow up urine pH",
                },
              ]}
              id={1}
              handleChange={(val) => {
                urinedata.uric_recommendation.ph_level = val.target.value;
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["ph_level"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            Rule out Myeloproliferative Disease / Treatment as cause to high
            urine uric acid (High turnover-destruction of body cells by
            Chemotherapy or Radiotherapy)
            <CustomRadioBox
              data={urinedata.uric_recommendation.myeloproliferative}
              content={[
                {
                  name: "No Myeloproliferative disease",

                  value: "None",
                },
                {
                  name: "If suspicion Myeloproliferative disease/treatment cause of high uric acid : Confirm recommendation for supplementation of Potassium Citrate / Hyperhydration",
                  value:
                    "Patient referred to treating oncologist to consider hyper-hydration / supplementation of Potassium Citrate or Allopurinol to prevent urine uric acid increase and precipitation",
                },
              ]}
              id={1}
              handleChange={(val) => {
                urinedata.uric_recommendation.myeloproliferative =
                  val.target.value;
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["myeloproliferative"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            Rule out Other Factors:
            <CustomCheckBox
              content={[
                {
                  name: "Uncontrolled Diabetes Mellitus? Insulin Resistance? ",
                  value: urinedata.uric_recommendation.diabetes,
                  handleChange: (val) => {
                    // TODO ADD POINT SYSTEM FOR UREA NITOGEN
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["diabetes"]: val.value,
                      },
                    });
                  },
                },

                {
                  name: "Gout?",
                  value: urinedata.uric_recommendation.gout,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["gout"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Diarrhea (loss of Bicarbonate)",
                  value: urinedata.uric_recommendation.diarrhea,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["diarrhea"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Decreased Diuresis [Dehydration- Low fluid intake]",
                  value: urinedata.uric_recommendation.diuresis,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["diuresis"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Medications: Thiazide Diuretics, Aspirin",
                  value: urinedata.uric_recommendation.medications,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["medications"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
          </div>,
        ]}
        onpopclose={() => {
          handleUricSubmission(urinedata);
          handleuricfators(urinedata);
        }}
      ></CustomPopup>
    ),
  };

  return resultTable[type];
};

export default ResultUrineData;
