import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";
const AdminProtectedRoute = ({ component: Component, ...restofProps }) => {
  const location = useLocation();

  const user = useSelector((e) => e);

  return getAuth().currentUser && user.super_admin ? (
    Component
  ) : (
    <Navigate state={{}} to={"/dashboard"} />
  );
};

export default AdminProtectedRoute;
