let CustomRadioBox = ({content, id, handleChange, data}) => {
  console.log("REB");
  return content.map((item, index) => {
   
    console.log(data);
    console.log("Data Logged")
    console.log(item.value);
    return (
      <div key={index} className="flex mt-6 items-center">
        <input
          type="radio"
          id={item.name}
          name={id}
          checked= { data == item.value ? true : null} 
          value= {item.value}
          onChange={handleChange}
          className="mr-4"
        />
        <div>
          <label htmlFor={item.name} className="font-semibold">
            {item.name}
          </label>
        </div>
      </div>
    );
  });
};

export default CustomRadioBox;
