import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import GSC from "./GSC";
import { Provider } from "react-redux";
import { createStore } from "redux";
import userReducer from "./store/reducers/UserReducer";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlB6qTxDi4cg-oQ4vOY_KbiIHNSRxI4v4",
  authDomain: "gsc-clinic.firebaseapp.com",
  projectId: "gsc-clinic",
  storageBucket: "gsc-clinic.appspot.com",
  messagingSenderId: "871977014116",
  appId: "1:871977014116:web:8c65995a6533da263d6071",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const store = createStore(userReducer,     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
);

auth.onAuthStateChanged((e) => {
  ReactDOM.render(
    <React.StrictMode>
      {" "}
      <Provider store={store}>
      <GSC />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
