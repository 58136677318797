import user_diet from "./user_diet";

export const handleDairyIntakePostSubmission = (urinedata) => {
  user_diet.calcium = 0;

  const items = [
    "milk",
    "yog",
    "cheese",
    "supp",
    "mineral_water_calc",
    "tap_water_calc",
  ];

  for (const [key, value] of Object.entries(urinedata.intake)) {
    if (items.indexOf(key) != -1) {

      user_diet.calcium = user_diet.calcium + parseFloat(value);
    }
  }
  
  if (user_diet.calcium > 5) {
    urinedata.calcuria_recommendation.general =
      "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day\n- Avoid consumption of more than 3 milk products/day\n- Avoid calcium rich mineral water (e.g. Vittel-Badoit)\n- Avoid calcium supplements if not necessary";
  }

  console.log(user_diet.calcium);
};

export const handleUricSubmission = (urinedata) => {
  user_diet.uric_acid = 0;
  const items = [
    "animal_protien",
    "juice",
    "soda",
    "carbs",
    "alcohol",
    "fish",
  //  "charuterie",
  ];
  for (const [key, value] of Object.entries(urinedata.intake)) {
    if (items.indexOf(key) != -1) {
      user_diet.uric_acid += value === true ? 1 : 0;
    }
  }
};

export const handleuricfators = (urinedata) => {
  const items = ["diabetes", "gout", "diarrhea", "diuresis", "medications"];
  const vals = {
    diabetes: "Maintain balanced blood glucose levels",
    gout: "Maintain normal blood uric acid levels through adequate treatment of Gout (Patient referred to treating physician)",
    diarrhea: "Refer to treating physician for management of diarrhea",
    diuresis: "Increase fluid intake",
    medications:
      "Patient referred to treating physician to consider stopping medications that increase urine uric acid (e.g. Thiazide Diuretics, Aspirin)",
  };

  for (const [key, value] of Object.entries(urinedata.uric_recommendation)) {
    let index = items.indexOf(key);
    if (index != -1) {
      if (value != false) {
        urinedata.uric_recommendation[key] = vals[key];
      } else {
        urinedata.uric_recommendation[key] = undefined;
      }
    }
  }
};

export const handleSaltIntakeSubmission = (urinedata) => {
  user_diet.sodium = 0;
  user_diet.oxalate = 0;
  const sodium_items = ["salt", "sparkling_water", "sauces ", "pickle"];
  const oxalate_items = [
    "spinach",
    "rhubarb",
    "cacao",
    "cashew",
    "seasame",
    "tea",
    "orange_juice",
  ];

  const items = sodium_items + oxalate_items;

  for (const [key, value] of Object.entries(urinedata.intake)) {
    if (sodium_items.indexOf(key) != 1) {
      user_diet.sodium += value === true ? 1 : 0;
    }
    if (oxalate_items.indexOf(key) != -1) {
      if(key == "orange_juice") user_diet.oxalate += value;
      else
      user_diet.oxalate += value === true ? 1 : 0;
    }
  }
  if (user_diet.sodium > 0) {
    urinedata.urea_recommendation.salt_intake =
      "Avoid foods and beverages with high salt content: \n- Table Salt\n- Sparkling water: Vichy, St Yorre, San Peligrino\n- Sauces (Ketchup, Soya)\n- Pickles";
  }
  if (user_diet.oxalate > 3) {
    urinedata.calcium_oxalate_recommendation.rec1 =
    "Avoid Excessive consumption of foods/beverages with high oxalate content\n-Spinach\n- Rhubarb\n- Cacao\n- Cashew / Nuts/ Hazelnuts/ Pistachios\n- Sesame\n- Tea (mainly Black Tea) \n- (Orange) Juice\n- Avoid excess Vitamin C Consumption\nMaintain the recommended daily Calcium intake (1gr/d)";
  
  }

  console.log(user_diet.sodium);
  return user_diet.sodium;
};
export const handleCystinePopupClose = (urinedata) => {
  user_diet.cystine = 0;
  const items = ["parmesan_cheese", "low_fluid", "egg", "protien_salt"];
  for (const [key, value] of Object.entries(urinedata.intake)) {
    if (items.indexOf(key) != -1) user_diet.cystine += value === true ? 1 : 0;
  }
  console.log(user_diet.cystine);

  if (urinedata.cystine_recommendation == undefined)
    urinedata.cystine_recommendation = {};
  urinedata.cystine_recommendation.cystine =
    " Avoid intake of foods with high cystine content: Parmesan Cheese, Eggs, Keep Fluid Intake > 3.5 Liters/day (urine output>3 Liters/day), Avoid High protein Intake/ High salt intake, Keep urine pH in normal value and not acidic (can promote uric acid stones)";
};

export const handleProtienIntakeSubmission = (urinedata) => {
  user_diet.protien = 0;
  const items = ["animal_meat", "dairy", "egg", ];
  console.log(urinedata.intake);
  for (const [key, value] of Object.entries(urinedata.intake)) {
    console.log(key);
    if (items.indexOf(key) != -1) user_diet.protien += value === true ? 1 : 0;
  }
  console.log(user_diet.protien);
};
