import { Button } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { DataGrid } from "@mui/x-data-grid";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchAllUsers,
  fetchPatientRecords,
  fetchUser,
  updateSuperAdmin,
} from "../../../api_calls/CallList";
import ModifyUserDialog from "./ModifyUserDialog";
export default function AllUsers(props) {
  useEffect(() => {
    fetchAllUsers().then((e) => {
        setrows(e);
    });
  }, []);


 
 const [modifyDialog, showModifyDialog] = useState(false);
 const [selectedUserForModification, setSelectedUserForModification] = useState(undefined); 

  const columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "gender", headerName: "Gender", width: 130 },
    { field: "createDate", headerName: "Create Date (DD/MM/YYYY)", width: 200, renderCell: (params) => {
        const date = new Date(params.row.createDate);
        return <p>{date.getDate() + " / " + date.getMonth() + ' / ' + date.getFullYear() }</p>

    } },
    { field: "country", headerName: "Country", width: 130 },
    { field: "state", headerName: "State", width: 130 },
    { field: "center_unique", headerName: "Center Unique Identifier", width: 170 },

    {
      contentAlign: "center",
      field: "moreInfo",
      headerName: "Modify User",
      renderCell: (params) => {
        const user = params.row;
        return (
        <>
        <button
        onClick={async (e) => {
            await updateSuperAdmin({id: user.uid}).then(e => {
                window.location.reload();
            });
        }}
         
        className={`${user.super_admin ? 'bg-red-600' : 'bg-green-400' } w-40 text-left px-3 py-2 rounded-sm text-white font-bold`} >{user.super_admin ? 'Revoke Super Admin' : 'Grant Super Admin'}</button>
        <button 
        
        onClick={(e) => {
            setSelectedUserForModification(user);
            showModifyDialog(true);

        }}
        className={`ml-4 bg-purple-500 px-3 py-2 rounded-sm text-white font-bold`} >Modify User</button>

        </>

        );
      },
      flex: 1,
    },
  ];

  const [rows, setrows] = useState([]);
 
  return (<>
    <ModifyUserDialog
    user={selectedUserForModification}
    isOpen={modifyDialog}
    callback={() => {
        showModifyDialog(false);
    }}  />
    <div
      style={{
        height: "70vh",
      }}
    >
      <div className="mt-10 text-4xl font-bold text-center border-b-2 pb-10">
        All Users
      </div>
      <div
        className="align-middle justify-center items-center sm:py-4 flex sm:flex-row flex-col"
        style={{
          justifyContent: "space-around",
        }}
      ></div>
      <div
        style={{
          height: "70vh",
        }}
      >
        <DataGrid
          sx={{
            textAlign: "center",
            padding: "10px 10px 0px 10px",
          }}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </div></>
  );
}
