import axios from "axios";
import React from "react";
import { submitPatientInfo } from "./api_calls/CallList";
import user_diet from "./components/form/user_diet";

export function SaveDatatoServer({
  user,
  final_recommendations,
  intake,
  callback,
}) {
  const [popup, setPopup] = React.useState([false, ""]);

  return (
    <div className="w-80 md:w-80 container px-5">
      <div>
        {popup[0] === true && (
          <div className="pr-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
            <div
              className="mx-auto relative px-12 py-6 w-auto md:w-auto bg-white rounded-2xl text-viodeep overflow-y-auto h-auto"
              style={{ maxHeight: "80%", maxWidth: "80%" }}
            >
              <button
                onClick={() => {
                  setPopup([false, ""]);
                }}
                className="absolute top-4 right-10 font-extrabold"
              >
                X
              </button>
              {popup[1] !== undefined && popup[1]}

              <div className="flex justify-center mt-6">
                <button
                  onClick={() => {
                    setPopup([false, ""]);
                  }}
                  className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <button
        className="w-full text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
        onClick={async () => {
          try {
         const res = await   submitPatientInfo({user, final_recommendations, user_diet});
           

            callback(res);
          } catch (error) {
            setPopup([true, error.toString()]);
          }
        }}
        disabled={
          // user.name === "" ||
          user.age === "" ||
          user.sex === "" ||
          user.weight === "" ||
          user.height === "" ||
          user.blood_creatinine === "" ||
          user.blood_calcium === "" ||
          user.ionized_calcium === "" ||
          user.fasting_glucose === "" ||
          user.blood_uric_acid === "" ||
          user.urine_creatininura === "" ||
          user.last_urine_volume === "" ||
          user.urine_calcium === "" ||
          user.urine_sodium === "" ||
          user.urine_urea_levels === "" ||
          user.urine_uric_acid === "" ||
          user.urine_ph === ""
            ? // user.previous_abdominal === "" ||
              // user.blood_creatinine === "" ||
              // user.blood_calcium === "" ||
              // user.ionized_calcium === "" ||
              // user.fasting_glucose === "" ||
              // user.blood_uric_acid === "" ||
              // user.blood_na === "" ||
              // user.blood_potassium === "" ||
              // user.magnesium === "" ||
              // user.phosphate === "" ||
              // user.chloride === "" ||
              // user.urine_creatininura === "" ||
              // user.last_urine_volume === "" ||
              // user.urine_calcium === "" ||
              // user.urine_sodium === "" ||
              // user.urine_oxalate === "" ||
              // user.urine_urea_levels === "" ||
              // user.urine_uric_acid === "" ||
              // user.citraturia === "" ||
              // user.cystinuria === "" ||
              // user.urine_ph === "" ||
              // user.morning_urine_specific_gravity === "" ||
              // user.urine_crystals === "" ||
              // user.urine_culture === "" ||
              // user.glucosuria === "" ||
              // user.calcium_intake === "" ||
              // user.oxalate_intake === "" ||
              // user.carbohydrate_intake === "" ||
              // user.causes_for_hyperUricosuria === "" ||
              // user.protein_intake === "" ||
              // user.for_cystinuria_patients === "" ||
              // user.parathyroid_hormone === "" ||
              // user.calcitriol === "" ||
              // user.blood_bicarbonate === "" ||
              // user.triglycerides === "" ||
              // user.cholesterol === ""
              true
            : false
        }
      >
        Generate Patient ID
      </button>
    </div>
  );
}
