import Multiselect from "multiselect-react-dropdown";
import Input from "../Input/input";

const DietrySurvey = ({user, handleChange}) => {
    const handleSaltChnage = (selectedList, selectedItem) => {
        user.salt_intake = [];
        for (let val = 0; val < selectedList.length; val++) {
          user.salt_intake.push(selectedList[val].name);
        }
        user.salt_intake = user.salt_intake;
    
        console.log(user.previous_abdominal);
      };
    

const saltOptions = {
    options: [
      { name: "Table Salt", id: 1 },
      { name: "Sparkling water: (Vichy, St Yorre, San Peligrino)", id: 2 },
      { name: "Sauces (Ketchup, Soya)", id: 3 },
      { name: "Other- Please Specify", id: 4 },
    ],
  };
  
  return (
    <div>
      {" "}
      <div className="container px-5 py-4 mx-auto flex sm:flex-nowrap flex-wrap items-center">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <h2 className="text-gray-900 text-xl mb-2 font-semibold title-font">
            Dietary Survey
          </h2>
          <div className="relative mb-8">
            <label
              htmlFor="calcium_intake"
              className="leading-7 text-sm text-gray-600"
            >
              Calcium Intake (Max 1-1.2 grams per day){" "}
            </label>
            <select
              name="calcium_intake"
              value={user.calcium_intake}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="Dairy Products - Milk [glass (~200mg)/day]">
                Dairy Products - Milk [glass (~200mg)/day]
              </option>
              <option value="Yogurt [Pieces(~200mg)/day]">
                Yogurt [Pieces(~200mg)/day]
              </option>
              <option
                value="Intake of Calcium rich bottled water [Vittel, Badoit (~200mg/Liter)] (Number of liters)"
              >
                Water Ca (mg)/Liter/day [Vittel, Badoit (~200mg/L)Calcium Rich
                mineral water]
              </option>
              <option value="Tap Water Ca (mg)/day (~100mg/L)">
                Tap Water Ca (mg)/day (~100mg/L)
              </option>
              <option value="Cheese [200mg Ca per ~30gr cheese (1 Product)]">
                Cheese [200mg Ca per ~30gr cheese (1 Product)]
              </option>
              <option value="Calcium Supplements">Calcium Supplements</option>
            </select>
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="amount_water_intake"
              className="leading-7 text-sm text-gray-600"
            >
              Water Intake
            </label>
            <select
              name="amount_water_intake"
              value={user.amount_water_intake}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="Less than 1 Litre">Less than 1 Litre</option>
              <option value="2 Litres">2 Litres</option>
              <option value="3 Litres">3 Litres</option>
              <option value="4 Litres">4 Litres</option>
              <option value="More than 4 Litres">More than 4 Litres</option>
            </select>
          </div>

          <div className="relative mb-8">
            <label
              htmlFor="salt_intake"
              className="leading-7 text-sm text-gray-600"
            >
              Salt Intake
            </label>

            <Multiselect
              id="salt_intake"
              className="bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
              options={saltOptions.options}
              selectedValues={saltOptions.selectedValue}
              displayValue="name"
              onSelect={handleSaltChnage}
              onRemove={handleSaltChnage}
            />
            <div className="mt-4"></div>
            <Input
              htmlFor={"specify_salt_one"}
              TextVal={"If Other please specify:"}
              Type={"text"}
              Id={"specify_salt_one"}
              Name={"specify_salt_one"}
              Value={user.specify_salt_one}
              OnChange={handleChange}
            />
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="oxalate_intake"
              className="leading-7 text-sm text-gray-600"
            >
              Oxalate Intake / causes for HyperOxaluria ({">"}50mg/day)
            </label>
            <select
              name="oxalate_intake"
              value={user.oxalate_intake}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="Spinach">Spinach</option>
              <option value="Rhubarb">Rhubarb</option>
              <option value="Cacao">Cacao</option>
              <option value="Cashew Nuts">Cashew Nuts</option>
              <option value="Hazelnuts">Hazelnuts</option>
              <option value="Pistachios">Pistachios</option>
              <option value="Sesame">Sesame</option>
              <option value="Vitamin C supplements">
                Vitamin C supplements
              </option>
              <option value="(Orange) Juice (alasses/dav)">
                (Orange) Juice (alasses/dav)
              </option>
              <option value="Tea (mainly Black Tea)">
                Tea (mainly Black Tea)
              </option>
              <option
                value="Calcium Deprivation/ Vitamin B6 Deficiency/ Recent Abx use/
            Short Bowel"
              >
                Calcium Deprivation/ Vitamin B6 Deficiency/ Recent Abx use/
                Short Bowel
              </option>
            </select>
          </div>
          <Input
            htmlFor={"specify_dietary_one"}
            TextVal={"If Other please specify:"}
            Type={"text"}
            Id={"specify_dietary_one"}
            Name={"specify_dietary_one"}
            Value={user.specify_dietary_one}
            OnChange={handleChange}
          />
        </div>
        <div className="md:w-1/2 bg-gray-300 rounded-lg p-6 flex items-center justify-center relative">
          <div className="bg-white relative py-6 rounded shadow-md px-6">
            <div className="">
              <h2 className="font-semibold text-gray-700 text-xs">
                <p className="font-bold pb-2">
                  In general, normal Daily Caloric requirements:
                </p>
                (1800-) 2000 KCalories/day (for 70 Kg person) <br />
                Of these:
                <br /> <br />
                <span className="font-bold">
                  Carbohydrates(4 Kcal/gr) :
                </span>{" "}
                45-50% (900Kcal) -{">"}225gr/day (Remember- carbs are not only
                sugar- they are also in other foods/drinks and not only
                sweets/Juice/Sodas)
                <br />
                <br />
                <span className="font-bold">Fat:</span> 35-40% (9 Kcal/gr) -
                {">"} 800Kcal-{">"} 77gr fat
                <br />
                <br />
                <span className="font-bold">Proteins:</span> 15% (4 Kcal/gr) -
                {">"} 300Kcal-{">"} 75gr proteins
                <br />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-5 pb-4 mx-auto flex sm:flex-nowrap flex-wrap items-center">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <div className="relative mb-8">
            <label
              htmlFor="carbohydrate_intake"
              className="leading-7 text-sm text-gray-600"
            >
              Carbohydrate Intake
            </label>
            <select
              name="carbohydrate_intake"
              value={user.carbohydrate_intake}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="Soda Drinks (e.g. Coca-Cola 330ml=36.25grams)">
                Soda Drinks (e.g. Coca-Cola 330ml=36.25grams)
              </option>
              <option value="Juices (~22gram/200mL->4-5 sugar pieces)">
                Juices (~22gram/200mL-{">"}4-5 sugar pieces)
              </option>
              <option value="Chocolate (Pieces/day)">
                Chocolate (Pieces/day)
              </option>
              <option value="Table Sugar (grams/day)">
                Table Sugar (grams/day)
              </option>
            </select>
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="causes_for_hyperUricosuria"
              className="leading-7 text-sm text-gray-600"
            >
              Causes for HyperUricosuria (High Urine Uric Acid)
            </label>
            <select
              name="causes_for_hyperUricosuria"
              value={user.causes_for_hyperUricosuria}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="High Animal Protein Intake-see Below">
                High Animal Protein Intake-see Below
              </option>
              <option value="High Fish/Anchovies consumption">
                High Fish/Anchovies consumption
              </option>
              <option value="Myeloproliferative Disease / Treatment">
                Myeloproliferative Disease / Treatment
              </option>
              <option
                value="Other Factors (Acidemia)- Insulin Resistance? Short
            Bowel/Dehydration? Gout?"
              >
                Other Factors (Acidemia)- Insulin Resistance? Short
                Bowel/Dehydration? Gout?
              </option>
            </select>
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="protein_intake"
              className="leading-7 text-sm text-gray-600"
            >
              Protein Intake (Max 0.8-1 gram/Kg/day)
            </label>
            <select
              name="protein_intake"
              value={user.protein_intake}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="Animal Meat Products - A 100 gram steak has ~25% protein in it">
                Animal Meat Products - A 100 gram steak has ~25% protein in it
              </option>
              <option value="Dairy Products and Vegetable Protein">
                Dairy Products and Vegetable Protein
              </option>
              <option value="Eggs (1 egg is ~30 grams of Protein)">
                Eggs (1 egg is ~30 grams of Protein)
              </option>
             {/* // <option value="Delicatessen/ Charcuterie">
               /// Delicatessen/ Charcuterie
              </option> */}
            </select>
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="for_cystinuria_patients"
              className="leading-7 text-sm text-gray-600"
            >
              For Cystinuria Patients:
            </label>
            <select
              name="for_cystinuria_patients"
              value={user.for_cystinuria_patients}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="Parmesan Cheese">Parmesan Cheese</option>
              <option value="Eggs">Eggs</option>
              <option value="Fluid Intake < 3.5 Liters day">
                Fluid Intake {"<"} 3.5 Liters day
              </option>
              <option value="High protein Intake/ High salt intake (see above)">
                High protein Intake/ High salt intake (see above)
              </option>
              <option
                value="Calcium Deprivation/ Vitamin B6 Deficiency/ Recent Abx use/
            Short Bowel"
              >
                Calcium Deprivation/ Vitamin B6 Deficiency/ Recent Abx use/
                Short Bowel
              </option>
            </select>
          </div>

          <Input
            htmlFor={"specify_dietary_two"}
            TextVal={"If Other please specify:"}
            Type={"text"}
            Id={"specify_dietary_two"}
            Name={"specify_dietary_two"}
            Value={user.specify_dietary_two}
            OnChange={handleChange}
          />
        </div>
                  
        <div className="md:w-1/2 bg-gray-300 rounded-lg p-6 flex items-center justify-center relative">
          <div className="bg-white relative py-6 rounded shadow-md px-6">
            <div className="">
              <h2 className="font-semibold text-gray-700 text-xs">
                <p className="font-bold">
                  As mentioned before, the normal Daily Caloric requirements:
                </p>
                ~ 2000 KCalories/day (for 70 Kg person) <br />
                Of these:
                <br />
                <br />
                <span className="font-bold">
                  Carbohydrates(4 Kcal/gr) :
                </span>{" "}
                45-50% (900Kcal) -{">"}225gr/day (Remember- carbs are not only
                sugar- they are also in other foods/drinks and not only
                sweets/Juice/Sodas)
                <br />
                Maximal Sugar Intake is ~37 gram/day or 6-8 teaspoons/day.
                <br />
                HyperUricemia{"&"}Uricosuria can result from:
                <br />
                - Increased Production from High Fructose intake: High corn
                syrup beverages consumption, uncontrolled DM
                <br />
                - Increased consumption: Seafood/Animal Meat (DNA from dead
                cells) , Alcohol
                <br />
                - Decreased Clearance [Dehydration- Low fluid intake, Diarrhea
                (Loss of fluid volume) etc.]
                <br />
                - Increased Acid load in the body[(Insulin Resistance, Diarrhea
                (loss of Bicarbonate)]
                <br />
                - High turnover of Purines (Chemo/RadioTx), Genetic
                Predisposition <br />
                - Medications: Thiazide Diuretics, Aspirin
                <br />
                <br />
                <span className="font-bold">Proteins:</span> 15% (4 Kcal/gr) -
                {">"} 300Kcal-{">"} 75gr proteins (depending on which meat-{">"}{" "}
                ~150 gr meat/day ={">"} 150/4=37.5 because not all 75 gr
                necessary protein will come from animal meat- there is protein
                in everything else)
                <br />
                <br />
                <span className="font-bold">Fat:</span> 35-40% (9 Kcal/gr) -
                {">"} 800Kcal-{">"} 77gr fat
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DietrySurvey;