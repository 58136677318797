//TODO ALGO 5

import React from "react";
import CustomCheckBox from "../custom_checkbox";
import CustomPopup from "../custom_popup";
import CustomRadioBox from "../custom_radio_box";
import Input from "../Input/input";
import {
  handleDairyIntakePostSubmission,
  handleUricSubmission,
  handleuricfators,
  handleSaltIntakeSubmission,
} from "./PopSubmissionHandlers";
import ResultUrineData from "./resultUrineData";
import user_diet from "./user_diet";

const ResultStoneData = ({ user, type, urinedata, setuc }) => {
  const DairyProductData = () => (
    <div>
      <h4 className="font-bold">
        Evaluation of Dietary sources for HyperCalciuria
      </h4>
      <p>Dairy Products</p>
      <Input
        TextVal="Milk[One Glass (~200mg)]"
        Value={urinedata.intake.milk}
        OnChange={(val) => {
          urinedata.intake.milk = val.target.value;
          // TODO ADD USER intake for urine calc assignment
        }}
        htmlFor="milk"
        Type={"number"}
      ></Input>
      <Input
        TextVal="Yogurt [One Piece (~200mg)]"
        Value={urinedata.intake.yog}
        OnChange={(val) => {
          urinedata.intake.yog = val.target.value;
        }}
        htmlFor="yogurt"
        Type={"number"}
      ></Input>
      <Input
        Value={urinedata.intake.cheese}
        TextVal="Cheese [200mg Ca per ~30gr cheese (1 Product)]"
        OnChange={(val) => {
          urinedata.intake.cheese = val.target.value;
        }}
        htmlFor="cheese"
        Type={"number"}
      ></Input>
      <Input
        Value={urinedata.intake.supp.value}
        TextVal="Calcium Supplements 0.5 , 1 grams"
        OnChange={(val) => {
          urinedata.intake.supp = val.target.value * 3;
        }}
        htmlFor="suppliments"
        Type={"number"}
      ></Input>
      <Input
        Value={urinedata.intake.mineral_water_calc.value}
        TextVal="Intake of Calcium rich bottled water [Vittel, Badoit (~200mg/Liter)] (Number of liters)"
        OnChange={(val) => {
          urinedata.intake.mineral_water_calc = val.target.value;
        }}
        htmlFor="mineral-calcium"
        Type={"number"}
      ></Input>{" "}
      <Input
        Value={urinedata.intake.tap_water_calc.value}
        TextVal="Tap Water Ca (mg)/day (~100mg/L) Number of liters"
        OnChange={(val) => {
          urinedata.intake.tap_water_calc = val.target.value / 2;
        }}
        htmlFor="tap-calcium"
        Type={"number"}
      ></Input>
    </div>
  );

  const resultTable = {
    calcium_oxalate_monohydrate: (
      <CustomPopup
        content={[
          // TODO POPUP 1
          <div>
            Is patient taking the recommended daily Calcium (1gram/day)? Ask for
            dairy products intake and calcium supplements: Low calcium intake
            will increase oxalate excretion in the urine instead of through the
            intestines.
            <CustomRadioBox
              data={urinedata.calcium_oxalate_recommendation.calcium}
              content={[
                {
                  name: "Normal calcium intake per day",

                  value: "None",
                },
                {
                  name: "Low calcium intake suspected: Confirm the Recommendation of : Normal daily calcium intake (1 gram/day): dairy products, calcium supplements.",

                  value:
                    "Maintain sufficient Calcium intake (1 gram per day). To consider: Treatment with Calcium Citrate 1 gram/day, with meals, and adjust dose according to results",
                },
              ]}
            />
          </div>,

          // TODO POPUP 2
          <div>
            Check for Excess Dietary Oxalate Intake in the dietary survey.
            <CustomCheckBox
              content={[
                {
                  name: "Spinach",
                  value: urinedata.intake.spinach,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["spinach"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Rhubarb",
                  value: urinedata.intake.rhubarb,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["rhubarb"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Cacao",
                  value: urinedata.intake.cacao,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["cacao"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Cashew / Nuts/ Hazelnuts/ Pistachios",
                  value: urinedata.intake.cashew,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["cashew"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Sesame",
                  value: urinedata.intake.seasame,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["seasame"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Tea (mainly Black Tea)",
                  value: urinedata.intake.tea,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["tea"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
            <Input
              TextVal="Orange Juice (Glasses/Day)"
              Value={urinedata.intake.orange_juice}
              OnChange={(val) => {
                urinedata.intake.orange_juice = val.target.value;
              }}
              htmlFor="orange_juice"
              Type={"number"}
            ></Input>
            {/* <CustomRadioBox
              data={
                urinedata.calcium_oxalate_recommendation.oxalate_monohydrate
              }
              id={1253}
              content={[
                { name: "No Recommendation required", value: "None" },
                {
                  name: "Recommendations in any case if there is a Calcium Oxalate Monohydrate Stone)",
                  value:
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcium_oxalate_recommendation: {
                    ...urinedata.calcium_oxalate_recommendation,
                    ["oxalate_monohydrate"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox> */}
            ,
          </div>,

          // TODO POPUP 3
          <div>
            Rule out excess Vitamin C Consumption? Vitamin C metabolism
            increases oxalate levels in the body
            <CustomRadioBox
              data={urinedata.calcium_oxalate_recommendation.vit_c}
              id={1253}
              content={[
                { name: "No excess Vitamin C Consumption", value: "None" },
                {
                  name: "Suspicion of excess Vitamin C Consumption",
                  value: "Avoid over consumption  of Vitamin C",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcium_oxalate_recommendation: {
                    ...urinedata.calcium_oxalate_recommendation,
                    ["vit_c"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,

          // TODO POPUP 4
          <div>
            Check for possible Short bowel syndrome / Inflammatory Bowel Disease
            or Previous Gastric bypass surgery.{" "}
            <CustomRadioBox
              data={urinedata.calcium_oxalate_recommendation.bowel_syndrome}
              id={12553}
              content={[
                {
                  name: "No Short bowel syndrome/Inflammatory Bowel Disease or previous Gastric bypass surgery",
                  value: "None",
                },
                {
                  name: "Known Short bowel syndrome or previous Gastric bypass surgery",
                  value:
                    "Maintain high fluid intake, Maintain sufficient Calcium intake (1 gram per day), Treatment with Calcium Citrate 1 gram/day, with meals, and adjust dose according to results. Keep patient on Low fat diet.",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcium_oxalate_recommendation: {
                    ...urinedata.calcium_oxalate_recommendation,
                    ["bowel_syndrome"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,

          // TODO POPUP 5

          <div>
            Rule out recent long-term Antibiotic treatment: Long-term antibiotic
            treatment eradicates the bacteria Oxalobacter formigenes in the
            intestine and might indirectly cause increased oxalate resorption.
            <CustomRadioBox
              data={
                urinedata.calcium_oxalate_recommendation.antibiotic_treatment
              }
              id={12553}
              content={[
                {
                  name: "No recent long-term Antibiotic treatment",
                  value: "None",
                },
                {
                  name: "Recent long-term Antibiotic treatment - Patient referred to treating physician to discuss alternatives to long-term antibiotic therapy",
                  value:
                    "Patient referred to treating physician to discuss alternatives to long-term antibiotic therapy",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcium_oxalate_recommendation: {
                    ...urinedata.calcium_oxalate_recommendation,
                    ["antibiotic_treatment"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,

          // TODO POPUP 6
          <div>
            Rule out known history of Primary HyperOxaluria in the family:
            Search for family members with early calcium oxalate stone disease
            and renal insufficiency due to stone disease.
            <CustomRadioBox
              data={
                urinedata.calcium_oxalate_recommendation.antibiotic_treatment
              }
              id={12553}
              content={[
                {
                  name: "No recent long-term Antibiotic treatment",
                  value: "None",
                },
                {
                  name: "Family members / history with primary hyperOxaluria- Patient referred to genetic analysis to rule out Primary HyperOxaluria",
                  value:
                    "Referred to nephrologic and genetic evaluation \n- High fluid intake to produce a urinary volume of 3 L/day \n- Potassium citrate to inhibit precipitation of calcium oxalate in the urine\n- Consider treatment trial for 3- to 6-months with Pyridoxine: At a starting dose of 5 mg/kg in patients with type 1 primary hyperoxaluria",
                },
              ]}
              handleChange={(val) => {}}
            ></CustomRadioBox>
          </div>,
        ]}
        onpopclose={() => {
          if (handleSaltIntakeSubmission(urinedata) > 0)
            setuc({
              ...urinedata,
              calcium_oxalate_recommendation: {
                ...urinedata.calcium_oxalate_recommendation,
                ["antibiotic_treatment"]:
                  "Avoid Excessive consumption of foods/beverages with high oxalate content\n-Spinach\n- Rhubarb\n- Cacao\n- Cashew / Nuts/ Hazelnuts/ Pistachios\n- Sesame\n- Tea (mainly Black Tea) \n- (Orange) Juice\n- Avoid excess Vitamin C Consumption\nMaintain the recommended daily Calcium intake (1gr/d)",
              },
            });
        }}
      />
    ),
    // hypercalcuria: ResultUrineData(
    //   "hyper_calc_w_normocalcemia",
    //   urinedata,
    //   setuc
    // ),
    // hyper_calc_w_hypernatruria: ResultUrineData(
    //   "hyper_calc_w_hypernatruria",
    //   urinedata,
    //   setuc
    // ),
    // hyper_calc_w_high_urine_urea: ResultUrineData(
    //   "hyper_calc_w_high_urine_urea",
    //   urinedata,
    //   setuc
    // ),
    cod: (
      <CustomPopup
        onNextCallback={() => {}}
        onPrevCallback={() => {}}
        onpopclose={() => {
          handleDairyIntakePostSubmission(urinedata);
        }}
        content={[
          //TODO [POPUP 1]
          <div className="text-left">
            <p className="font-bold">
              {" "}
              {
                "Rule out HyperParathyroidism [>85picogram/liter] (by far the most common cause for resorptive hypercalciruia and hypercalcemia):"
              }{" "}
            </p>
            <p> Primary Causes: </p>
            <p> {"Parathyroid Adenoma >90% of Primary causes"} </p>
            <p> • Parathyroid cancer (rare) </p>
            <p>
              {" "}
              • If parathyroid elevated refer to Endocrinology evaluation to
              rule out parathyroid adenoma or cancer.{" "}
            </p>

            <CustomRadioBox
              data={urinedata.calcuria_recommendation.HyperParathyroidism}
              id={8}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["HyperParathyroidism"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referral to Parathyroid blood test",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referral to Parathyroid blood test",
                  value: "Patient referred to Parathyroid blood test",
                },
              ]}
            />
          </div>,
          //TODO [POPUP 2]
          <DairyProductData />,

          // TODO [POPUP 3]
          <div className="text-left">
            <p className="font-bold">
              {" "}
              Differential Diagnoses for hypercalciuria due to Increased Bone
              resorption:
            </p>
            <p>• Prolonged immobilization </p>
            <p>
              {`• Increased constant acid load in the body – (Insulin resistance/high protein intake etc..) -> The suggested mechanism is that
                       it causes the release of Calcium from bone to buffer the acid`}
            </p>
            <p>• Chronic steroid use </p>
            <p>• HyperThy roidism </p>
            <p>• Oncologic patients: Osteolytic bone Metastasis </p>
            <p>
              • Oncologic patients: Parathyroid-related protein secreting
              malignancy{" "}
            </p>
            <p>
              {
                "• Paget’s disease of bone (accelerated bone remodeling -> deformities, bone pain"
              }
              )
            </p>
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation
                  .bone_resporption_recommendation
              }
              id={9}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["bone_resporption_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "If no cause suspected- Ignore recommendation for Patient referred to treating physician for evaluation of secondary bone resorption.",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referred to treating physician for evaluation of secondary bone resorption.",
                  value:
                    "Patient referred to treating physician for evaluation of secondary bone resorption.",
                },
              ]}
            />
          </div>,
          // TODO [POPUP 4]
          <div className="text-left">
            <p className="font-bold">
              Other Differential Diagnoses for hypercalciuria:
            </p>
            <p>• Prolonged immobilization </p>
            <p>or</p>
            <p>
              • Ectopic Production: Granulamatous Disorders: Sarcoidosis,
              Tuberculosis, lymphoma
            </p>
            <p>• HyperThy roidism </p>

            <p className="font-bold mt-2">
              If patient takes vitamin D supplement or oncologic patient: Check
              vitamin D levels and if high refer to treating physician and
              nephrologist
            </p>
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.vit_d_recommendation}
              id={10}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["vit_d_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referred to treating physician for evaluation of Vitamin D toxicity",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referred to treating physician for evaluation of Vitamin D toxicity",
                  value:
                    "Patient referred to treating physician for evaluation of Vitamin D toxicity",
                },
              ]}
            />
          </div>,

          // TODO [POPUP 5]
          <div className="text-left">
            <p className="font-bold">
              {
                "If patient on chronic lithium treatment- refer to treating physician for further follow up and treatment- to rule out Potential Lithium Treatment toxicity (Increases suppression threshold of PTH by calcium -> takes a higher Calcium level to suppress PTH)"
              }
            </p>
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.lithium_recommendation}
              id={11}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["lithium_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referred to treating physician for evaluation of Lithium toxicity",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referred to treating physician for evaluation of Lithium toxicity",
                  value:
                    "Patient referred to treating physician for evaluation of Lithium toxicity.",
                },
              ]}
            />
          </div>,
          // TODO [POPUP 6]
          <div className="text-left">
            <p>If Chronic Thiazide use: </p>
            <p className="font-bold">
              • Chronic treatment with thiazide diuretic might be a cause for
              hypercalcemia- refer to nephrologist and consider stopping
              thiazide.
            </p>
            <CustomRadioBox
              data={urinedata.calcuria_recommendation.thizize_recommendation}
              id={12}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["thizize_recommendation"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referral to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referral to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
                  value:
                    "Patient referral to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
                },
              ]}
            />
          </div>,
          // TODO [POPUP 7]
          <div className="text-left">
            <p className="font-bold">
              {
                "Very rare diagnosis: If patient has family members with urinary stones and hypercalcemia:"
              }
            </p>
            <p>
              - Consider the diagnosis of Familial Hypocalciuria-Hypercalcemia
              syndrome (Increases suppression threshold of PTH by calcium){" "}
            </p>
            <p>
              If suspected refer to nephrologist for further work up and
              diagnosis.
            </p>
            <CustomRadioBox
              data={
                urinedata.calcuria_recommendation.hypocalciuria_hypercalcemia
              }
              id={13}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.calcuria_recommendation,
                    ["hypocalciuria_hypercalcemia"]: val.target.value,
                  },
                });
              }}
              content={[
                {
                  name: "Ignore recommendation for Patient referral to nephrologist for further follow up.",
                  value: "None",
                },
                {
                  name: "Confirm recommendation for Patient referral to nephrologist for further follow up.",
                  value:
                    "Patient referral to nephrologist for further follow up.",
                },
              ]}
            />
          </div>,
        ]}
      />
    ),

    carbapatite: (
      <CustomPopup
        // TODO SET LIMIT FOR HYPER CALCURIA

        content={[
          <div>
            <p>
              {" "}
              Does patient have Alkaline Urine pH ? • Rule out Renal Tubular
              Acidosis (Check if Urine pH is{" > "}6.5):   Congenital- e.g.
              Albright syndrome  Or Acquired- e.g. Sjogren Syndrome
            </p>
            <CustomRadioBox
              data={urinedata.cabapatite_recommendation.urine_ph}
              id={12553}
              content={[
                {
                  name: "Normal urine pH",
                  value: "None",
                },
                {
                  name: "Urine pH alkaline (>6.5) : refer to nephrologist to rule out Renal Tubular Acidosis",
                  value:
                    "Patient referred to nephrologic evaluation to rule out Renal Tubular Acidosis",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  cabapatite_recommendation: {
                    ...urinedata.cabapatite_recommendation,
                    ["urine_ph"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            <p>
              Other alternative diagnosis that can cause Carbapatite stones:
              Medullary Sponge kidney (simultaneous multiple stone types) - No
              specific treatment but Continue close follow up with general
              recommendations
            </p>
            <CustomRadioBox
              data={urinedata.cabapatite_recommendation.urine_ph}
              id={12}
              content={[]}
            ></CustomRadioBox>
          </div>,
          <div>
            <p>
              Rule out chronic Urinary Tract Infection with non-urease Bacteria
            </p>
            <CustomRadioBox
              data={urinedata.brushite_recommendation.urine_culture}
              id={111}
              content={[
                {
                  name: "No Infection- Ignore recommendation to refer patient to Urine Culture",
                  value: "None",
                },
                {
                  name: "If recent sterile urine culture not available : Confirm recommendation to refer patient to Urine Culture",
                  value: "Patient referred to performance of Urine Culture",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  brushite_recommendation: {
                    ...urinedata.brushite_recommendation,
                    ["urine_culture"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
        ]}
      ></CustomPopup>
    ),
    brushite: (
      <CustomPopup
      onpopclose={() => {}}
        // TODO SET LIMIT FOR HYPER CALCURIA
      
        content={
         
           [
                <div>
                  <p>
                    {" "}
                    Does patient have Alkaline Urine pH ? • Rule out Renal
                    Tubular Acidosis (Check if Urine pH is{" > "}6.5):  
                    Congenital- e.g. Albright syndrome  Or Acquired- e.g.
                    Sjogren Syndrome
                  </p>
                  <CustomRadioBox
                    data={urinedata.cabapatite_recommendation.urine_ph}
                    id={12553}
                    content={[
                      {
                        name: "Normal urine pH",
                        value: "None",
                      },
                      {
                        name: "Urine pH alkaline (>6.5) : refer to nephrologist to rule out Renal Tubular Acidosis",
                        value:
                          "Patient referred to nephrologic evaluation to rule out Renal Tubular Acidosis",
                      },
                    ]}
                    handleChange={(val) => {
                      setuc({
                        ...urinedata,
                        cabapatite_recommendation: {
                          ...urinedata.cabapatite_recommendation,
                          ["urine_ph"]: val.target.value,
                        },
                      });
                    }}
                  ></CustomRadioBox>
                </div>,
                <div>
                  <p>
                    Other alternative diagnosis that can cause Brushite stones:
                    Medullary Sponge kidney (simultaneous multiple stone types)
                  </p>
                  {/* <CustomRadioBox
                    data={urinedata.cabapatite_recommendation.urine_ph}
                    id={12}
                    content={[]}
                  ></CustomRadioBox> */}
                </div>,
              ]
        }
      ></CustomPopup>
    ),
    struvite: (
      <CustomPopup
        content={[
          <div>
            <p>‘‘No bacteria, No stone’’</p>{" "}
            <p>
              ◦ Complete stone removal is mandatory - the patient must be stone
              free.
            </p>{" "}
            <p> Post Op: </p>
            <p>
              {" "}
              ◦ The patient can be placed on an antibiotic regimen for the first
              2 to 3 weeks following the procedure and subsequent stent removal{" "}
            </p>
            <p>
              {" "}
              ◦ A preventative dose of the antibiotic can be given for 6 to 12
              weeks.{" "}
            </p>
            ◦ If urine culture remains sterile after this interval, then it
            would be reasonable to obtain periodic urine cultures (i.e., at 3,
            6, and 12 months) to make sure that reinfection is not
            <p> occurring. </p>
            Alternative: Only in rare cases where Operation is not
            <p> possible: </p>
            <p>
              {" "}
              Acetohydroxamic acid (250mgX3/day or up to a maximum of 1.5
              g/day): blocks the urease enzyme of the bacteria.{" "}
            </p>
            <p>
              {" "}
              Warn patient against significant serious side effects like:
              PE/DVT, Anemia (12%–28%), nausea, and headaches.{" "}
            </p>
            <CustomRadioBox
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  struvite_recommendation: {
                    ...urinedata.struvite_recommendation,
                    ["fragments"]: val.target.value,
                  },
                });
              }}
              data={urinedata.struvite_recommendation.fragments}
              content={[
                {
                  name: "No suspicion for residual fragments",
                  value: "None",
                },
                {
                  name: "If residual fragments are suspected: refer patient to imaging to rule out residual fragments",
                  value:
                    "Patient referred to imaging to rule out residual fragments",
                },
                {
                  name: "If residual fragments are present: refer patient to surgery to treat residual fragments",
                  value:
                    "Patient referred to surgery to treat residual fragments",
                },
              ]}
            ></CustomRadioBox>
          </div>,

          <div>
            {
              <p>
                • If urine pH continuously high Control urine culture and
                eradicate any existing bacteria If necessary: Acidify urine with
                Ammonium Chloride 1gr*2-3/d for pH 6.5-7
              </p>
            }
            <CustomRadioBox
              data={urinedata.brushite_recommendation.urine_culture}
              content={[
                {
                  name: "Normal urine pH and No residual Infection- Ignore recommendation to refer patient to Urine Culture",
                  value: "None",
                },
                {
                  name: "Elevated urinary pH > 6.5 – Suspected/Residual infection : Confirm recommendation to refer patient to Urine Culture and begin treatment to acidify urine",
                  value:
                    "Repeat urine culture and antibiotic treatment if culture positive. Treatment to Acidify urine with Ammonium Chloride 1gr*2-3/d for pH 6.5-7",
                },
              ]}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  brushite_recommendation: {
                    ...urinedata.brushite_recommendation,
                    ["urine_culture"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
        ]}
      ></CustomPopup>
    ),
    uric: (
      <CustomPopup
        content={[
          <div className="text-left">
            Rule out a diet rich in purines (source of uric acid)
            <CustomCheckBox
              content={[
                {
                  name: "High Animal Protein Intake",
                  value: urinedata.intake.animal_protien,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["animal_protien"]: val.value,
                      },
                    });
                  },
                },
                // {
                //   name: "Charcuterie",
                //   value: urinedata.intake.charcuterie,
                //   handleChange: (val) => {
                //     setuc({
                //       ...urinedata,
                //       intake: {
                //         ...urinedata.intake,

                //         ["charcuterie"]: val.value,
                //       },
                //     });
                //   },
                // },
                {
                  name: "High Fish/Anchovies Consumption",
                  value: urinedata.intake.fish,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,
                        ["fish"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "High Alcohol Consumption",
                  value: urinedata.intake.alcohol,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["alcohol"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "High Carbohydrate Intake",
                  value: urinedata.intake.carbs,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["carbs"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Soda Drinks/Corn Syrup based beverages (e.g. Coca-Cola 330ml = 36.25 grams)",
                  value: urinedata.intake.soda,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["soda"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Juices (~22 gram/200mL -> 4-5 sugar peices)",
                  value: urinedata.intake.juice,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      intake: {
                        ...urinedata.intake,

                        ["juice"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
            <Input
              TextVal="Chocolate (Peices/day)"
              Value={urinedata.intake.chocolate}
              OnChange={(val) => {
                urinedata.intake.chocolate = val.target.value;

                // TODO ADD USER intake for urine calc assignment
              }}
              htmlFor="Chocolate"
              Type={"number"}
            ></Input>
            <Input
              TextVal="Table Sugar (grams/day)"
              Value={urinedata.intake.sugar}
              OnChange={(val) => {
                urinedata.intake.sugar = val.target.value;
              }}
              htmlFor="sugar"
              Type={"number"}
            ></Input>
            <CustomRadioBox
              data={urinedata.uric_recommendation.urine_uric}
              content={[
                {
                  name: "No dietary source for increased urine uric acid",

                  value: "None",
                },
                {
                  name: "Suspected high daily intake of urine uric acid: confirm recommendations to lower daily uric acid intake",
                  value:
                    "Avoid high daily protein intake (<0.8gr/kg/day): Animal Meat Products, Excessive intake of Dairy Products and Vegetables rich in Protein, Eggs",
                },
              ]}
              id={134}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["urine_uric"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,

          <div>
            Rule out Acidic urine pH (can promote formation of uric acid
            stones): check urine pH level in morning spot urine and keep it
            between 6-6.5
            <CustomRadioBox
              data={urinedata.uric_recommendation.ph_level}
              content={[
                {
                  name: "Refer patient to morning spot urine/ urine pH evaluation",

                  value:
                    "Refer patient to morning spot urine/ urine pH evaluation",
                },
                {
                  name: "Normal urine pH: Ignore recommendation for supplementation of Potassium Citrate",
                  value: "None",
                },
                {
                  name: "If acidic urine pH: Confirm recommendation for supplementation of Potassium Citrate",
                  value:
                    "Treatment with Potassium Citrate 20 mEq X3/day and follow up urine pH",
                },
              ]}
              id={1}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["ph_level"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            Rule out Myeloproliferative Disease / Treatment as cause to high
            urine uric acid (High turnover-destruction of body cells by
            Chemotherapy or Radiotherapy)
            <CustomRadioBox
              data={urinedata.uric_recommendation.myeloproliferative}
              content={[
                {
                  name: "No Myeloproliferative disease",

                  value: "None",
                },
                {
                  name: "If suspicion Myeloproliferative disease/treatment cause of high uric acid : Confirm recommendation for supplementation of Potassium Citrate / Hyperhydration",
                  value:
                    "Refer to treating physician- recommend treatment with hyperhydration / Potassium Citrate 20 mEq X3/day and follow up urine pH/ consider adding Allopurinol",
                },
              ]}
              id={1}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  calcuria_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["myeloproliferative"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            Rule out Other Factors:
            <CustomCheckBox
              content={[
                {
                  name: "Uncontrolled Diabetes Mellitus? Insulin Resistance? ",
                  value: urinedata.uric_recommendation.diabetes,
                  handleChange: (val) => {
                    // TODO ADD POINT SYSTEM FOR UREA NITOGEN
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["diabetes"]: val.value,
                      },
                    });
                  },
                },

                {
                  name: "Gout?",
                  value: urinedata.uric_recommendation.gout,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["gout"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Diarrhea (loss of Bicarbonate)",
                  value: urinedata.uric_recommendation.diarrhea,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["diarrhea"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Decreased Diuresis [Dehydration- Low fluid intake]",
                  value: urinedata.uric_recommendation.diuresis,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["diuresis"]: val.value,
                      },
                    });
                  },
                },
                {
                  name: "Medications: Thiazide Diuretics, Aspirin",
                  value: urinedata.uric_recommendation.medications,
                  handleChange: (val) => {
                    setuc({
                      ...urinedata,
                      uric_recommendation: {
                        ...urinedata.uric_recommendation,
                        ["medications"]: val.value,
                      },
                    });
                  },
                },
              ]}
            ></CustomCheckBox>
          </div>,

          <div>
            {
              <p>
                • If patient still hyperuricosuric despite dietary
                recommendations:
                <br /> Consider treatment with Allopurinol 100mg/d Warn patients
                regarding S/E of allopurinol: <br />◦ Hepatic dysfunction,
                Steven Johnson syndrome <br />◦ Check LFT and Creatinine and CBC
                periodically
              </p>
            }
            <CustomRadioBox
              data={urinedata.uric_recommendation.hyperuricosuric}
              content={[
                {
                  name: "Normal urine Uric Acid levels: Ignore recommendation for supplementation of P.O. Allopurinol 100 mg/day",
                  value: "None",
                },
                {
                  name: "Elevated urine Uric Acid levels: Confirm recommendation for supplementation of P.O. Allopurinol 100 mg/day ",
                  value:
                    "Consider treatment with Allopurinol 100mg/d \n- Warn patients regarding S/E of allopurinol: ◦ Hepatic dysfunction, Steven Johnson syndrome\n- Check LFT and Creatinine and CBC periodically",
                },
              ]}
              id={1}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["hyperuricosuric"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            {
              <p>
                • If patient still hyperuricosuric despite dietary
                recommendations and treatment with Allopurinol 100 mg/day:
                <br />
                Consider treatment with Allopurinol 300mg/d Warn patients
                regarding S/E of allopurinol: <br />◦ Hepatic dysfunction,
                Steven Johnson syndrome <br />◦ Check LFT and Creatinine and CBC
                periodically
              </p>
            }
            <CustomRadioBox
              data={urinedata.uric_recommendation.hyperuricosuric}
              content={[
                {
                  name: "Normal urine Uric Acid levels: Ignore recommendation for supplementation of P.O. Allopurinol 300 mg/day",
                  value: "None",
                },
                {
                  name: "Elevated urine Uric Acid levels: Confirm recommendation for supplementation of P.O. Allopurinol 300 mg/day ",
                  value:
                    "Consider treatment with Allopurinol 300mg/d \n- Warn patients regarding S/E of allopurinol: ◦ Hepatic dysfunction, Steven Johnson syndrome\n- Check LFT and Creatinine and CBC periodically",
                },
              ]}
              id={1}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.uric_recommendation,
                    ["hyperuricosuric"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
        ]}
        onpopclose={() => {
          handleUricSubmission(urinedata);
          handleuricfators(urinedata);
        }}
      ></CustomPopup>
    ),

    cystine: (
      <CustomPopup
        content={[
          <div>
            <p>
              The Goal is to reduce Cystine concentration in urine to{" "}
              {"<250mg/L"}
            </p>
            <p>◦ Check urine cystine levels</p>
            <p>
              ◦ Encourage Patient to Drink 3-4 liters of water to maintain {">"}
              3 liters of urine output
            </p>
            <p>
              ◦ Avoid overnight dehydration and patient is encouraged to wake up
              at least once a night to void and drink additional water.{" "}
            </p>
            <p>
              ◦ Check dietary intake responsible for high urine Cystine: Avoid
              foods rich in methionine (eggs, parmesan cheese)
            </p>
            <p>◦ Avoid High protein Intake</p>
            <p>◦ Avoid High salt intake</p>

            <CustomRadioBox
              data={urinedata.cystine_recommendation.cystine_stones}
              content={[
                {
                  name: "Ignore Above recommendations",
                  value: "None",
                },
                {
                  name: "Confirm Above Recommendations",
                  value:
                    "Avoid intake of foods with high cystine content:, Parmesan Cheese, Eggs, Keep Fluid Intake > 3.5 Liters/day (urine output>3 Liters/day), Avoid overnight dehydration and patient is encouraged to wake up at least once a night to void and drink additional water. , Sodium restriction to 100 mmol/day (2,300 mg/day), Protein restriction to 0.8 to 1 g/kg/day",
                },
              ]}
              id={1}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  uric_recommendation: {
                    ...urinedata.cystine_recommendation,
                    ["cystine_stoneshyperuricosuric"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            <p>If urine pH less than 7:</p>
            <p>
              {" "}
              Treatment with Potassium-citrate to maintain urine pH {">7"} (be
              careful from Calcium Phosphate stones development)
            </p>

            <CustomRadioBox
              data={urinedata.cystine_recommendation.urine_ph}
              content={[
                {
                  name: "Urine pH above 7- Ignore additional recommendation to change in treatment",
                  value: "None",
                },
                {
                  name: "Urine pH below 7 – Confirm Recommendation for Treatment with Potassium-citrate to maintain urine pH >7 (be careful from Calcium Phosphate stones development)",
                  value:
                    "Treatment with 20-30 mEq/day of Potassium-citrate to maintain urine pH >7 and follow up urine pH",
                },
              ]}
              id={21}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  cystine_recommendation: {
                    ...urinedata.cystine_recommendation,
                    ["urine_ph"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
          <div>
            <p>
              If all above options fail: give Thiola 200mg*2/d with Vit B6
              50mg/d Warn against side effects: mouth ulcers, nausea, and
              impaired sensation of taste. Also: Aplastic anaemia, leukopenia,
              proteinuria, nephrotic syndrome, and hematuria * 25% of patients
              fail to stay on this medication.
            </p>

            <CustomRadioBox
              data={urinedata.cystine_recommendation.thiola}
              content={[
                {
                  name: "Ignore recommendation for additional treatment",
                  value: "None",
                },
                {
                  name: "Confirm Recommendation for Treatment with PO Thiola 200mg*2/day with Vitamin B6 50mg/day",
                  value:
                    "Treatment with PO Thiola 200mg X 2/day and Vitamin B6 50mg/day",
                },
              ]}
              id={21}
              handleChange={(val) => {
                setuc({
                  ...urinedata,
                  cystine_recommendation: {
                    ...urinedata.cystine_recommendation,
                    ["thiola"]: val.target.value,
                  },
                });
              }}
            ></CustomRadioBox>
          </div>,
        ]}
      ></CustomPopup>
    ),
  };
  console.log(type);
  return type === "" ? <></> : resultTable[type];
};

export default ResultStoneData;
