import ResultStoneData from "./resultStoneData";
import ResultUrineData from "./resultUrineData";
import { blood_units, urine_units } from "./units";

const ResultStone = ({ user, urinedata, setuc }) => {
  console.log(user.type_of_stone);

  if (
    user.type_of_stone.indexOf("Calcium Oxalate Dihydrate (Weddellite)") > -1
  ) {
    urinedata.calcium_oxalate_recommendation.dihydrate_rec_stone =
      "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day\n- Avoid consumption of more than 3 milk products/day\n- Avoid calcium rich mineral water (e.g. Vittel-Badoit)\n- Avoid calcium supplements if not necessary";
  }
  const getUserStone = (stone) => {
    let val = "";
    let upperTitle = "";
    let c = "";
    switch (stone) {
      case "Brushite":
        c = (
          <div>
            <ResultStoneData
              user={user}
              type={"brushite"}
              urinedata={urinedata}
              setuc={setuc}
            />
            {user.urine_calcium > urine_units.urine_calcium.high && (
              <div>
                {" "}
                <p>
                  Attention to Hypercalciuria: rule out dietary sources for
                  increased urine calcium
                </p>
                <ResultUrineData
                  emptyList={[1]}
                  type="hyper_calc_w_normocalcemia"
                  urinedata={urinedata}
                  setuc={setuc}
                />{" "}
              </div>
            )}
          </div>
        );
        urinedata.calcuria_recommendation.general =
        "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day\n- Avoid consumption of more than 3 milk products/day\n- Avoid calcium rich mineral water (e.g. Vittel-Badoit)\n- Avoid calcium supplements if not necessary";
  
        upperTitle = "Check the possible etiologies for Brushite stones";
        val = "brushite";
        break;

      case "Carbapatite":
        upperTitle = "Check the possible etiologies for Carbapatite stones";

        c = (
          <div>
            <ResultStoneData
              user={user}
              type={"carbapatite"}
              urinedata={urinedata}
              setuc={setuc}
            />
            {user.urine_calcium > urine_units.urine_calcium.high && (
              <div>
                {" "}
                <p>
                  Attention to Hypercalciuria: rule out dietary sources for
                  increased urine calcium
                </p>
                <ResultUrineData
                  emptyList={[1]}
                  type="hyper_calc_w_normocalcemia"
                  urinedata={urinedata}
                  setuc={setuc}
                />{" "}
              </div>
            )}
          </div>
        );

        val = "carbapatite";
        break;

      case "Calcium Oxalate Dihydrate (Weddellite)":
        upperTitle =
          "Check the possible etiologies for Calcium Oxalate Dihydrate stones";
        urinedata.calcium_oxalate_recommendation.dihydrate_rec_stone =
          "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day\n- Avoid consumption of more than 3 milk products/day\n- Avoid calcium rich mineral water (e.g. Vittel-Badoit)\n- Avoid calcium supplements if not necessary";
        val = "cod";

      case "Cystine":
        upperTitle = "Check the possible etiologies for Cystine stones";

        val = "cystine";
        break;
      case "Calcium Oxalate Monohydrate (Whewellite)":
        urinedata.calcium_oxalate_recommendation.monohydrate_rec_stone =
          "Avoid Excessive consumption of foods/beverages with high oxalate content\n-Spinach\n- Rhubarb\n- Cacao\n- Cashew / Nuts/ Hazelnuts/ Pistachios\n- Sesame\n- Tea (mainly Black Tea) \n- (Orange) Juice\n- Avoid excess Vitamin C Consumption\nMaintain the recommended daily Calcium intake (1gr/d)";

        upperTitle =
          "Check the possible etiologies for Calcium Oxalate Monohydrate stones";
        c = (
          <ResultUrineData
            type="hyperoxaluria"
            urinedata={urinedata}
            setuc={setuc}
          />
        );
        val = "calcium_oxalate_monohydrate";
        break;
      case "Struvite":
        upperTitle = "Check the possible etiologies for Struvite stones";

        val = "struvite";
        break;
      case "Uric Acid":
       // if(user.urine_uric_acid > urine_units.urine_uric_acid.high || user.blood_uric_acid > blood_units.blood_uric_acid.high)
        urinedata.uric_recommendation.high_pro_rec1 =
        "Avoid intake of foods with high uric acid content:\n-  Animal Meat Products/Eggs/Fish/Anchovie\n-  Alcoholic beverage\n-  Avoid excessive carbohydrate intake / Fructose / Sweetened beverage\n-  Keep urine pH in normal value and not acidic (Acidic urine can promote the formation of uric acid stones)"
        // else
        // urinedata.uric_recommendation.high_pro_rec1 =
        //   "Avoid high daily protein intake (<0.8gr/kg/day): Animal Meat Products, Excessive intake of Dairy Products and Vegetables rich in Protein, Eggs";
        upperTitle = "Check the possible etiologies for Uric acid stones";
        val = "uric";
        break;
    }
    console.log(urinedata);
    return (
      <>
        {upperTitle}

        {c == "" ? (
          <ResultStoneData
            user={user}
            type={val}
            urinedata={urinedata}
            setuc={setuc}
          />
        ) : (
          c
        )}
      </>
    );
  };
  let cod = (
    <div>
      {" "}
      {user.urine_calcium > urine_units.urine_calcium.high && user.blood_calcium > blood_units.blood_calcium.high && (
        <div>
          {" "}
          <p>
            Attention: Hypercalciuria and Hypercalcemia<br></br> • Check Dietary
            calcium intake and make sure the limit of 1200 mg per day is not
            exceeded <br></br>• Rule out Hyperparathyroidism <br></br>• Rule out
            the Alternative Diagnoses for elevated blood calcium:
          </p>
        </div>
      )}{" "}
      {true && (
        <div>
          <ResultUrineData
            setuc={setuc}
            urinedata={urinedata}
            type="hyper_calc_w_normocalcemia"
          />
          <br></br>
        </div>
      )}
      {user.urine_calcium > urine_units.urine_calcium.high && user.urine_sodium > urine_units.urine_sodium.high && (
        <div>
          {" "}
          <p>
            Hypercalciuria and Hypernatruria <br />
            Attention to Hypercalciuria: result of high urine natrium and high
            salt intake.
          </p>
          <ResultUrineData
            setuc={setuc}
            urinedata={urinedata}
            type="hyper_calc_w_hypernatruria"
          />
          <br></br>
        </div>
      )}
      {user.urine_calcium > urine_units.urine_calcium.high && user.urine_urea_levels > urine_units.urine_urea_levels.high && (
        <div>
          <p>
            Attention to Hypercalciuria: <br /> Probably the result of high
            protein intake and increased acid load in the body
          </p>
          <ResultUrineData
            setuc={setuc}
            urinedata={urinedata}
            type="hyper_calc_w_high_urine_urea"
          />
          <br></br>
        </div>
      )}
      {user.urine_calcium > urine_units.urine_calcium.high && user.blood_calcium > blood_units.blood_calcium.high && (
        <div>
          <p>
            Attention: Hypercalciuria and Hypercalcemia: Check for
            Hyperparathyroidism (resorptive hypercalciuria) : rule out adenoma
            and refer to endocrinologic evaluation if Parathyroid elevated.{" "}
            <br />
            Remember the Alternative Diagnoses for elevated blood calcium (press
            on link)
          </p>
          <ResultUrineData
            setuc={setuc}
            urinedata={urinedata}
            type="hyper_calcuria_w_high_hypercalcemia"
          />
        </div>
      )}
    </div>
  );

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-2 mx-auto">
        <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
          Stone Results
        </div>
        <div className="w-full mx-auto overflow-auto mt-5">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                  Stone Selected
                </th>

                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Doctor{"'"}s comment
                </th>
              </tr>
            </thead>
            <tbody>
              {user.type_of_stone.indexOf(
                "Calcium Oxalate Dihydrate (Weddellite)"
              ) > -1 && (
                <tr className="border-t-2 border-gray-200 px-4 py-3">
                  <td className="px-4 py-3">
                    {
                      user.type_of_stone[
                        user.type_of_stone.indexOf(
                          "Calcium Oxalate Dihydrate (Weddellite)"
                        )
                      ]
                    }
                  </td>
                  <td className="px-4 py-3">
                    Check the possible etiologies for Calcium Oxalate Dihydrate
                    (Weddellite) stones
                    <br />
                    <br /> {cod}
                  </td>{" "}
                </tr>
              )}

              {user.type_of_stone
                .filter((e) => e !== "Calcium Oxalate Dihydrate (Weddellite)")
                .map((l) => {
                  return (
                    <tr className="border-t-2 border-gray-200 px-4 py-3">
                      <td className="px-4 py-3">{l}</td>
                      <td className="px-4 py-3">{getUserStone(l)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ResultStone;
{
  /* {user.urine_calcium > 7 && (
                       <div>
                         <p>
                           Check the possible etiologies for Calcium Oxalate
                           Monohydrate (Whewellite) stones (specific sentence for
                           each stone type)
                         </p>
                         <br></br>
                       </div>
                       // <div>
   
                       //   <button
                       //     className="bg-gray-500 text-white p-2 rounded-md text-xs"
                       //     onClick={() => {
                       //       setPopup(true);
                       //       setValue("HyperCalcuria_alone");
                       //     }}
                       //   >
                       //     Click here
                       //   </button>
                       // </div>
                     )} */
}
