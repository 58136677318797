import React from "react";
import { Chart } from "react-google-charts";
// import PatientDetails from "../components/form/patientDetails";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import user_diet from "../components/form/user_diet";
function DataIntakeGraph({ user }) {
  const intakeData = [];
  const maxValue = {
    sodium: 2,
    calcium: 5,
    urea_nitrogen: 2,
    cystine: 2,
    uric_acid: 3,
    protien: 2,
  };

  const a_diet = user.intake ?? user_diet;
  for (const [key, value] of Object.entries(a_diet)) {
    if (value > 0)
      intakeData.push({
        dataType: key,
        dataValue: value,
        dataColor: value > maxValue[key] ? "red" : "green",
      });
  }

  let seriesColorUrine = [];
  const pointRenderUrine = (args) => {
    seriesColorUrine.push(intakeData[args.point.index].dataColor);
    args.fill = seriesColorUrine[args.point.index];
  };

  // const intakeData = [
  //   ["Topic", "Normal", "High", "Low"],
  //   user.urine_creatininura < 150
  //     ? ["creatininura", 0, 0, user.urine_creatininura]
  //     : user.urine_creatininura > 200
  //     ? ["creatininura", 0, user.urine_creatininura, 0]
  //     : ["creatininura", user.urine_creatininura, 0, 0],
  //   user.last_urine_volume < 1900
  //     ? ["urine volume", 0, 0, user.last_urine_volume]
  //     : ["urine volume", user.last_urine_volume, 0, 0],
  //   user.urine_calcium < 7
  //     ? ["calcium", user.urine_calcium, 0, 0]
  //     : ["calcium", 0, user.urine_calcium, 0],
  //   user.urine_sodium < 130
  //     ? ["na", 0, 0, user.urine_sodium]
  //     : ["na", 0, user.urine_sodium, 0],
  //   user.urine_oxalate < 0.5
  //     ? ["oxalate", user.urine_oxalate, 0, 0]
  //     : ["oxalate", 0, user.urine_oxalate, 0],
  //   user.urine_urea_levels < 5.5
  //     ? ["urea levels", user.urine_urea_levels, 0, 0]
  //     : ["urea levels", 0, user.urine_urea_levels, 0],
  //   user.urine_uric_acid > 5
  //     ? ["uric acid", 0, user.urine_uric_acid, 0]
  //     : ["uric acid", user.urine_uric_acid, 0, 0],
  //   user.citraturia > 1.5
  //     ? ["citraturia", user.citraturia, 0, 0]
  //     : ["citraturia", 0, 0, user.citraturia],
  //   user.cystinuria > 60
  //     ? ["cystinuria", 0, user.cystinuria, 0]
  //     : ["cystinuria", user.cystinuria, 0, 0],
  // ];

  // const UrineOptions = {
  //   chart: {
  //     vAxis: {textPosition:'none' } ,
  //     hAxis: { textPosition: 'none', title: '  ',  titleTextStyle: {color: 'white'}  ,      slantedText:true,
  //     slantedTextAngle:45  },
  //     legend:  { position: "none" },
  //     title: "Urine Result Graph",
  //     subtitle: "Check your Urine levels respectively",
  //     isStacked: true,

  //   },
  // };
  return (
    <div className="md:px-6 my-8 ">
      {/* <PatientDetails user={user} /> */}
      {/* <div className="w-full rounded-lg shadow-lg p-8 bg-white bg-opacity-75 mt-4">
        <Chart
          
          chartType="Bar"
          width="100%"
          height="400px"
          options={bloodOptions}
          data={bloodData}
          loader={<div>Loading Blood Data...</div>}
          
        />
      </div> */}

      {/* <div className="w-full rounded-lg shadow-lg p-8 bg-white bg-opacity-75 mt-4">
        <Chart 
          
          
          chartType="Bar"
          width="100%"
          height="400px"
          options={UrineOptions}
          data={intakeData}
          loader={<div>Loading Urine Data...</div>}
        />
      </div> */}

      <div class="lg:-mb-20 sm:my-0">
        <ChartComponent
          title="Dietary Intake Graph"
          width="640"
          height="550"
          pointRender={pointRenderUrine}
          style={{ overflow: "auto" }}
          primaryXAxis={{
            valueType: "Category",
            labelIntersectAction: "Rotate90",
            labelPosition: "Outside",
          }}
          legendSettings={{ visible: true, shapeHeight: 12, shapeWidth: 12 }}
        >
          <Inject
            services={[
              ColumnSeries,
              Legend,
              Tooltip,
              DataLabel,
              LineSeries,
              Category,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={intakeData}
              xName="dataType"
              type="Column"
              yName="dataValue"
              name="High"
              legendShape="Circle"
              fill="red"
            />
            <SeriesDirective name="Normal" legendShape="Circle" fill="green" />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
}

export default DataIntakeGraph;
