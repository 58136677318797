import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem } from "@material-ui/core";
import { wait, waitFor } from "@testing-library/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { update } from "react-spring";
import DataGraph from "../../pages/dataGraphBlood";
import DataGraphUrine from "../../pages/DataGraphUrine";
import DataIntakeGraph from "../../pages/DataIntakeGraph";
import Export from "../../pages/ExportPDF";
import { SaveDatatoServer } from "../../SaveDatatoServer";
import Input from "../Input/input";
import PatientDetails from "./patientDetails";
import { Input as MuiIP } from "@material-ui/core";
import { blood_units, urine_units } from "./units";
import { getPatientInfo } from "../../api_calls/CallList";
import LoadingGif from "../../LoadingGif";
import { setUser } from "../../store/actions/UserAction";

const PatientDetailsFromServer = () => {
  const [popup, setpopup] = React.useState(false);
  const [user, setuser] = React.useState(undefined);
  const [error, errorMsg] = React.useState({ message: "", enabled: false });
  const [loading, setLoading] = React.useState(false);

  const params = new URLSearchParams(useLocation().search);
  const [patientID, setPID] = React.useState(params.get('id'));

  const fetchAndSet = async () => {
    try {
      console.log(patientID);
      if (patientID.length > 5) {
        getPatientInfo(patientID).then(data => {

          console.log("LOGGING DATA");
          console.log(data);
          setUser({comments: data.comments, intake: data.intake,})
          delete data.intake;
          delete data.recommend;

          setuser({...user, 'user': data});


        }).then(e => {

          setpopup(false);

        })






      } else {
        throw "Patient ID is invalid";
      }

    } catch (error) {


      setTimeout(() => {
        errorMsg({ enabled: false, message: error.toString() });
      }, 3000);
      errorMsg({ enabled: true, message: error.toString() });
    }
  }

  useEffect(() => {

    if (params.get('id')) {
      setpopup(false);
      setLoading(true);
      console.log(patientID)
      fetchAndSet().then(e => setLoading(false)).catch(e => setpopup(true));



    }
    else {
      setpopup(true);
      
    }
  }, []);

  if (loading) {
    return <LoadingGif />
  }

  return (
    <>
      {!popup && user && (
        <ShowDataFetchedFromServer
          patientID={patientID}
          intake={user.intake}
          recommendation={user.recommendation}
          userinfo={user.user}
        />
      )}
      <div className="h-screen">
        {popup === true && (
          <div className="pr-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
            <div
              className="mx-auto relative px-12 py-6 w-auto md:w-auto bg-white rounded-2xl text-viodeep h-52 overflow-y-auto h-auto "
              style={{ maxHeight: "80%", maxWidth: "80%" }}
            >
              {error.enabled && (
                <div className="w-full py-4 px-2 bg-red-600 rounded-lg text-white font-bold text-center">
                  {error.message}
                </div>
              )}
              <Input
                isRequired={true}
                htmlFor={`patient_id`}
                TextVal={`Patient ID`}
                Type={`number`}
                Id={`patient_id`}
                Name={`patient_id`}
                //   Value={patientID}
                OnChange={(e) => {

                  setPID(e.target.value)
                }}
              />
              <button
                onClick={async () => {

                }}
                className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold w-full justify-center items-center"
              >
                Submit
              </button>

              <div className="flex justify-center mt-6"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function ShowDataFetchedFromServer({ intake, recommendation, userinfo }) {
  React.useEffect((e) => {
    document.scrollingElement.scrollTo({ top: 0 });
  }, []);
  let a = {};
  for (const [key, val] of Object.entries(userinfo)) {
    if (val !== "" && val !== null && val !== undefined) {
      a[key] = val;
    } else {
      a[key] = "";
    }
  }
  const user = a;

  let [content, setcontent] = React.useState([]);
  const [popup, setpopup] = React.useState(false);

  const [recommendations, setrecommendations] = React.useState(
    recommendation ?? []
  );
  console.log("LOGGING REC: " + recommendations);

  let followup = "";
  let newins = "";
  const [addition, setaddition] = React.useState(false);

  //parseData(user, urinedata);

  const [instructions, updateI] = React.useState([
    "FLUID INTAKE SHOULD BE >3-4 LITERS/DAY TO PRODUCE >2 LITERS OF URINE PER DAY.",
    "CALCIUM INTAKE SHOULD BE 1-1.2 GRAMS PER DAY (~ THREE dairy products per day)",
    "SALT INTAKE SHOULD BE < 7GR/DAY.",
    "PROTEIN INTAKE SHOULD BE LESS THAN 0.8GR/KG/DAY.",
    "SUGAR INTAKE SHOULD BE LESS THAN 36GR/DAY.",
    "WEIGHT LOSS AND WORKOUT.",
    "INCREASE CONSUMPTION OF FIBERS FROM VEGETABLES AND FRUITS",
  ]);

  const updatedIns = instructions.map((instruction, i) => {
    return (
      <li>
        {" "}
        <div
          className="flex mb-2"
          onClick={(e) => {
            newins = instruction[i];
            const a = [instruction, i, "ins"];
            setcontent(a);
            wait(() => {
              setpopup(true);
            }, 500);
          }}
        >
          <div>{i + 1 + ": "}</div>
          <pre className="whitespace-pre-wrap"> {instruction}</pre>{" "}
        </div>{" "}
      </li>
    );
  });
  return (
    <div>
      {popup === true && (
        <div className="pr-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
          <div
            className="mx-auto relative px-10 py-10  md:w-auto bg-white rounded-2xl text-viodeep w-96  overflow-y-auto h-auto"
          //  style={{ maxHeight: "80vh", maxWidth: "80vw" }}
          >
            <button
              onClick={() => {
                setpopup(false);
                newins = "";
              }}
              className="absolute top-4 right-10 font-extrabold my-1 "
            >
              X
            </button>
            <textarea
              rows={15}
              cols={50}
              type=""
              //  value={newins}
              onChange={(e) => {
                newins = e.target.value.toUpperCase();

                console.log(newins);
              }}
              defaultValue={content[0]}
            ></textarea>
            <div className="flex justify-center mt-6">
              <button
                onClick={() => {
                  if (content[2] === "ins") {
                    console.log(content[0]);
                    let a = instructions;
                    a[content[1]] = newins;
                    console.log(a[content[1]]);
                    updateI(a);
                  } else {
                    let a = recommendations;
                    a[content[1]] = newins;
                    setrecommendations(a);
                  }
                  setpopup(false);
                  newins = "";
                }}
                className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
              >
                {"Edit"}
              </button>

              <button
                onClick={() => {
                  if (content[2] === "ins") {
                    updateI(instructions.filter((e) => e !== content[0]));
                  } else {
                    setrecommendations(
                      recommendations.filter((e) => e !== content[0])
                    );
                  }

                  setpopup(false);
                  newins = "";
                }}
                className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold ml-10"
              >
                {"Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="container px-2 py-6 mx-auto md:px-3  lg:px-7"
        id="tobeprinted"
      >
        <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mb-4 justify-between flex">
          <p className="font-bold border-black font-mono lg:text-3xl text-lg">
            Patient's Summary
          </p>
        </div>
        <PatientDetails
          id={user.patient_id}
          user={user}
          disabledComment={true}
        />
        <div class="flex flex-col lg:flex-row items-start">
          <div className="container mx-auto mt-20 rounded-lg shadow-lg   bg-gray-100 bg-opacity-75">
            <div className="py-2 text-sm font-bold border-b-2 w-full  border-indigo-500">
              Blood Results
            </div>
            <div className="w-full mx-auto overflow-auto mt-2">
              <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                  <tr>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                      Result Type
                    </th>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                      Report Value
                    </th>
                    {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Patient{"'"}s Advise
                </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Blood Creatinine{" "}
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.blood_creatinine}{" "}
                      {blood_units.blood_creatinine.unit}{" "}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.blood_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Blood Calcium
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.blood_calcium} {blood_units.blood_calcium.unit}{" "}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.blood_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Blood ionized Calcium
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.ionized_calcium} {blood_units.ionized_calcium.unit}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.ionized_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      Blood fasting glucose
                    </td>
                    <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                      {user.fasting_glucose} {blood_units.fasting_glucose.unit}
                    </td>
                    {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.fasting_glucose_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      Blood uric acid levels
                    </td>
                    <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                      {user.blood_uric_acid} {blood_units.blood_uric_acid.unit}
                    </td>
                    {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_uric_acid_p}
                </td> */}
                  </tr>
                  {user.blood_na !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Sodium (Na)
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.blood_na} {blood_units.blood_na.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_na_p}
                </td> */}
                    </tr>
                  )}
                  {user.blood_potassium !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Potassium level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.blood_potassium}{" "}
                        {blood_units.blood_potassium.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_potassium_p}
                </td> */}
                    </tr>
                  )}
                  {user.magnesium !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Magnesium level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.magnesium} {blood_units.magnesium.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.magnesium_p}
                </td> */}
                    </tr>
                  )}
                  {user.phosphate !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Phosphate level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.phosphate} {blood_units.phosphate.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.phosphate_p}
                </td> */}
                    </tr>
                  )}
                  {user.chloride !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Chloride level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.chloride} {blood_units.chloride.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.chloride_p}
                </td> */}
                    </tr>
                  )}
                  {user.parathyroid_hormone !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Parathyroid hormone level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.parathyroid_hormone}{" "}
                        {blood_units.parathyroid_hormone.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.parathyroid_hormone_p}
                </td> */}
                    </tr>
                  )}
                  {user.calcitriol !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Calcitriol hormone level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.calcitriol} {blood_units.calcitriol.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.calcitriol_p}
                </td> */}
                    </tr>
                  )}
                  {user.blood_bicarbonate !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Bicarbonate level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.blood_bicarbonate}{" "}
                        {blood_units.blood_bicarbonate.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_bicarbonate_p}
                </td> */}
                    </tr>
                  )}
                  {user.triglycerides !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Triglycerides level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.triglycerides} {blood_units.triglycerides.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.triglycerides_p}
                </td> */}
                    </tr>
                  )}
                  {user.cholesterol !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Cholesterol level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.cholesterol} {blood_units.cholesterol.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.cholesterol_p}
                </td> */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div>{<DataGraph user={user} />}</div>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row mt-10 items-start ">
          <div className="container mx-auto mt-10  rounded-lg shadow-lg  bg-gray-100 bg-opacity-75 align-middle justify-center justify-self-center">
            <div className="py-2 text-sm text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500 align-middle justify-center justify-self-center">
              Urine Results
            </div>
            <div className="w-full mx-auto overflow-auto mt-5">
              <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                  <tr>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                      Result Type
                    </th>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                      Report Value
                    </th>
                    {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Patient{"'"}s Advise
                </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold text-sm px-4 py-3">
                      Creatininura
                    </td>
                    <td className="px-4 text-sm py-3">
                      {user.urine_creatininura}{" "}
                      {urine_units.urine_creatininura.unit}
                    </td>
                    {/* <td className="px-4 py-3">{user.urine_creatininura_p}</td> */}
                  </tr>
                  <tr>
                    <td className="font-bold  text-sm border-t-2 border-gray-200 px-4 py-3">
                      Urine Volume
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.last_urine_volume}{" "}
                      {urine_units.last_urine_volume.unit}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.last_urine_volume_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Urine Calcium
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.urine_calcium} {urine_units.urine_calcium.unit}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.urine_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                      Urine Sodium (Na)
                    </td>
                    <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                      {user.urine_sodium} {urine_units.urine_sodium.unit}
                    </td>
                    {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_sodium_p}
                </td> */}
                  </tr>
                  {user.urine_oxalate !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Oxalate
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.urine_oxalate} {urine_units.urine_oxalate.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_oxalate_p}
                </td> */}
                    </tr>
                  )}
                  {user.urine_urea_levels !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Urea Levels
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.urine_urea_levels}{" "}
                        {urine_units.urine_urea_levels.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_urea_levels_p}
                </td> */}
                    </tr>
                  )}
                  {user.urine_uric_acid !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Uric acid Levels
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.urine_uric_acid}{" "}
                        {urine_units.urine_uric_acid.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_uric_acid_p}
                </td> */}
                    </tr>
                  )}
                  {user.citraturia !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Citrate
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.citraturia} {urine_units.citraturia.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.citraturia_p}
                </td> */}
                    </tr>
                  )}
                  {user.cystinuria !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Cystine Levels
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.cystinuria} {urine_units.cystinuria.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.cystinuria_p}
                </td> */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div>{<DataGraphUrine user={user} />}</div>
          </div>
        </div>
        <div className="mb-40 mt-36 mx-auto w-full justify-end">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            Intake Graph
          </div>{" "}
          <DataIntakeGraph user={user} intake={intake}></DataIntakeGraph>
        </div>
        <div className="w-full rounded-lg shado  w-lg p-8 bg-gray-100 bg-opacity-75 mt-12">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            Diagnosis Summary
          </div>
          <div className="leading-loose px-4 py-3 border-t-2 border-b-2"></div>
          <br></br>

          <p>
            {" "}
            {user.age} Year old {user.gender} patient
          </p>
          <p>Type(s) of stone: {user.type_of_stone ? user.type_of_stone.join(", ") : ''}</p>
          <p>Background Conditions: {user.background_conditions ? user.background_conditions.join(", ") : ''}</p>
        </div>
        <div className="w-full rounded-lg shado  w-lg p-8 bg-gray-100 bg-opacity-75 mt-12">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            Patient's Advices
          </div>
          <div className="leading-loose px-4 py-3 border-t-2 border-b-2">
            <ul>
              {recommendations.map((val, i) => {
                return (
                  <li>
                    {" "}
                    <div
                      className="flex mb-2"
                      onClick={(e) => {
                        newins = val;
                        const a = [val, i, "rec"];
                        setcontent(a);
                        wait(() => {
                          setpopup(true);
                        }, 500);
                      }}
                    >
                      <pre className="whitespace-pre-wrap"> {val}</pre>{" "}
                    </div>{" "}
                  </li>
                );
              })}
            </ul>
            {!addition && (
              <div>
                <button
                  className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  onClick={() => {
                    setaddition(!addition);
                  }}
                >
                  Add Advise
                </button>
                <br></br>
              </div>
            )}
            {addition && (
              <div className="w-30">
                <br></br>
                <MuiIP
                  onChange={(e) => {
                    newins = e.target.value;
                  }}
                />
                <br></br>
                <button
                  onClick={(e) => {
                    if (recommendations.indexOf(newins) === -1) {
                      setrecommendations([
                        ...recommendations,
                        recommendations.length + newins.toUpperCase(),
                      ]);
                      newins = "";
                      setaddition(false);
                    } else {
                      alert("This instruction is already there");
                    }
                  }}
                  className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                >
                  Submit
                </button>
                <button
                  onClick={(e) => {
                    setaddition(false);
                  }}
                  className="w-30 ml-3 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full rounded-lg shado  w-lg p-8 bg-gray-100 bg-opacity-75 mt-12">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            General Instructions
          </div>
          <div className="leading-loose px-4 py-3 border-t-2 border-b-2">
            <ul>{updatedIns}</ul>
          </div>
          {!addition && (
            <div>
              <button
                className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={() => {
                  setaddition(!addition);
                }}
              >
                Add Instruction
              </button>
              <br></br>
            </div>
          )}
          {addition && (
            <div className="w-30">
              <br></br>
              <MuiIP
                onChange={(e) => {
                  newins = e.target.value;
                }}
              />
              <br></br>
              <button
                onClick={(e) => {
                  if (instructions.indexOf(newins) === -1) {
                    updateI([...instructions, newins.toUpperCase()]);
                    newins = "";
                    setaddition(false);
                  } else {
                    alert("This instruction is already there");
                  }
                }}
                className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Submit
              </button>
              <button
                onClick={(e) => {
                  setaddition(false);
                }}
                className="w-30 ml-3 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Cancel
              </button>
            </div>
          )}
          <div className="mt-10">
            <label className="">FOLLOW UP MEETING IN:</label>
            <MuiIP
              className="ml-3"
              onChange={(e) => {
                followup = e.target.value;
              }}
            ></MuiIP>
            <button
              onClick={(e) => {
                if (instructions.indexOf(newins) === -1) {
                  updateI([
                    ...instructions,

                    "Follow up meeting in ".toUpperCase() +
                    followup.toUpperCase(),
                  ]);
                  newins = "";
                  setaddition(false);
                } else {
                  alert("This instruction is already there");
                }
              }}
              className="w-30 mt-8 mx-10 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              ADD
            </button>
          </div>
        </div>
        {/*       
      <button
          className="w-3/12 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          onClick={() => {
            setStep(step + 1);
          }}
          
      >
        Check Patient Report
      </button>  */}
      </div>

      {/* <Export></Export> */}
    </div>
  );
}

export default PatientDetailsFromServer;
