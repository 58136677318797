import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, List, ListItem } from "@material-ui/core";
import { wait } from "@testing-library/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { update } from "react-spring";
import DataGraph from "../../pages/dataGraphBlood";
import DataGraphUrine from "../../pages/DataGraphUrine";
import DataIntakeGraph from "../../pages/DataIntakeGraph";
import Export from "../../pages/ExportPDF";
import { SaveDatatoServer } from "../../SaveDatatoServer";
import Popup from "../popup/popup";
import PatientDetails from "./patientDetails";
import { blood_units, urine_units } from "./units";

const parseData = (user, urinedata) => {
  console.log(user);
  console.log("PARSING");

  console.log(urinedata);
  const final_recommendations = [];

  // CHECKING FOR BMI
  let BMI = (user.weight / (((user.height / 100) * user.height) / 100)).toFixed(
    3
  );
  if (BMI > 25) {
    final_recommendations.push("Patient Overweight- Weight loss Encouraged");
  }
  // FOR CALCIUM ADV
  if (urinedata.intake.calcium > 5) {
    final_recommendations.push(
      "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day\n- Avoid consumption of more than 3 milk products/day\n- Avoid calcium rich mineral water (e.g. Vittel-Badoit)\n- Avoid calcium supplements if not necessary"
    );
  }
  if (urinedata.intake.sodium > 1)
    final_recommendations.push(
      "Avoid Excessive consumption of foods/beverages with high oxalate content"
    );

  if (urinedata.intake.sodium > 2) {
    final_recommendations.push(
      "Avoid foods and beverages with high salt content: \n-Table Salt\n-Sparkling water: Vichy, St Yorre, San Peligrino\n-Sauces (Ketchup, Soya)"
    );
  }

  // PARSING OTHERS
  for (const [key, val] of Object.entries(user)) {
    if (
      (key.substring(key.length - 2, key.length) == "_p" ||
        key.substring(key.length - 4, key.length) == "_low" ||
        key.substring(key.length - 5, key.length) == "_high" ||
        key.substring(key.length - 3, key.length) == "_up" ||
        key.substring(key.length - 5, key.length) == "_down") &&
      val != "" &&
      val != " " &&
      val != undefined &&
      val != true &&
      val != false
    ) {
      if (typeof val === "object") {
        for (const [k, v] of val) {
          final_recommendations.push(v);
        }
      } else final_recommendations.push(val);
    }
  }

  for (const [key, val] of Object.entries(urinedata)) {
    if (key.includes("recommendation")) {
      for (const [innerkey, innerVal] of Object.entries(urinedata[key])) {
        if (
          innerVal != "None" &&
          innerVal != "" &&
          innerVal != undefined &&
          innerVal != true &&
          innerVal != false
        ) {
          //   console.log(key);          console.log("PF");

          if (typeof innerVal === "object" && key != "other_factors") {
            for (const [k, v] of innerVal) {
              final_recommendations.push(v);
            }
          } else final_recommendations.push(innerVal);
        }
        console.log(final_recommendations);
      }
    }
  }
  if (urinedata.uric_recommendation.other_factors)
    for (const [key, val] of Object.entries(
      urinedata.uric_recommendation.other_factors
    )) {
      if (val != "" && val != undefined && val !== "None") {
        console.log(final_recommendations);

        final_recommendations.push("For " + key + ": " + val);
        console.log(final_recommendations);
      }
    }

  let all_recommendations = [...new Set(final_recommendations)];

  return all_recommendations;
};

function PatientAD(props) {
  let [patientID, setPatientID] = React.useState(undefined);
  let c = "";  let followuptime = "months";


  let [content, setcontent] = React.useState([]);

  const [popup, setpopup] = React.useState(false);

  React.useEffect((e) => {
    document.scrollingElement.scrollTo({ top: 0 });
  }, []);

  let followup = "";
  let newins = "";
  const [addition, setaddition] = React.useState(false);

  const user = props.user;
  const urinedata = props.urinedata;

  //parseData(user, urinedata);
  const [recommendations, setrecommendations] = React.useState([]);

  const [instructions, updateI] = React.useState([
    "Fluid intake should be >3-4 liters/day to produce >2 liters of urine per day.",
    "Calcium intake should be 1-1.2 grams per day (~ three dairy products per day)",
    "Salt intake should be < 7gr/day.",
    "Protein intake should be less than 0.8gr/kg/day.",
    "Sugar intake should be less than 36gr/day.",
    "Weight loss and workout.",
    "Increase consumption of fibers from vegetables and fruits",
  ]);

  React.useEffect(() => {
    setrecommendations(parseData(user, urinedata));
  }, []);

  const updatedIns = instructions.map((instruction, i) => {
    return (
      <li>
        {" "}
        <div
          className="flex mb-1"
          onClick={(e) => {
            newins = instruction[i];
            const a = [instruction, i, "ins"];
            setcontent(a);
            wait(() => {
              setpopup(true);
            }, 500);
          }}
        >
          <div>{i + 1 + ": "}</div>
          <pre className="whitespace-pre-wrap"> {instruction}</pre>{" "}
        </div>{" "}
      </li>
    );
  });
  return (
    <div>
      {popup === true && (
        <div className="pr-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
          <div
            className="mx-auto relative px-10 py-10  md:w-auto bg-white rounded-2xl text-viodeep w-96  overflow-y-auto h-auto"
            //  style={{ maxHeight: "80vh", maxWidth: "80vw" }}
          >
            <button
              onClick={() => {
                setpopup(false);
                newins = "";
              }}
              className="absolute top-4 right-10 font-extrabold my-1 "
            >
              X
            </button>
            <textarea
              rows={15}
              cols={50}
              type=""
              //  value={newins}
              onChange={(e) => {
                newins = e.target.value.toUpperCase();

                console.log(newins);
              }}
              defaultValue={content[0]}
            ></textarea>
            <div className="flex justify-center mt-6">
              <button
                onClick={() => {
                  if (content[2] === "ins") {
                    console.log(content[0]);
                    let a = instructions;
                    a[content[1]] = newins;
                    console.log(a[content[1]]);
                    updateI(a);
                  } else {
                    let a = recommendations;
                    a[content[1]] = newins;
                    setrecommendations(a);
                  }
                  setpopup(false);
                  newins = "";
                }}
                className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
              >
                {"Edit"}
              </button>

              <button
                onClick={() => {
                  if (content[2] === "ins") {
                    updateI(instructions.filter((e) => e !== content[0]));
                  } else {
                    setrecommendations(
                      recommendations.filter((e) => e !== content[0])
                    );
                  }

                  setpopup(false);
                  newins = "";
                }}
                className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold ml-10"
              >
                {"Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="container px-2 py-6 mx-auto md:px-3  lg:px-7"
        id="tobeprinted"
      >
        <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mb-4 justify-between flex">
          <p className="font-bold border-black font-mono lg:text-3xl text-lg">
            Patient's Summary
          </p>
          {!patientID && (
            <SaveDatatoServer
              callback={(val) => {
                console.log("Callback Function");
                setPatientID(val.id);
              }}
              user={user}
              final_recommendations={recommendations}
              intake={urinedata.intake}
            />
          )}
        </div>
        <PatientDetails id={patientID} user={user} disabledComment={true} />
        <div class="flex flex-col lg:flex-row items-start">
          <div className="container mx-auto mt-20 rounded-lg shadow-lg   bg-gray-100 bg-opacity-75">
            <div className="py-2 text-sm font-bold border-b-2 w-full  border-indigo-500">
              Blood Results
            </div>
            <div className="w-full mx-auto overflow-auto mt-2">
              <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                  <tr>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                      Result Type
                    </th>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                      Report Value
                    </th>
                    {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Patient{"'"}s Advise
                </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Blood Creatinine{" "}
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.blood_creatinine}{" "}
                      {blood_units.blood_creatinine.unit}{" "}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.blood_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Blood Calcium
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.blood_calcium} {blood_units.blood_calcium.unit}{" "}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.blood_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Blood ionized Calcium
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.ionized_calcium} {blood_units.ionized_calcium.unit}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.ionized_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      Blood fasting glucose
                    </td>
                    <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                      {user.fasting_glucose} {blood_units.fasting_glucose.unit}
                    </td>
                    {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.fasting_glucose_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      Blood uric acid levels
                    </td>
                    <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                      {user.blood_uric_acid} {blood_units.blood_uric_acid.unit}
                    </td>
                    {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_uric_acid_p}
                </td> */}
                  </tr>
                  {user.blood_na !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Sodium (Na)
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.blood_na} {blood_units.blood_na.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_na_p}
                </td> */}
                    </tr>
                  )}
                  {user.blood_potassium !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Potassium level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.blood_potassium}{" "}
                        {blood_units.blood_potassium.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_potassium_p}
                </td> */}
                    </tr>
                  )}
                  {user.magnesium !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Magnesium level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.magnesium} {blood_units.magnesium.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.magnesium_p}
                </td> */}
                    </tr>
                  )}
                  {user.phosphate !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Phosphate level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.phosphate} {blood_units.phosphate.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.phosphate_p}
                </td> */}
                    </tr>
                  )}
                  {user.chloride !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Chloride level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.chloride} {blood_units.chloride.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.chloride_p}
                </td> */}
                    </tr>
                  )}
                  {user.parathyroid_hormone !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Parathyroid hormone level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.parathyroid_hormone}{" "}
                        {blood_units.parathyroid_hormone.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.parathyroid_hormone_p}
                </td> */}
                    </tr>
                  )}
                  {user.calcitriol !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Calcitriol hormone level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.calcitriol} {blood_units.calcitriol.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.calcitriol_p}
                </td> */}
                    </tr>
                  )}
                  {user.blood_bicarbonate !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Bicarbonate level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.blood_bicarbonate}{" "}
                        {blood_units.blood_bicarbonate.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.blood_bicarbonate_p}
                </td> */}
                    </tr>
                  )}
                  {user.triglycerides !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Triglycerides level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.triglycerides} {blood_units.triglycerides.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.triglycerides_p}
                </td> */}
                    </tr>
                  )}
                  {user.cholesterol !== "" && (
                    <tr>
                      <td className="font-bold text-sm border-t-2 border-b-2 border-gray-200 px-4 py-3">
                        Blood Cholesterol level
                      </td>
                      <td className="border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        {user.cholesterol} {blood_units.cholesterol.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.cholesterol_p}
                </td> */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div>{<DataGraph user={user} />}</div>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row mt-10 items-start ">
          <div className="container mx-auto mt-10  rounded-lg shadow-lg  bg-gray-100 bg-opacity-75 align-middle justify-center justify-self-center">
            <div className="py-2 text-sm text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500 align-middle justify-center justify-self-center">
              Urine Results
            </div>
            <div className="w-full mx-auto overflow-auto mt-5">
              <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                  <tr>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                      Result Type
                    </th>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                      Report Value
                    </th>
                    {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Patient{"'"}s Advise
                </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold text-sm px-4 py-3">
                      Creatininura
                    </td>
                    <td className="px-4 text-sm py-3">
                      {user.urine_creatininura}{" "}
                      {urine_units.urine_creatininura.unit}
                    </td>
                    {/* <td className="px-4 py-3">{user.urine_creatininura_p}</td> */}
                  </tr>
                  <tr>
                    <td className="font-bold  text-sm border-t-2 border-gray-200 px-4 py-3">
                      Urine Volume
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.last_urine_volume}{" "}
                      {urine_units.last_urine_volume.unit}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.last_urine_volume_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-gray-200 px-4 py-3">
                      Urine Calcium
                    </td>
                    <td className="border-t-2 text-sm border-gray-200 px-4 py-3">
                      {user.urine_calcium} {urine_units.urine_calcium.unit}
                    </td>
                    {/* <td className="border-t-2 border-gray-200 px-4 py-3">
                  {user.urine_calcium_p}
                </td> */}
                  </tr>
                  <tr>
                    <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                      Urine Sodium (Na)
                    </td>
                    <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                      {user.urine_sodium} {urine_units.urine_sodium.unit}
                    </td>
                    {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_sodium_p}
                </td> */}
                  </tr>
                  {user.urine_oxalate !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Oxalate
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.urine_oxalate} {urine_units.urine_oxalate.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_oxalate_p}
                </td> */}
                    </tr>
                  )}
                  {user.urine_urea_levels !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Urea Levels
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.urine_urea_levels}{" "}
                        {urine_units.urine_urea_levels.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_urea_levels_p}
                </td> */}
                    </tr>
                  )}
                  {user.urine_uric_acid !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Uric acid Levels
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.urine_uric_acid}{" "}
                        {urine_units.urine_uric_acid.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.urine_uric_acid_p}
                </td> */}
                    </tr>
                  )}
                  {user.citraturia !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Citrate
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.citraturia} {urine_units.citraturia.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.citraturia_p}
                </td> */}
                    </tr>
                  )}
                  {user.cystinuria !== "" && (
                    <tr>
                      <td className="font-bold border-t-2 text-sm border-b-2 border-gray-200 px-4 py-3">
                        Urine Cystine Levels
                      </td>
                      <td className="border-t-2 border-b-2 text-sm border-gray-200 px-4 py-3">
                        {user.cystinuria} {urine_units.cystinuria.unit}
                      </td>
                      {/* <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                  {user.cystinuria_p}
                </td> */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div>{<DataGraphUrine user={user} />}</div>
          </div>
        </div>
        <div className="mb-40 mt-36 mx-auto w-full justify-end">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            Intake Graph
          </div>{" "}
          <DataIntakeGraph user={user}></DataIntakeGraph>
        </div>
        <div className="w-full rounded-lg shado  w-lg p-8 bg-gray-100 bg-opacity-75 mt-12">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500 mb-6">
            Diagnosis Summary
          </div>
          <p>
            {" "}
            {user.age} Year Old {user.sex} Patient
          </p>
          {/* <div className="leading-loose px-4 py-3 border-t-2 border-b-2"> */}
            {user.type_of_stone.length > 0 && (
              <p>Type(s) of Stone: {user.type_of_stone.join(", ")}</p>
            )}{" "}
            {user.background_conditions.length > 0 && (
              <p>
                Background Conditions: {user.background_conditions.join(", ")}
              </p>
            )}
          {/* </div> */}
          <br></br>
        </div>
        <div className="w-full rounded-lg shado  w-lg p-8 bg-gray-100 bg-opacity-75 mt-12">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            Patient's Advices
          </div>
          <div className="leading-loose px-4 py-3 border-t-2 border-b-2">
            <ul>
              {recommendations.map((val, i) => {
                return (
                  <li>
                    {" "}
                    <div
                      className="flex mb-2"
                      onClick={(e) => {
                        newins = val;
                        const a = [val, i, "rec"];
                        setcontent(a);
                        wait(() => {
                          setpopup(true);
                        }, 500);
                      }}
                    >
                      <pre className="whitespace-pre-wrap"> {val}</pre>{" "}
                    </div>{" "}
                  </li>
                );
              })}
            </ul>
            {!addition && (
              <div>
                <button
                  className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  onClick={() => {
                    setaddition(!addition);
                  }}
                >
                  Add Advise
                </button>
                <br></br>
              </div>
            )}
            {addition && (
              <div className="w-30">
                <br></br>
                <Input
                  onChange={(e) => {
                    newins = e.target.value;
                  }}
                ></Input>
                <br></br>
                <button
                  onClick={(e) => {
                    if (recommendations.indexOf(newins) === -1) {
                      setrecommendations([
                        ...recommendations,
                        newins,
                      ]);
                      newins = "";
                      setaddition(false);
                    } else {
                      alert("This instruction is already there");
                    }
                  }}
                  className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                >
                  Submit
                </button>
                <button
                  onClick={(e) => {
                    setaddition(false);
                  }}
                  className="w-30 ml-3 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full rounded-lg shado  w-lg p-8 bg-gray-100 bg-opacity-75 mt-12">
          <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
            General Instructions
          </div>
          <div className="leading-loose px-4 py-3 border-t-2 border-b-2">
            <ul>{updatedIns}</ul>
          </div>
          {!addition && (
            <div>
              <button
                className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={() => {
                  setaddition(!addition);
                }}
              >
                Add Instruction
              </button>
              <br></br>
            </div>
          )}
          {addition && (
            <div className="w-30">
              <br></br>
              <Input
                onChange={(e) => {
                  newins = e.target.value;
                }}
              ></Input>
              <br></br>
              <button
                onClick={(e) => {
                  if (instructions.indexOf(newins) === -1) {
                    updateI([...instructions, newins.toUpperCase()]);
                    newins = "";
                    setaddition(false);
                  } else {
                    alert("This instruction is already there");
                  }
                }}
                className="w-30 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Submit
              </button>
              <button
                onClick={(e) => {
                  setaddition(false);
                }}
                className="w-30 ml-3 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Cancel
              </button>
            </div>
          )}
          <div className="mt-10">

          <div className="flex content-between w-full justify-between align-top">
            <div>
            <label className="items-center justify-center">Follow up meeting in:</label>
            <input
              className="w-14 mx-3 h-14 bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => {
                followup = e.target.value;
              }}
            ></input>
            <select
            //value={followuptime}
              name="for_time"
              // value={user.for_cystinuria_patients}
              onChange={(e) => {

                followuptime  = e.target.value;
              }}
              className="bg-gray-100 h-14 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              
              <option value="months">Months</option>
              <option value="days">Days</option>
              <option value="weeks">Weeks </option>
              <option value="years">Years </option>
            </select>{" "}
            <button
              onClick={(e) => {
                if (instructions.indexOf(newins) === -1) {
                  updateI([
                    ...instructions,

                    "Follow up meeting in " + followup + " " + followuptime
                  ]);
                  newins = "";
                  setaddition(false);
                } else {
                  alert("This instruction is already there");
                }
              }}
              className="w-30 mx-10 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              ADD
            </button>
            </div>
            {!patientID && (
            <SaveDatatoServer
              callback={(val) => {
                console.log("Callback Function");
                setPatientID(val.id);
              }}
              user={user}
              final_recommendations={recommendations}
              intake={urinedata.intake}
            />
          )}
          </div>
          </div>
        </div>
        {/*       
      <button
          className="w-3/12 mt-8 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          onClick={() => {
            setStep(step + 1);
          }}
          
      >
        Check Patient Report
      </button>  */}
      </div>

      {/* <Export></Export> */}
    </div>
  );
}

export default PatientAD;
