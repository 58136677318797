import { Button, Collapse, IconButton } from "@material-ui/core";
import { ActionTypes } from "@mui/base";
import { Alert } from "@mui/material";
import { create } from "@mui/material/styles/createTransitions";
import { updateProfile } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { fetchUser, updateUserProfile } from "../../../api_calls/CallList";

import LoadingGif from "../../../LoadingGif";
import { setUser } from "../../../store/actions/UserAction";
import Input from "../../Input/input";

export default function UserProfile() {



  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    severe: 'success',
    display: false,
    msg: ''
  })

  const [inputForChange, setInputForChange] = useState({})

  let originalData = useRef({});
  useEffect(() => {
    fetchUser().then(e => {
      originalData.current = e;
      setInputForChange(e);
    }).then(e => {
      setLoading(false);

    })

  }, [])


  const updateUser = async (input) => {
    setDisabled(true);
    try {
      const updateData = {};
      for (const [key, value] of Object.entries(inputForChange)) {
        if (value !== originalData.current[key]) {
          updateData[key] = value;

        }

      }

      if (Object.keys(updateData).length > 0) {
        await updateUserProfile(updateData);
        await fetchUser().then(e => {
          dispatch(setUser(e))

        })

        setAlert({ severe: 'success', display: true, msg: 'Success' });

      }

    }
    catch (error) {
      setAlert({ severe: 'error', display: true, msg: error.toString() });

    }
    finally {
      setDisabled(false);
    }

  }


  let center = '';
  if (inputForChange.center)
    center = inputForChange.center.country + ' - ' + inputForChange.center.state + ' - ' + inputForChange.center.center_unique


  return (
    <div>
      <Collapse in={alert.display}>
        <Alert
          severity={alert.severe}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ display: false });
              }}
            >
              Close
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alert.msg}
        </Alert>
      </Collapse>

      {!loading ? (
        <div className="p-10 mt-30 flex flex-col w-full items-center justify-center mb-10">
          <div className="mt-10 w-full text-center font-bold text-3xl pb-5 align-middle justify-center">
            MY PROFILE
          </div>

          <div className="grid rounded-2xl shadow items-center align-middle p-10 justify-center flex-col border-2">
            <div>
              <Input
                isRequired={true}
                defaultValue={inputForChange.name}
                Name={"name"}
                OnChange={(e) => setInputForChange({ ...inputForChange, name: e.target.value })}
                htmlFor={"name"}
                TextVal={"Name"}
                Placeholder={"Enter the name"}
              />
              <label htmlFor="sex" className="leading-7 text-sm text-gray-600">
                Gender <span className="text-red-500">*</span>
              </label>
              <br />
              <select
                defaultValue={inputForChange.gender}

                onChange={(e) => {
                  setInputForChange({ ...inputForChange, gender: e.target.value });
                }}
                name="gender"
                className="bg-gray-100 w-full rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value="" disabled hidden>
                  Select your gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
              <div className="mt-4"></div>
              <Input
                Type={"email"}
                isRequired={true}
                Name={"email"}
                IsDisabled={true}
                defaultValue={inputForChange.email}
                OnChange={(e) => setInputForChange({ ...inputForChange, email: e.target.value })}
                htmlFor={"email"}
                TextVal={"Email (Ask admin to update)"}
                Placeholder={"Enter the Email"}
              />
              <Input
                Type={"password"}
                Name={"password"}
                OnChange={(e) => setInputForChange({ ...inputForChange, password: e.target.value })}
                htmlFor={"password"}
                TextVal={"Update your Password"}
                Placeholder={"Enter a password"}
              />{" "}

              <Input
                Type={"text"}
                isRequired={true}
                Name={"center"}
                Value={center}

                IsDisabled={true}
                TextVal={"Your Center (Only admins can update)"}
              />
              <button
                onClick={(e) => {
                  updateUser(inputForChange);

                }}
                disabled={disabled}
                className="disabled:bg-red-500 w-full text-white bg-indigo-500 border-0 py-2 focus:outline-none hover:bg-indigo-600 rounded mb-10 text-lg"
              >
                Update Profile
              </button>
            </div>
          </div>
        </div>
      ) : (
        <LoadingGif />
      )}
    </div>
  );
}
