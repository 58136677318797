
const userReducer = (state = {}, action) => {
  if (action.type === "SET_USER") {
    state = Object.assign(state, action.payload);
  }
  console.log(
    "LOL"
  );
  return state;
};
export default userReducer;