import React from "react";
import Data from "../popup/data";
import ResultUrineData from "./resultUrineData";

import { blood_units, urine_units } from "./units";
function ResultBlood(props) {
  const user = props.user;
  const [popup, setPopup] = React.useState(false);
  const [value, setValue] = React.useState("");
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-2 mx-auto">
        <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
          Blood Results
        </div>
        <div className="w-full mx-auto overflow-auto mt-5">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                  Result Type
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Report Value
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Doctor{"'"}s comment
                </th>
              </tr>
            </thead>
            <tbody>
              {user.blood_creatinine !== "" && (
                <tr>
                  <td className="font-bold px-4 py-3">Blood Creatinine</td>
                  <td className="px-4 py-3">
                    {user.blood_creatinine} {blood_units.blood_creatinine.unit}
                  </td>
                  <td className="px-4 py-3">
                    {user.blood_creatinine >
                      blood_units.blood_creatinine.high && (
                      <div>
                        <p>Elevated blood creatinine levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_creatinine");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_creatinine <=
                      blood_units.blood_creatinine.high && (
                      <p>Normal blood creatinine levels</p>
                    )}
                  </td>
                </tr>
              )}
              {user.blood_calcium !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-gray-200 px-4 py-3">
                    Blood Calcium
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.blood_calcium} {blood_units.blood_calcium.unit}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.blood_calcium > blood_units.blood_calcium.high && (
                      <div>
                        <p>Elevated blood calcium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_calcium");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_calcium < blood_units.blood_calcium.low && (
                      <div>
                        <p>Low blood calcium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_calcium_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_calcium >= blood_units.blood_calcium.low &&
                      user.blood_calcium <= blood_units.blood_calcium.high && (
                        <div>
                          <p>Normal Blood Calcium Levels</p>
                        </div>
                      )}
                    {/* {user.urine_calcium > urine_units.urine_calcium.high &&
                      user.urine_sodium > urine_units.urine_sodium.high && (
                        <div>
                          <p>
                            HyperCalciuria AND Hypernatruria <br />
                            Attention to Hypercalciuria: result of high urine
                            natrium and high salt intake.
                          </p>
                          <ResultUrineData
                            setuc={props.setuc}
                            urinedata={props.urinedata}
                            type="hyper_calc_w_hypernatruria"
                          />
                          <br></br>
                        </div>
                      )} */}
                  </td>
                </tr>
              )}
              {user.ionized_calcium !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-gray-200 px-4 py-3">
                    Blood Ionized Calcium
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.ionized_calcium} {blood_units.ionized_calcium.unit}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.ionized_calcium >
                      blood_units.ionized_calcium.high && (
                      <div>
                        <p>Elevated blood ionized calcium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("ionized_calcium");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {/* {user.ionized_calcium >= 1.3 && (
                      <div>
                        <p>Elevated blood ionized calcium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("ionized_calcium");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )} */}

                    {/* {user.urine_calcium > urine_units.urine_calcium.high &&
                      user.urine_sodium > urine_units.urine_sodium.high && (
                        <div>
                          <p>
                            HyperCalciuria AND Hypernatruria <br />
                            Attention to Hypercalciuria: result of high urine
                            natrium and high salt intake.
                          </p>
                          <ResultUrineData
                            setuc={props.setuc}
                            urinedata={props.urinedata}
                            type="hyper_calc_w_hypernatruria"
                          />
                          <br></br>
                        </div>
                      )} */}
                    {user.ionized_calcium <= blood_units.ionized_calcium.high &&
                      user.ionized_calcium >=
                        blood_units.ionized_calcium.low && (
                        <p>Normal blood ionized calcium levels</p>
                      )}
                    {user.ionized_calcium < blood_units.ionized_calcium.low && (
                      <div>
                        {" "}
                        <p>Low blood ionized calcium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("ionized_calcium_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              )}{" "}
              {user.fasting_glucose !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood fasting glucose
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.fasting_glucose} {blood_units.fasting_glucose.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.fasting_glucose >
                      blood_units.fasting_glucose.high && (
                      <div>
                        <p>Elevated blood fasting glucose levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("fasting_glucose");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.fasting_glucose <=
                      blood_units.fasting_glucose.high && (
                      <p>Normal blood fasting glucose levels</p>
                    )}
                  </td>
                </tr>
              )}
              {user.blood_uric_acid !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood uric acid levels
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_uric_acid} {blood_units.blood_uric_acid.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_uric_acid >
                      blood_units.blood_uric_acid.high && (
                      <div>
                        <p>
                          Elevated blood uric acid levels: Increased risk for
                          uric acid stones {"-"} especially if low urine pH.{" "}
                          <br />
                          Check urine pH and check dietary intake responsible
                          for high urine uric acid:
                          <br /> Diet rich with purines (source of uric acid)
                          like red meat- seafood or Excessive Carbohydrate
                          consumption (soda, high fructose content)
                        </p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_uric_acid");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_uric_acid <=
                      blood_units.blood_uric_acid.high && (
                      <div>
                        <p>Normal blood uric acid levels</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}{" "}
              {user.blood_na !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Sodium (Na)
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_na} {blood_units.blood_na.unit}{" "}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_na < blood_units.blood_na.low && (
                      <div>
                        <p>Low blood sodium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_na_down");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_na > blood_units.blood_na.high && (
                      <div>
                        <p>High blood sodium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_na_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_na >= blood_units.blood_na.low &&
                      user.blood_na <= blood_units.blood_na.high && (
                        <p>Normal blood sodium levels</p>
                      )}
                  </td>
                </tr>
              )}
              {user.blood_potassium != "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Potassium level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_potassium} {blood_units.blood_potassium.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_potassium < blood_units.blood_potassium.low && (
                      <div>
                        <p>Low blood potassium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_potassium_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_potassium >
                      blood_units.blood_potassium.high && (
                      <div>
                        <p>High blood potassium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_potassium_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_potassium >= blood_units.blood_potassium.low &&
                      user.blood_potassium <=
                        blood_units.blood_potassium.high && (
                        <p>Normal blood potassium levels</p>
                      )}
                  </td>
                </tr>
              )}{" "}
              {user.magnesium != "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Magnesium level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.magnesium} {blood_units.magnesium.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.magnesium < blood_units.magnesium.low && (
                      <div>
                        <p>Low blood magnesium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("magnesium_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.magnesium > blood_units.magnesium.high && (
                      <div>
                        <p>High blood magnesium levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("magnesium_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.magnesium >= blood_units.magnesium.low &&
                      user.magnesium <=
                        blood_units.magnesium.high && (
                        <p>Normal blood magnesium levels</p>
                      )}
                  </td>
                </tr>
              )}{" "}
              {user.phosphate !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Phosphate level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.phosphate} {blood_units.phosphate.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.phosphate < blood_units.phosphate.low && (
                      <div>
                        <p>Low blood phosphate levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("phosphate_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.phosphate > blood_units.phosphate.high && (
                      <div>
                        <p>High blood phosphate levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("phosphate_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.phosphate >= blood_units.phosphate.low &&
                      user.phosphate <= blood_units.phosphate.high && (
                        <p>Normal blood phosphate levels</p>
                      )}
                  </td>
                </tr>
              )}
              {user.chloride !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Chloride level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.chloride} {blood_units.chloride.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.chloride < blood_units.chloride.low && (
                      <div>
                        <p>Low blood chloride levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("chloride_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.chloride > blood_units.chloride.high && (
                      <div>
                        <p>High blood chloride levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("chloride_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.chloride >= blood_units.chloride.low &&
                      user.chloride <= blood_units.chloride.high && (
                        <p>Normal blood chloride levels</p>
                      )}
                  </td>
                </tr>
              )}
              {user.parathyroid_hormone !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Parathyroid hormone level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.parathyroid_hormone}{" "}
                    {blood_units.parathyroid_hormone.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.parathyroid_hormone <=
                      blood_units.parathyroid_hormone.high && (
                      <p>Normal parathyroid hormone levels</p>
                    )}
                    {user.parathyroid_hormone >
                      blood_units.parathyroid_hormone.high && (
                      <div>
                        <p>High parathyroid hormone levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("parathyroid_hormone");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              )}{" "}
              {user.calcitriol !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Calcitriol hormone level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.calcitriol} {blood_units.calcitriol.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.calcitriol <= blood_units.calcitriol.high && (
                      <p>Normal calcitriol levels</p>
                    )}
                    {user.calcitriol > blood_units.calcitriol.high && (
                      <div>
                        <p>High calcitriol levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("calcitriol");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.blood_bicarbonate && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Bicarbonate level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_bicarbonate}{" "}
                    {blood_units.blood_bicarbonate.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.blood_bicarbonate <
                      blood_units.blood_bicarbonate.low && (
                      <div>
                        <p>Low blood bicarbonate levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_bicarbonate_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_bicarbonate >
                      blood_units.blood_bicarbonate.high && (
                      <div>
                        <p>High blood bicarbonate levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("blood_bicarbonate_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.blood_bicarbonate <=
                      blood_units.blood_bicarbonate.high &&
                      user.blood_bicarbonate >=
                        blood_units.blood_bicarbonate.low && (
                        <p>Normal blood bicarbonate levels</p>
                      )}
                  </td>
                </tr>
              )}
              {user.triglycerides !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Triglycerides level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.triglycerides} {blood_units.triglycerides.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.triglycerides <= blood_units.triglycerides.high  && (
                      <p>Normal triglycerides levels</p>
                    )}
                    {user.triglycerides > blood_units.triglycerides.high && (
                      <div>
                        <p>High blood triglycerides levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("triglycerides");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.cholesterol != "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Blood Cholesterol level
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.cholesterol} {blood_units.cholesterol.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.cholesterol <= blood_units.cholesterol.high && (
                      <p>Normal blood cholesterol levels</p>
                    )}
                    {user.cholesterol > blood_units.cholesterol.high && (
                      <div>
                        <p>High blood cholesterol levels</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("cholesterol");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Data
        popup={popup}
        setPopup={setPopup}
        value={value}
        user={user}
        handleChange={props.handleChange}
      />
    </section>
  );
}

export default ResultBlood;
