const Contact = () => {
  return (
    <div style={{height: "100vh"}} className="mx-10 mt-10">
      <h2 className="text-6xl mb-10">GET IN TOUCH</h2> 
      <p className="xl">For questions do not hesitate to contact the
      website's team at{" "}
      <a
        href="mailto:globalstoneclinic@gmail.com"
        style={{ color: "green", font: "bold" }}
      >
        globalstoneclinic@gmail.com
      </a></p>
    </div>
  );
};

export default Contact;
