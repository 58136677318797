import React, { useState } from "react";

// EMPTY LIST MAKES SURE THAT THE ITEM ISNT IN THE LIST JUST IN CASE
let CustomPopup = ({
  onpopclose,
  onNextCallback,
  onPrevCallback,
  content,
  emptyList,
}) => {
  let [popup, setPopup] = useState(false);
  let [i, seti] = useState(0);
  return (
    <div>
      <button
        className="bg-gray-500 text-white p-2 rounded-md text-xs"
        onClick={() => {
          setPopup(true);
        }}
      >
        Click here
      </button>
      <div>
        {popup === true && (
          <div className="pr-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
            <div
              className="mx-auto relative px-12 py-6 w-auto md:w-auto bg-white rounded-2xl text-viodeep h-52 overflow-y-auto h-auto "
              style={{ maxHeight: "80%", maxWidth: "80%" }}
            >
              <button
                onClick={() => {
                  if (onpopclose) onpopclose();

                  seti(0);
                  setPopup(false);
                }}
                className="absolute top-4 right-10 font-extrabold my-1 "
              >
                X
              </button>
              <div className="mt-10">{content[i]}</div>
              {/*/.map((item, index) => {
                return (
                  <div key={index} className="flex mt-6 items-center">
                    <input
                      type="radio"
                      id={item.name}
                      name={item.name}
                      value={item.patientAD}
                      onChange={handleChange}
                      className="mr-4"
                    />
                    <div>
                      <label htmlFor={item.name} className="font-semibold">
                        {item.popup}
                      </label>
                    </div>
                  </div> */}
              {/* ); */}
              {/* })} */}

              <div className="flex justify-center mt-6">
                <button
                  onClick={() => {
                    if (i == 0) {
                      if (onpopclose) onpopclose();

                      setPopup(false);
                    } else {
                      if (emptyList && emptyList.indexOf(i - 1) > -1)
                        seti(i - 2);
                      else seti(--i);
                    }
                    if (onPrevCallback != undefined) onPrevCallback(i, seti);
                  }}
                  className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
                >
                  {i == 0 ? "Close" : "Back"}
                </button>

                <button
                  onClick={() => {
                    if (i < content.length - 1) {
                      if (emptyList && emptyList.indexOf(i + 1) > -1)
                        seti(i + 2);
                      else seti(++i);

                      console.log(onNextCallback == undefined);
                    } else {
                      setPopup(false);
                      seti(0);
                      if (onpopclose != undefined) onpopclose();
                    }
                    if (onNextCallback != undefined) onNextCallback(i, seti);
                  }}
                  className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold ml-10"
                >
                  {i < content.length - 1 ? "Next" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomPopup;
