import React from "react";
import Data from "../popup/data";
import ResultStoneData from "./resultStoneData";
import ResultUrineData from "./resultUrineData";
import { blood_units, urine_units } from "./units";

function ResultUrine(props) {
  const { user, handleChange, urinedata, setuc } = props;
  const [popup, setPopup] = React.useState(false);
  const [value, setValue] = React.useState("");
  console.log(user.weight / 10);
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-2 mx-auto">
        <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
          Urine Results
        </div>
        <div className="w-full mx-auto overflow-auto mt-5">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                  Result Type
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Report Value
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Doctor{"'"}s comment
                </th>
              </tr>
            </thead>
            <tbody>
              {user.urine_creatininura !== "" && (
                <tr>
                  <td className="font-bold px-4 py-3">Creatininura</td>
                  <td className="px-4 py-3">
                    {user.urine_creatininura}{" "}
                    {urine_units.urine_creatininura.unit}
                  </td>
                  <td className="px-4 py-3">
                    {user.urine_creatininura <=
                      urine_units.urine_creatininura.low(user.weight) && (
                      <div>
                        <p>
                          Probably incomplete urine collection {"<"}24 hours-
                          verify and repeat if doubt
                        </p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_creatininura_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.urine_creatininura >=
                      urine_units.urine_creatininura.high(user.weight) && (
                      <div>
                        <p>
                          Probably over-due urine collection {">"}24 hours-
                          verify and repeat if doubt
                        </p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_creatininura_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.urine_creatininura >
                      urine_units.urine_creatininura.low(user.weight) &&
                      user.urine_creatininura <
                        urine_units.urine_creatininura.high(user.weight) && (
                        <div>
                          <p>Normal urine collection</p>
                          {/* <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_creatininura_normal");
                          }}
                        >
                          Click here
                        </button> */}
                        </div>
                      )}
                  </td>
                </tr>
              )}
              {user.last_urine_volume != "" && (
                <tr>
                  <td className="font-bold border-t-2 border-gray-200 px-4 py-3">
                    Urine Volume
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.last_urine_volume}{" "}
                    {urine_units.last_urine_volume.unit}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.last_urine_volume <
                      urine_units.last_urine_volume.high && (
                      <div>
                        <p>
                          Increase fluid intake to acquire {">"}1900ml urine/day
                        </p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("last_urine_volume_down");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.last_urine_volume >=
                      urine_units.last_urine_volume.high && (
                      <div>
                        <p>
                          Keep sufficient fluid intake to acquire {">"}1900ml
                          urine/day
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.urine_calcium !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-gray-200 px-4 py-3">
                    Urine Calcium Level
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.urine_calcium} {urine_units.urine_calcium.unit}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.urine_calcium <= urine_units.urine_calcium.high && (
                      <div>
                        <p>Normal urine calcium levels</p>
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("urine_calcium_normal");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                    {user.urine_calcium > urine_units.urine_calcium.high && (
                      <div>
                        <p>
                          Attention to Hypercalciuria: rule out dietary sources
                          for increased urine calcium
                        </p>
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="hyper_calc_w_normocalcemia"
                        />
                        <br></br>
                      </div>
                      // <div>

                      //   <button
                      //     className="bg-gray-500 text-white p-2 rounded-md text-xs"
                      //     onClick={() => {
                      //       setPopup(true);
                      //       setValue("HyperCalcuria_alone");
                      //     }}
                      //   >
                      //     Click here
                      //   </button>
                      // </div>
                    )}
                    {user.urine_calcium > urine_units.urine_calcium.high &&
                      user.urine_sodium > urine_units.urine_sodium.high && (
                        <div>
                          {" "}
                          <p>
                            HyperCalciuria AND Hypernatruria <br />
                            Attention to Hypercalciuria: result of high urine
                            natrium and high salt intake.
                          </p>
                          <ResultUrineData
                            setuc={setuc}
                            urinedata={urinedata}
                            type="hyper_calc_w_hypernatruria"
                          />
                          <br></br>
                        </div>
                      )}
                    {user.urine_calcium > urine_units.urine_calcium.high &&
                      user.urine_urea_levels >
                        urine_units.urine_urea_levels.high && (
                        <div>
                          <p>
                            Attention to Hypercalciuria: <br /> Probably the
                            result of high protein intake and increased acid
                            load in the body
                          </p>
                          <ResultUrineData
                            setuc={setuc}
                            urinedata={urinedata}
                            type="hyper_calc_w_high_urine_urea"
                          />
                          <br></br>
                        </div>
                        // <div>
                        //   <p>
                        //     HyperCalciuria AND High urea in urine <br />
                        //     Attention to Hypercalciuria: result of high urea and
                        //     high protein intake and resultant high acid load.
                        //   </p>
                        //   <button
                        //     className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        //     onClick={() => {
                        //       setPopup(true);
                        //       setValue("HyperCalciuria_High_urine_urea");
                        //     }}
                        //   >
                        //     Click here
                        //   </button>
                        // </div>
                      )}
                    {user.urine_calcium > urine_units.urine_calcium.high &&
                      user.blood_calcium > blood_units.blood_calcium.high && (
                        <div>
                          <p>
                            Attention: Hypercalciuria and Hypercalcemia: Check
                            for Hyperparathyroidism (resorptive hypercalciuria)
                            : rule out adenoma and refer to endocrinologic
                            evaluation if Parathyroid elevated. <br />
                            Remember the Alternative Diagnoses for elevated
                            blood calcium (press on link)
                          </p>
                          <ResultUrineData
                            setuc={setuc}
                            urinedata={urinedata}
                            type="hyper_calcuria_w_high_hypercalcemia"
                          />
                        </div>
                        // <p>
                        //   Attention: Hypercalciuria and Hypercalcemia: Check for
                        //   Hyperparathyroidism (resorptive hypercalciuria) : rule
                        //   out adenoma and refer to endocrinologic evaluation if
                        //   Parathyroid elevated. <br />
                        //   Remember the Alternative Diagnoses for elevated blood
                        //   calcium (press on link)
                        // </p>
                        //   <button
                        //     className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        //     onClick={() => {
                        //       setPopup(true);
                        //       setValue("HyperCalciuria_Hypercalcemia");
                        //     }}
                        //   >
                        //     Click here
                        //   </button>
                        // </div>
                      )}
                  </td>
                </tr>
              )}
              {user.urine_sodium !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Urine Sodium (Na)
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_sodium} {urine_units.urine_sodium.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_sodium <= urine_units.urine_sodium.high && (
                      <div>
                        <p>
                          Salt intake not exceeded ({"<"}3g/day) according to
                          urine Na
                        </p>
                        {/* <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_sodium_down");
                          }}
                        >
                          Click here
                        </button> */}
                      </div>
                    )}
                    {user.urine_sodium > urine_units.urine_sodium.high && (
                      <div>
                        <p>
                          Attention: Possible High Salt intake ({">"}7gr)
                          according to urine Na. High Na content in urine causes
                          hyper-calciuria and increases risk for stone
                          formation.
                        </p>
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="hypernaturia"
                        />
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("urine_sodium_up");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.urine_oxalate !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Urine Oxalate
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_oxalate} {urine_units.urine_oxalate.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_oxalate <= urine_units.urine_oxalate.high && (
                      <div>
                        <p>Normal urine oxalate levels</p>
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("urine_oxalate_down");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                    {user.urine_oxalate > urine_units.urine_oxalate.high && (
                      <div>
                        <p>
                          Attention: Hyperoxaluria- risk for calcium oxalate
                          stone formation. Verify sources for high oxalate
                          content in urine
                        </p>
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="hyperoxaluria"
                        />
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.urine_urea_levels !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Urine Urea Levels
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_urea_levels} {" "}
                    {urine_units.urine_urea_levels.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_urea_levels <=
                      urine_units.urine_urea_levels.high && (
                      <div>
                        <p>
                          Normal urea levels Protein intake not exceeded ({"<"}
                          1.1gr/kg/day) according to urine Urea levels
                        </p>
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("urine_urea_levels_down");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                    {user.urine_urea_levels >
                      urine_units.urine_urea_levels.high && (
                      <div>
                        <p>
                          Attention: Possible High Protein intake ({">"}{" "}
                          1.1gr/kg/day) according to urine Urea levels.
                          <br /> High protein consumption increases acid load in
                          body and causes hyper-calciuria thus increasing risk
                          for stone formation.
                        </p>
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="urea"
                        />

                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("urine_urea_levels_up");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.urine_uric_acid.toString().length > 0 && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Urine Uric acid Levels
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_uric_acid} {urine_units.urine_uric_acid.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.urine_uric_acid >
                      urine_units.urine_uric_acid.high && (
                      <div>
                        <p>
                          Attention: High urine uric acid levels: Increased risk
                          for uric acid stones - especially if low urine pH.{" "}
                          <br />
                          Check urine pH and check dietary intake responsible
                          for high urine uric acid: <br />- Diet rich with
                          purines (source of uric acid) like red meat- seafood -
                          Excessive Carbohydrate consumption <br />
                          Instruct patient to avoid intake of foods that are
                          high in purine content like animal meat(red meat) and
                          lentils, or foods with high fructose content
                          <br />
                        </p>
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="uric"
                        />
                        {/* <button
                      className="bg-gray-500 text-white p-2 rounded-md text-xs"
                      onClick={() => {
                        setPopup(true);
                        setValue("urine_uric_acid_up");
                      }}
                    >
                      Click here
                    </button> */}
                      </div>
                    )}
                    {user.urine_uric_acid <=
                      urine_units.urine_uric_acid.high && (
                      <div>
                        <p>Normal urine uric acid levels</p>
                        {/* <button
                      className="bg-gray-500 text-white p-2 rounded-md text-xs"
                      onClick={() => {
                        setPopup(true);
                        setValue("urine_uric_acid_down");
                      }}
                    >
                      Click here
                    </button> */}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.citraturia !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Urine Citrate
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.citraturia} {urine_units.citraturia.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.citraturia > urine_units.citraturia.high && (
                      <div>
                        <p>Normal urine citrate levels</p>
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("citraturia_up");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                    {user.citraturia <= urine_units.citraturia.high && (
                      <div>
                        <p>
                          Attention: low urinary citrate: rule out causes for
                          metabolic acidosis or possible renal tubular acidosis
                          If needed refer to further evaluation with
                          nephrologist
                        </p>
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="citrate"
                        />
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("citraturia_down");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.cystinuria !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Urine Cystine Levels
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.cystinuria} {urine_units.cystinuria.unit}
                  </td>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    {user.cystinuria > urine_units.cystinuria.high && (
                      <div>
                        <p>
                          Attention: High urine cystine levels: Increased risk
                          for Cystine stones {"-"} especially if low urine pH
                          Check urine pH and check dietary intake responsible
                          for high urine Cystine
                        </p>{" "}
                        <ResultUrineData
                          setuc={setuc}
                          urinedata={urinedata}
                          type="cystine"
                        />
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("cystinuria_up");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                    {user.cystinuria <= urine_units.cystinuria.high && (
                      <div>
                        <p>Normal urine cystine levels</p>
                        {/* <button
                        className="bg-gray-500 text-white p-2 rounded-md text-xs"
                        onClick={() => {
                          setPopup(true);
                          setValue("cystinuria_down");
                        }}
                      >
                        Click here
                      </button> */}
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Data
        popup={popup}
        setPopup={setPopup}
        value={value}
        user={user}
        handleChange={props.handleChange}
      />
    </section>
  );
}

export default ResultUrine;
