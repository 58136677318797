const PakTest = () => {
  return (
    <div className="m-10">
      <div className="ml-50 w-full">
        <img src="http://www.uro-stone.com/uploads/1/3/0/1/130115436/annotation-2020-03-29-184222_orig.png"></img>{" "}
        <p>
          Charles Pak&nbsp;<em>&nbsp;et al. &nbsp;</em>
          <strong>
            A Simple Test
            <br />
            for the Diagnosis of Absorptive,
            <br /> Resorptive and Renal Hypercalciuria <br />
          </strong>{" "}
          NEJM 1975
        </p>
        <p>
          <br />
        </p>
      </div>
      <p>
        <em>
          <u>
            <strong>Advanced Evaluation</strong>
          </u>
          <br />
          <strong>What is the Pak (Calcium Load) test and why do it?</strong>
        </em>
      </p>
      <p>
        <em>
          The <strong>Calcium Load (Pak) Test&nbsp;</strong>is a series of tests
          named after the renowned researcher Charles Pak.
        </em>
      </p>
      <p>
        <em>
          In cases of HyperCalciuria without HyperCalcemia, we need to
          differentiate between Absorptive, Renal Calcium Leak and Resorptive{" "}
          <strong>(1</strong>
        </em>
        <strong>
          <strong>&deg; HyperPTh</strong>
        </strong>
        <strong>)</strong>
        <em>
          &nbsp;Causes of Hypercalciuria&nbsp;
          <br />
          <strong>[</strong>1
        </em>
        <strong>&deg; HyperPTh</strong>
        <em>
          &nbsp;doesn&apos;t always give HyperCalciuria &amp; HyperCalcemia-&gt;
          in which case it is probably obvious the cause is <strong>1</strong>
        </em>
        <strong>
          <strong>
            &deg; HyperPTh<em>]&nbsp;</em>
          </strong>
        </strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <em>
          This differentiation between the causes also would lead to different
          treatments:
          <br />
          For Resorptive HyperCa-uria (<strong>1</strong>
        </em>
        <strong>
          <strong>&deg; HyperPTh</strong>
        </strong>
        <em>
          )&ndash;Treatment is directed towards the PTH gland pathology
          <br />
          For&nbsp;Renal Calcium Leak and absorptive&ndash;Treatment might be
          with Calcium + Thiazides diuretics)
          <br />
          <br />
          Nonetheless, the evaluation must not neglect other possible causes for
          Hypercalciuria and HyperCalcemia ! In any case the evaluation for{" "}
          <strong>1</strong>
        </em>
        <strong>
          <strong>&deg; HyperPTh</strong>
        </strong>
        <em>
          &nbsp;is normal - it is imperative
          <br /> to rule out one of the options in the{" "}
          <u>
            <strong>
              <a href="http://www.uro-stone.com/hypercalcemiahypercalciuria-differential-diagnosis.html">
                Differential Diagnosis section
              </a>
            </strong>
          </u>
          - especially Oncologic related causes- which can be life threatening.
        </em>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>
          <em>
            The Alternative to performing this evaluation, is to refer the
            patient to a Nephrologist.
          </em>
        </strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <u>
          <strong>How the test is performed</strong>
        </u>
      </p>
      <p>
        1) Before making the test- make sure there is no Vitamin D deficiency,
        the patient is on normal salt, protein and oxalate diet.
      </p>
      <p>{"2) Patient is put on 3 days of low calcium diet (&lt;400mg/day)"}</p>
      <p>{"3) Fasting for 12 hours before test"}</p>
      <p>
        {"4) Calciuria, calcemia and PTH test in the morning after fasting"}
      </p>
      <p>{"5) 1 gram Calcium oral dose"}</p>
      <p>{"6) After 1.5 Hrs- Check Urine, Blood Calcium and PTH"}</p>
      <p>
        <br />
      </p>
      <p>
        <u>
          <strong>Interpretation of the Pak Test</strong>
        </u>
        &nbsp;
      </p>
      <p>
        In this test Patients with&nbsp;
        <u>
          <strong>absorptive hypercalciuria</strong>
        </u>{" "}
        normalize their urinary calcium excretion while on a fasting diet, but
        greatly increase their urinary calcium excretion after the calcium load.
      </p>
      <p>
        Therefore, their fasting calcium/creatinine ratio is 0.11 or less, but
        their post&ndash;calcium load samples are greater than 0.20,
        demonstrating an exaggerated calcium absorption and subsequent
        excretion.
      </p>
      <p>
        &nbsp;<em>&nbsp;</em>
      </p>
      <p>
        <em>
          <strong>
            <u>
              Renal leak and resorptive hypercalciuria (HyperParaTHyroidism)
            </u>
          </strong>
        </em>
        &nbsp;
      </p>
      <p>
        Patients with either renal leak or resorptive hypercalciuria are
        hypercalciuric regardless of oral calcium intake. Consequently, they
        show more than 200 mg of urinary calcium excretion per 24 hours on the
        calcium-restricted diet and demonstrate high calcium/creatinine ratios
        in both phases of the calcium-loading test.
      </p>
      <p>
        <em>
          * However,The serum calcium level, &nbsp;can be used to differentiate
          between these 2 diagnoses. Patients with&nbsp;
          <strong>renal leak hypercalciuria</strong> have low serum calcium
          levels, whereas those with&nbsp;
          <strong>resorptive hypercalciuria</strong>, which occurs in patients
          with hyperparathyroidism, are hypercalcemic.
          <br />* Also, the PTH level can be used to differentiate between these
          2 diagnoses. Patients with <strong>
            renal leak hypercalciuria
          </strong>{" "}
          have normalized/low PTH levels after Calcium load, whereas those with{" "}
          <strong>resorptive hypercalciuria (Primary</strong>
          <strong>&nbsp;hyperparathyroidism)</strong>, would have high PTH
          levels.&nbsp;As mentioned before , the importance of this test is to
          unmask these cases of <strong>Primary</strong>
          <strong>&nbsp;hyperparathyroidism</strong> which occur in some
          patients with Hypercalciuria but normal serum calcium levels.
        </em>
      </p>
      <p>
        <br />
      </p>
      <p>
        <em>
          * Hypercalciuria is defined as the excretion of more than 200 mg of
          urinary calcium per 24 hours on the 400-mg calcium-restricted diet.
        </em>
      </p>
      <p>
        <em>
          *&nbsp;<u>For more Advanced Work Up:</u> Patients with absorptive
          hypercalciuria type I typically do not normalize their urinary calcium
          excretion to less than 200 mg per 24 hours on the 400-mg calcium
          restricted diet, whereas patients with type 2 absorptive
          hypercalciuria do demonstrate less than 200 mg of urinary calcium per
          day while on that same diet.
        </em>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default PakTest;
