import React from "react";
import { Chart } from "react-google-charts";
// import PatientDetails from "../components/form/patientDetails";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { blood_units } from "../components/form/units";

function DataGraph({ user }) {
  //--------------------------------

  let seriesColor = [];
  const pointRender = (args) => {
    console.log(bloodData[args.point.index].dataColor);

    seriesColor.push(bloodData[args.point.index].dataColor);
    args.fill = seriesColor[args.point.index];
  };

  //--------------------------
  const bloodData = [];

  // const bloodData = [
  //   ["Topic", "Normal", "High", "Low"],
  //   user.blood_creatinine < 110
  //     ? ["blood creatinine", 0, 0, user.blood_creatinine]
  //     : ["blood creatinine", user.blood_creatinine, 0, 0],
  //   user.blood_calcium > 2.6
  //     ? ["blood calcium", 0, user.blood_calcium, 0]
  //     : ["blood calcium", user.blood_calcium, 0, 0],
  //   user.ionized_calcium > 1.3
  //     ? ["ionized calcium", 0, user.ionized_calcium, 0]
  //     : ["ionized calcium", user.ionized_calcium, 0, 0],
  //   user.fasting_glucose > 100
  //     ? ["fasting_glucose", 0, user.fasting_glucose, 0]
  //     : ["fasting_glucose", user.fasting_glucose, 0, 0],
  //   user.blood_uric_acid > 510
  //     ? ["uric acid", 0, user.blood_uric_acid, 0]
  //     : ["uric acid", user.blood_uric_acid, 0, 0],
  //   user.blood_na < 135
  //     ? ["blood na", 0, 0, user.blood_na]
  //     : user.blood_na > 145
  //     ? ["blood na", 0, user.blood_na, 0]
  //     : ["blood na", user.blood_na, 0, 0],
  //   user.blood_potassium < 3.5
  //     ? ["blood potassium", 0, 0, user.blood_potassium]
  //     : user.blood_potassium > 5
  //     ? ["blood potassium", 0, user.blood_potassium, 0]
  //     : ["blood potassium", user.blood_potassium, 0, 0],
  //   user.magnesium < 0.85
  //     ? ["magnesium", 0, 0, user.magnesium]
  //     : user.magnesium > 1.1
  //     ? ["magnesium", 0, user.magnesium, 0]
  //     : ["magnesium", user.magnesium, 0, 0],
  //   user.phosphate < 0.8
  //     ? ["phosphate", 0, 0, user.phosphate]
  //     : user.phosphate > 1.5
  //     ? ["phosphate", 0, user.phosphate, 0]
  //     : ["phosphate", user.phosphate, 0, 0],
  //   user.chloride < 96
  //     ? ["chloride", 0, 0, user.chloride]
  //     : user.chloride > 106
  //     ? ["chloride", 0, user.chloride, 0]
  //     : ["chloride", user.chloride, 0, 0],
  //   user.parathyroid_hormone < 85
  //     ? ["parathyroid hormone", user.parathyroid_hormone, 0, 0]
  //     : ["parathyroid hormone", 0, user.parathyroid_hormone, 0],
  //   user.calcitriol < 100
  //     ? ["calcitriol", user.calcitriol, 0, 0]
  //     : ["calcitriol", 0, user.calcitriol, 0],
  //   user.blood_bicarbonate < 23
  //     ? ["blood bicarbonate", 0, 0, user.blood_bicarbonate]
  //     : user.blood_bicarbonate > 30
  //     ? ["blood bicarbonate", 0, user.blood_bicarbonate, 0]
  //     : ["blood bicarbonate", user.blood_bicarbonate, 0, 0],
  //   user.triglycerides < 200
  //     ? ["triglycerides", user.triglycerides, 0, 0]
  //     : ["triglycerides", 0, user.triglycerides, 0],
  //   user.cholesterol < 240
  //     ? ["cholesterol", user.cholesterol, 0, 0]
  //     : ["cholesterol", 0, user.cholesterol, 0],
  // ];

  // const bloodOptions = {
  //   chart: {
  //     vAxis: {textPosition:'none' },
  //     hAxis: { textPosition: 'none', title: '  ',  titleTextStyle: {color: 'white'} , slantedTextAngle:90 },
  //      legend:  { position: "none" },
  //     title: "Blood Result Graph",
  //     subtitle: "Check your blood levels respectively",
  //   },
  // };

  const limits = {
    blood_creatinine: {
      name: "Blood Creatinine",
      low: blood_units.blood_creatinine.low,
      high: blood_units.blood_creatinine.high,
    },
    blood_calcium: {
      name: "Blood Calcium",
      low: blood_units.blood_calcium.low,
      high: blood_units.blood_calcium.high,
    
    },
    ionized_calcium: {
      name: "Ionized Calcium",
      low: blood_units.ionized_calcium.low,
      high: blood_units.ionized_calcium.high,
    
    },
    fasting_glucose: {
      name: "Fasting Glucose",
      low: blood_units.fasting_glucose.low,
      high: blood_units.fasting_glucose.high,
    
    },
    blood_uric_acid: {
      name: "Blood Uric Acid",
      low: blood_units.blood_uric_acid.low,
      high: blood_units.blood_uric_acid.high,
     },
    blood_na: {
      name: "Blood Sodium",
      low: blood_units.blood_na.low,
      high: blood_units.blood_na.high,
     },
    blood_potassium: {
      name: "Blood Potassium",
      low: blood_units.blood_potassium.low,
      high: blood_units.blood_potassium.high,
     },
    magnesium: {
      name: "magnesium",
      low: blood_units.magnesium.low,
      high: blood_units.magnesium.high,
     },
    phosphate: {
      name: "Phosphate",
      low: blood_units.phosphate.low,
      high: blood_units.phosphate.high,
     },
    chloride: {
      name: "Chloride",
      low: blood_units.chloride.low,
      high: blood_units.chloride.high,
    },
    parathyroid_hormone: {
      name: "Parathyroid Hormone",
      low: blood_units.parathyroid_hormone.low,
      high: blood_units.parathyroid_hormone.high,
      },
    calcitriol: {
      name: "Calcitriol",
      low: blood_units.calcitriol.low,
      high: blood_units.calcitriol.high,
     },
    blood_bicarbonate: {
      name: "Blood Bicarbonate",
      low: blood_units.blood_bicarbonate.low,
      high: blood_units.blood_bicarbonate.high,
     },
    triglycerides: {
      name: "Triglycerides",
      low: blood_units.triglycerides.low,
      high: blood_units.triglycerides.high,
     },
    cholesterol: {
      name: "Cholesterol",
      low: blood_units.cholesterol.low,
      high: blood_units.cholesterol.high,
     },
  };

  for (const [key, val] of Object.entries(limits)) {
    if (user[key] !== "" && user[key] !== undefined && user[key] !== NaN) {
      let color = "";

      if (user[key] > val.high) {
        color = "red";
      } else if (user[key] >= val.low && user[key] <= val.high) {
        color = "#5ca9f2";
      } else {
        color = "yellow";
      }

      if (user[key] > 10) {
        let tempval = 0.0;
        tempval = user[key];
        while (tempval > 10) {
          tempval = tempval / 10;
        }
        bloodData.push({
          dataType: val.name,
          dataValue: tempval,
          dataColor: color,
        });
      } else
        bloodData.push({
          dataType: val.name,
          dataValue: user[key],
          dataColor: color,
        });
    }
  }

  let seriesColorUrine = [];

  // const UrineData = [
  //   ["Topic", "Normal", "High", "Low"],
  //   user.urine_creatininura < 150
  //     ? ["creatininura", 0, 0, user.urine_creatininura]
  //     : user.urine_creatininura > 200
  //     ? ["creatininura", 0, user.urine_creatininura, 0]
  //     : ["creatininura", user.urine_creatininura, 0, 0],
  //   user.last_urine_volume < 1900
  //     ? ["urine volume", 0, 0, user.last_urine_volume]
  //     : ["urine volume", user.last_urine_volume, 0, 0],
  //   user.urine_calcium < 7
  //     ? ["calcium", user.urine_calcium, 0, 0]
  //     : ["calcium", 0, user.urine_calcium, 0],
  //   user.urine_sodium < 130
  //     ? ["na", 0, 0, user.urine_sodium]
  //     : ["na", 0, user.urine_sodium, 0],
  //   user.urine_oxalate < 0.5
  //     ? ["oxalate", user.urine_oxalate, 0, 0]
  //     : ["oxalate", 0, user.urine_oxalate, 0],
  //   user.urine_urea_levels < 5.5
  //     ? ["urea levels", user.urine_urea_levels, 0, 0]
  //     : ["urea levels", 0, user.urine_urea_levels, 0],
  //   user.urine_uric_acid > 5
  //     ? ["uric acid", 0, user.urine_uric_acid, 0]
  //     : ["uric acid", user.urine_uric_acid, 0, 0],
  //   user.citraturia > 1.5
  //     ? ["citraturia", user.citraturia, 0, 0]
  //     : ["citraturia", 0, 0, user.citraturia],
  //   user.cystinuria > 60
  //     ? ["cystinuria", 0, user.cystinuria, 0]
  //     : ["cystinuria", user.cystinuria, 0, 0],
  // ];

  // const UrineOptions = {
  //   chart: {
  //     vAxis: {textPosition:'none' } ,
  //     hAxis: { textPosition: 'none', title: '  ',  titleTextStyle: {color: 'white'}  ,      slantedText:true,
  //     slantedTextAngle:45  },
  //     legend:  { position: "none" },
  //     title: "Urine Result Graph",
  //     subtitle: "Check your Urine levels respectively",
  //     isStacked: true,

  //   },
  // };
  return (
    <div className="md:px-6 my-8 ">
      {/* <PatientDetails user={user} /> */}
      {/* <div className="w-full rounded-lg shadow-lg p-8 bg-white bg-opacity-75 mt-4">
        <Chart
          
          chartType="Bar"
          width="100%"
          height="400px"
          options={bloodOptions}
          data={bloodData}
          loader={<div>Loading Blood Data...</div>}
          
        />
      </div> */}

      {/* <div className="w-full rounded-lg shadow-lg p-8 bg-white bg-opacity-75 mt-4">
        <Chart 
          
          
          chartType="Bar"
          width="100%"
          height="400px"
          options={UrineOptions}
          data={UrineData}
          loader={<div>Loading Urine Data...</div>}
        />
      </div> */}

      <div class="sm:my-0">
        <ChartComponent
          title="blood data"
          width="640"
          id="charts"
          pointRender={pointRender}
          style={{ overflow: "auto" }}
          primaryXAxis={{
            valueType: "Category",
            labelIntersectAction: "Rotate90",
            labelPosition: "Outside",
          }}
          primaryYAxis={{
            labelStyle: { size: "0px" },
          }}
          legendSettings={{ visible: true, shapeHeight: 6, shapeWidth: 8 }}
        >
          <Inject
            services={[
              ColumnSeries,
              Legend,
              Tooltip,
              DataLabel,
              LineSeries,
              Category,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={bloodData}
              xName="dataType"
              type="Column"
              yName="dataValue"
              name="High"
              legendShape="Circle"
              fill="red"
            />
            <SeriesDirective name="Low" legendShape="Circle" fill="#F4B400" />
            <SeriesDirective
              name="Normal"
              legendShape="Circle"
              fill="#5ca9f2"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {/* <div>
      <ChartComponent title="Urine Graph"     pointRender = {pointRenderUrine} primaryXAxis={{valueType : "Category",  labelIntersectAction: 'Rotate45' , labelPosition : 'Outside'  }} style={{ width : '100%' ,  height : '100%'}}  legendSettings = {{ visible: true, shapeHeight: 12, shapeWidth: 12 }} >
      <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, LineSeries, Category]}/>
      <SeriesCollectionDirective>
        <SeriesDirective dataSource={UrineData} xName='dataType' type='Column' yName="dataValue" name = 'High' legendShape='Circle' fill = "red"  />
        <SeriesDirective  name = 'Low' legendShape='Circle' fill="#F4B400"   />
        <SeriesDirective  name = 'Normal' legendShape='Circle' fill="#5ca9f2"   />
      </SeriesCollectionDirective>
    </ChartComponent>
      </div> */}
    </div>
  );
}

export default DataGraph;
