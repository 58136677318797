import axios from "axios";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingGif from "./LoadingGif";
import App from "./App";
import AdminDashBoard from "./components/admin/Dashboard";
import { fetchUser } from "./api_calls/CallList";
import { setUser } from "./store/actions/UserAction";
import ProtectedRoute from './ProtectedRoute'
// axios.defaults.baseURL = "localhost:8080";

export default function GSC() {
  const [isUserFetchedFromServer, setisUserFetchedFromServer] = useState(false);
  const dispatch = useDispatch();
  

  useEffect((e) => {
    const auth = getAuth();

    auth.onAuthStateChanged((e) => {
      if (auth.currentUser) {
        fetchUser()
          .then((data) => {
            dispatch(setUser(data));
            setisUserFetchedFromServer(true);

          })
          .catch((e) => {
            setisUserFetchedFromServer(true);

            auth.signOut();
 
          });
          
      } else {
        setisUserFetchedFromServer(true);
      }
    });
  }, []);
  return isUserFetchedFromServer ? (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />}></Route>
        <Route path="dashboard/*" element={<AdminDashBoard />}  />
      </Routes>{" "}
    </BrowserRouter>
  ) : (
    <LoadingGif />
  );
}
