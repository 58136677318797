import React from "react";
import { Chart } from "react-google-charts";
// import PatientDetails from "../components/form/patientDetails";

import {
  Category,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { urine_units } from "../components/form/units";

function DataGraphUrine({ user }) {
  const UrineData = [];

  const limits = {
    urine_creatininura: {
      name: "Urine Creatiniura",
      low: urine_units.urine_creatininura.low(user.weight),
      high: urine_units.urine_creatininura.high(user.weight) + 0.1,
    },
    last_urine_volume: {
      name: "Last Urine Volume",
      low: 0,
      high: 2900,
    },
    urine_calcium: {
      name: "Urine Calcium",
      low: urine_units.urine_calcium.low,
      high: urine_units.urine_calcium.high,
    },
    urine_sodium: {
      name: "Urine Sodium",
      low: urine_units.urine_sodium.low,
      high: urine_units.urine_sodium.high,
    },
    urine_oxalate: {
      name: "Urine Oxalate",
      low: urine_units.urine_oxalate.low,
      high: urine_units.urine_oxalate.high,
    },
    urine_urea_levels: {
      name: "Urine Urea Levels",
      low: urine_units.urine_urea_levels.low,
      high: urine_units.urine_urea_levels.high,
    },
    urine_uric_acid: {
      name: "Urine Uric Acid",
      low: urine_units.urine_uric_acid.low,
      high: urine_units.urine_uric_acid.high,
    },
    citraturia: {
      name: "Urine Citraturia",
      low: urine_units.citraturia.low,
      high: urine_units.citraturia.high,
    },
    cystinuria: {
      name: "Urine Cystinuria",
      low: urine_units.cystinuria.low,
      high: urine_units.cystinuria.high,
    },
  };

  for (const [key, val] of Object.entries(limits)) {
    if (user[key] !== "" && user[key] !== undefined && user[key] !== NaN) {
      let color = "";
      if (key == "last_urine_volume") {
        if (user[key] < 1900) {
          color = "red";
        } else if (user[key] > 2000) {
          color = "blue";
        } else {
          color = "yellow";
        }

        continue;
      }

      if (user[key] > val.high) {
        color = "red";
      } else if (
        (user[key] >= val.low && user[key] <= val.high) ||
        (user[key] == val.high && val.low == val.high)
      ) {
        color = "#5ca9f2";
      } else {
        color = "yellow";
      }
      String.prototype.replaceAt = function (index, replacement) {
        return (
          this.substring(0, index) +
          replacement +
          this.substring(index + replacement.length)
        );
      };
      let l = key.replace("_", " ");
      l = l.replaceAt(0, String.prototype.toUpperCase(key[0]));
      l = l.replaceAt(
        key.indexOf(" ") + 1,
        key[key.indexOf(" ") + 1].toUpperCase()
      );

      if (user[key] > 10) {
        let tempval = 0.0;
        tempval = user[key];
        while (tempval > 10) {
          tempval = tempval / 10;
        }
        UrineData.push({
          dataType: val.name,
          dataValue: tempval,
          dataColor: color,
        });
      } else
        UrineData.push({
          dataType: val.name,
          dataValue: user[key],
          dataColor: color,
        });
    }
  }

  let seriesColorUrine = [];
  const pointRenderUrine = (args) => {
    seriesColorUrine.push(UrineData[args.point.index].dataColor);
    args.fill = seriesColorUrine[args.point.index];
  };

  // const UrineData = [
  //   ["Topic", "Normal", "High", "Low"],
  //   user.urine_creatininura < 150
  //     ? ["creatininura", 0, 0, user.urine_creatininura]
  //     : user.urine_creatininura > 200
  //     ? ["creatininura", 0, user.urine_creatininura, 0]
  //     : ["creatininura", user.urine_creatininura, 0, 0],
  //   user.last_urine_volume < 1900
  //     ? ["urine volume", 0, 0, user.last_urine_volume]
  //     : ["urine volume", user.last_urine_volume, 0, 0],
  //   user.urine_calcium < 7
  //     ? ["calcium", user.urine_calcium, 0, 0]
  //     : ["calcium", 0, user.urine_calcium, 0],
  //   user.urine_sodium < 130
  //     ? ["na", 0, 0, user.urine_sodium]
  //     : ["na", 0, user.urine_sodium, 0],
  //   user.urine_oxalate < 0.5
  //     ? ["oxalate", user.urine_oxalate, 0, 0]
  //     : ["oxalate", 0, user.urine_oxalate, 0],
  //   user.urine_urea_levels < 5.5
  //     ? ["urea levels", user.urine_urea_levels, 0, 0]
  //     : ["urea levels", 0, user.urine_urea_levels, 0],
  //   user.urine_uric_acid > 5
  //     ? ["uric acid", 0, user.urine_uric_acid, 0]
  //     : ["uric acid", user.urine_uric_acid, 0, 0],
  //   user.citraturia > 1.5
  //     ? ["citraturia", user.citraturia, 0, 0]
  //     : ["citraturia", 0, 0, user.citraturia],
  //   user.cystinuria > 60
  //     ? ["cystinuria", 0, user.cystinuria, 0]
  //     : ["cystinuria", user.cystinuria, 0, 0],
  // ];

  // const UrineOptions = {
  //   chart: {
  //     vAxis: {textPosition:'none' } ,
  //     hAxis: { textPosition: 'none', title: '  ',  titleTextStyle: {color: 'white'}  ,      slantedText:true,
  //     slantedTextAngle:45  },
  //     legend:  { position: "none" },
  //     title: "Urine Result Graph",
  //     subtitle: "Check your Urine levels respectively",
  //     isStacked: true,

  //   },
  // };
  return (
    <div className="md:px-6 my-8 ">
      {/* <PatientDetails user={user} /> */}
      {/* <div className="w-full rounded-lg shadow-lg p-8 bg-white bg-opacity-75 mt-4">
        <Chart
          
          chartType="Bar"
          width="100%"
          height="400px"
          options={bloodOptions}
          data={bloodData}
          loader={<div>Loading Blood Data...</div>}
          
        />
      </div> */}

      {/* <div className="w-full rounded-lg shadow-lg p-8 bg-white bg-opacity-75 mt-4">
        <Chart 
          
          
          chartType="Bar"
          width="100%"
          height="400px"
          options={UrineOptions}
          data={UrineData}
          loader={<div>Loading Urine Data...</div>}
        />
      </div> */}

      <div class="lg:-mb-20 sm:my-0">
        <ChartComponent
          title="Urine Graph"
          pointRender={pointRenderUrine}
          style={{ overflow: "auto" }}
          primaryXAxis={{
            valueType: "Category",
            labelIntersectAction: "Rotate90",
            labelPosition: "Outside",
          }}
          primaryYAxis={{
            labelStyle: { size: "0px" },
          }}
          legendSettings={{ visible: true, shapeHeight: 12, shapeWidth: 12 }}
        >
          <Inject
            services={[
              ColumnSeries,
              Legend,
              Tooltip,
              DataLabel,
              LineSeries,
              Category,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={UrineData}
              xName="dataType"
              type="Column"
              yName="dataValue"
              name="High"
              legendShape="Circle"
              fill="red"
            />
            <SeriesDirective name="Low" legendShape="Circle" fill="#F4B400" />
            <SeriesDirective
              name="Normal"
              legendShape="Circle"
              fill="#5ca9f2"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
}

export default DataGraphUrine;
