import React from "react";

function CardRight(props) {
  const { content, title, img, button, buttonTwo, subtitle } = props;
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
      {img &&  <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={img}
          />
        </div>}
        <div className="lg:flex-grow sm:w-1/2   md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font text-xl lg:text-4xl mb-4 font-bold text-gray-900">
            {title}
          </h1>
          {subtitle && <p className="font-semibold text-lg mb-3">{subtitle}</p>}
          <p className="mb-8 leading-relaxed">{content}</p>
          <div className="flex w-full md:justify-start justify-center items-end">
            <button
            
            onClick={props.onButton1Click}
            className="inline-flex text-gray-900 font-semibold hover:text-white border-gray-900 border-2 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded md:text-xs lg:text-lg">
              {button}
            
            </button>
            {buttonTwo === "" ? (
              <p></p>
            ) : (
              <button 
              onClick={props.onButton2Click}

              className="inline-flex ml-5 text-gray-900 font-semibold hover:text-white border-gray-900 border-2 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded md:text-xs lg:text-lg">
                {buttonTwo}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardRight;
