import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ResultStoneData from "../form/resultStoneData";

import ResultUrineData from "../form/resultUrineData";
function Home() {
  const [popup, setPopup] = React.useState(false);
  let val = false;
  const [checked, setchecked] = React.useState(false);
  let nav = useNavigate();
  return (
    <>
      {popup === true && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
          <div
            className="mx-auto relative px-12 py-6 sm:w-screen lg:w-1/2 bg-white rounded-2xl text-viodeep h-52 overflow-y-auto h-auto "
            style={{ maxHeight: "80%", maxWidth: "90%" }}
          >
            <p className="font-bold text-justify text-gray-900 md:text-lg mb-8">
              <p className="text-center">WAIVER AND RELEASE OF LIABILITY</p>
              <br></br>
              This website is to be used as an advisory tool and only as such.
              All final medical decisions regarding management of the patient's
              disease and treatment must be taken by the treating physician. The
              website does not hold any responsibility regarding the actions
              taken by the users of the website.
            </p>

            <div className="flex justify-center items-center">
              <div 
            onClick={() => {

            }}
              className="flex justify-center items-center pt-1  text-gray-900 font-semibold border-gray-900 border-2 mr-5 focus:outline-none  rounded text-lg">
                <Checkbox
                  value={checked}
                  //defaultValue={checked}
                  onChange={(e) => {
                    setchecked(!checked);

                  }}
                ></Checkbox>
                <p className="mr-5 font-semibold md:text-lg ">I AGREE</p>
              </div>

              <button
                type="button"
                className="inline-flex  text-gray-900 font-semibold hover:text-white border-gray-900 border-2 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded text-lg"
                onClick={() => {
                  if (checked) nav("/form");
                  else setPopup(false);
                }}
              >
                {!checked ? "CLOSE" : "CONTINUE TO WEBSITE"}
              </button>
            </div>

            <div className="flex justify-center mt-6"></div>
          </div>
        </div>
      )}
      <div className="lg:w-full mx-auto">
        <div className="flex flex-wrap w-full lg:h-screen bg-gray-900 py-32 px-10 relative z-0 mb-4">
          <img
            alt="gallery"
            className="w-full object-cover h-full lg:h-screen object-center opacity-90 block absolute inset-0"
            src="./assets/home/main.jpg"
          />
          <div className="text-center relative z-10 w-full lg:pt-28">
            <h2 className=" text-lg md:text-2xl lg:text-5xl text-white font-bold title-font mb-2 drop-shadow-lg">
              URINARY STONES <br />
              METABOLIC WORKUP
            </h2>
            <button
              onClick={() => {
                setPopup(true);
              }}
              //to="/terms"
              className="mt-6 w-30 text-white px-6 py-4 font-bold inline-flex bg-gray-900 items-center hover:bg-logoBlue hover:px-30 duration-150"
            >
              <p className="pr-3">START NOW</p>
              {"    "}
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
            <br></br>
            <Link
              to="/user-guide"
              className="mt-6 text-white px-6 py-4 font-bold inline-flex bg-gray-900 items-center hover:w-6 hover:bg-logoBlue"
            >
              USER'S GUIDE{" "}
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2 hover:w-6"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
        {/* <div className="bg-white mx-auto text-center">
    <p>Hyper Calcuria With High HyperCalcemia</p>
    <ResultUrineData
      type={"hyper_calcuria_w_high_hypercalcemia"}
      urinedata={urinedata}
      setuc={setuc}

    />

    <p>hyper_calc_w_high_urine_urea</p>
    <ResultUrineData
      type={"hyper_calc_w_high_urine_urea"}
      urinedata={urinedata}
      setuc={setuc}
    />

    <p>hyper_calc_w_normocalcemia</p>
    <ResultUrineData
      type={"hyper_calc_w_normocalcemia"}
      urinedata={urinedata}
      setuc={setuc}
    />

    <p>hyper_calc_w_hypernatruria</p>
    <ResultUrineData
      type={"hyper_calc_w_hypernatruria"}
      urinedata={urinedata}
      setuc={setuc}
    />
    <p>urea</p>
    <ResultUrineData type={"urea"} urinedata={urinedata} setuc={setuc} />
    <p>citrate</p>
    <ResultUrineData type={"citrate"} urinedata={urinedata} setuc={setuc} />
    <p>cystine</p>

    <ResultUrineData type={"cystine"} urinedata={urinedata} setuc={setuc} />
    <p>uric</p>

    <ResultUrineData type={"uric"} urinedata={urinedata} setuc={setuc} />
   <div>
    <p> STONE </p>

    <p>calcium_oxalate_monohydrate</p>
    <ResultStoneData
      type={"calcium_oxalate_monohydrate"}
      urinedata={urinedata}
      setuc={setuc}
    />
    <p>xcarbaptite</p>

    <ResultStoneData
      type={"xcarbaptite"}
      urinedata={urinedata}
      setuc={setuc}
    />
    <p>brushite</p>

    <ResultStoneData
      type={"brushite"}
      urinedata={urinedata}
      setuc={setuc}
    />
    <p>struvite</p>

    <ResultStoneData
      type={"struvite"}
      urinedata={urinedata}
      setuc={setuc}
    />

    <p>uric</p>

    <ResultStoneData type={"uric"} urinedata={urinedata} setuc={setuc} />
    <p>cystine</p>

    <ResultStoneData type={"cystine"} urinedata={urinedata} setuc={setuc} />
  </div>
</div> */}
      </div>
    </>
  );
}

export default Home;
