import React from "react";

function Popup(props) {
  return (
    <div>
      {props.popup === true && (
        <div className="pr-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
          <div
            className="mx-auto relative px-12 py-6 w-auto md:w-auto bg-white rounded-2xl text-viodeep overflow-y-auto h-auto"
            style={{ maxHeight: "80%", maxWidth: "80%" }}
          >
            <button
              onClick={() => {
                props.setPopup(false);
              }}
              className="absolute top-4 right-10 font-extrabold"
            >
              X
            </button>
            { props.title !== undefined &&  props.title }

            {props.content.map((item, index) => {
              console.log(item.name);
              console.log(item.patientAD)
              return (
                <div key={index} className="flex mt-6 items-center">
                  <input
                    type="radio"
                    id={item.name}
                    checked={item.patientAD == props.user[item.name]}
                    name={item.name}
                    value={item.patientAD}
                    onChange={props.handleChange}
                  className="mr-4"
                  />
                  <div>
                    <label htmlFor={item.name} className="font-semibold">
                      {item.popup}
                    </label>
                  </div>
                </div>
              );
            })}
            <div className="flex justify-center mt-6">
              <button
                onClick={() => {
                  props.setPopup(false);
                }}
                className=" text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;

