import { Button, Collapse, IconButton } from "@material-ui/core";
import { ActionTypes } from "@mui/base";
import { Alert } from "@mui/material";
import { create } from "@mui/material/styles/createTransitions";
import { height } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  createCenter,
  createUser,
  fetchCenters,
  getAllCenters,
} from "../../../api_calls/CallList";
import LoadingGif from "../../../LoadingGif";
import Input from "../../Input/input";

export default function NewUserForm() {
  const [selectable, setselectable] = useState(null);
  const navigate = useNavigate();
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [ip, setip] = useState({
    name: null,
    gender: null,
    email: null,
    password: null,
    confirm_pass: null,
    center: null,
  });

  const [alert, setAlert] = useState({
    display: false,
    msg: null,
    severe: "success",
  });
  const createNewUser = async () => {
    setbuttonDisabled(true);
    for (const entry of Object.entries(ip)) {
      if (!entry[1] || entry[1].toString().length < 1) {
        setAlert({
          display: true,
          severe: "error",
          msg: `Please enter a valid value for ${entry[0]}`,
        });
        return;
      }
      if (ip.password.length < 6) {
        setAlert({
          display: true,
          severe: "error",
          msg: `Make sure that the length of the password is greater than 5 characters`,
        });
        return;
      }
      if (ip.password !== ip.confirm_pass) {
        setAlert({
          display: true,
          severe: "error",
          msg: `Passwords do not match`,
        });
        return;
      }
      if (!ip.email.includes("@")) {
        setAlert({
          display: true,
          severe: "error",
          msg: `Please enter a valid email address`,
        });
        return;
      }
    }
    createUser(ip)
      .then((res) => {
        setAlert({ display: true, msg: "Success", severe: "success" });
        setbuttonDisabled(false);
      })
      .catch((error) => {
        setAlert({ display: true, msg: error.toString(), severe: "error" });
        setbuttonDisabled(false);
      });
  };

  useEffect((e) => {
    getAllCenters()
      .then((data) => {
        const finalData = [];
        data.forEach((e) => {
          finalData.push({
            label: e.countryId + " - " + e.stateId + " - " + e.centerUnique,
            value: e.id,
          });
        });
        setselectable(finalData);
      })
      .catch((e) => navigate("/"));
  }, []);
  const items = [{ text: "Select the Center", type: "center" }];

  return (
    <div>
      <Collapse in={alert.display}>
        <Alert
          severity={alert.severe}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ display: false });
              }}
            >
              Close
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alert.msg}
        </Alert>
      </Collapse>

      {selectable ? (
        <div className="p-10 mt-30 flex flex-col w-full items-center justify-center mb-10">
          <div className="mt-10 w-full text-center font-bold text-3xl pb-5 align-middle justify-center">
            CREATE USER
          </div>

          <div className="rounded-2xl shadow items-center align-middle p-10 justify-center flex-col flex border-2">
            <div>
              <Input
                isRequired={true}
                Name={"name"}
                OnChange={(e) => setip({ ...ip, name: e.target.value })}
                htmlFor={"name"}
                TextVal={"Name of the User"}
                Placeholder={"Enter the name"}
              />
              <label htmlFor="sex" className="leading-7 text-sm text-gray-600">
                Gender <span className="text-red-500">*</span>
              </label>
              <br />
              <select
                defaultValue={""}
                onChange={(e) => setip({ ...ip, gender: e.target.value })}
                name="sex"
                className="bg-gray-100 w-full rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value="" disabled hidden>
                  Select your gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
              <div className="mt-4"></div>
              <Input
                Type={"email"}
                isRequired={true}
                Name={"email"}
                OnChange={(e) => setip({ ...ip, email: e.target.value })}
                htmlFor={"email"}
                TextVal={"Email of the User"}
                Placeholder={"Enter the Email"}
              />
              <Input
                Type={"password"}
                isRequired={true}
                Name={"password"}
                OnChange={(e) => setip({ ...ip, password: e.target.value })}
                htmlFor={"password"}
                TextVal={"Create a Password"}
                Placeholder={"Enter a password"}
              />{" "}
              <Input
                Type={"password"}
                isRequired={true}
                Name={"confirm_pass"}
                OnChange={(e) => setip({ ...ip, confirm_pass: e.target.value })}
                htmlFor={"confirm_pass"}
                TextVal={"Re-enter the password"}
                Placeholder={"Re-enter the pass"}
              />
              {items.map((e) => (
                <>
                  {" "}
                  <p className="text-sm">{e.text}</p>{" "}
                  <Select
                    isClearable={true}
                    options={selectable}
                    onChange={(input, action) => {
                      if (action.action === "select-option") {
                        setip({ ...ip, center: input.value });

                        //   setInput({ ...Input, [e.type]: ip.label })
                      }
                    }}
                  />
                  <br />
                </>
              ))}
              <button
                disabled={buttonDisabled}
                onClick={createNewUser}
                className="disabled:bg-red-500 w-full text-white bg-indigo-500 border-0 py-2 focus:outline-none hover:bg-indigo-600 rounded mb-10 text-lg"
              >
                Create User
              </button>
            </div>
          </div>
        </div>
      ) : (
        <LoadingGif />
      )}
    </div>
  );
}
