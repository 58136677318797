import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

const TermsandCond = () => {
  return (
    <div>
      <Navbar />
      <div className="px-12 md:px-32 py-24">
        <p className="font-bold text-gray-900 md:text-lg mb-8">
          WAIVER AND RELEASE OF LIABILITY: THIS WEBSITE IS TO BE USED AS AN
          ADVISORY TOOL AND ONLY AS SUCH. ALL FINAL MEDICAL DECISIONS REGARDING
          MANAGEMENT OF THE PATIENT'S DISEASE AND TREATMENT MUST BE TAKEN BY THE
          TREATING PHYSICIAN. THE WEBSITE DOES NOT HOLD ANY RESPONSIBILITY
          REGARDING THE ACTIONS TAKEN BY THE USERS OF THE WEBSITE.
        </p>
        <Link
          to="/form"
          className="inline-flex text-gray-900 font-semibold hover:text-white border-gray-900 border-2 py-2 px-6 focus:outline-none hover:bg-gray-900 rounded text-lg"
        >
          AGREE AND CONTINUE TO WEBSITE
        </Link>
      </div>
      
      <Footer />
    </div>
  );
};

export default TermsandCond;
