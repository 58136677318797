const blood_units = {
  blood_creatinine: {
    high: 1.2,
    low: 0.7,
    unit: "mg/dL",
  },
  blood_calcium: {
    high: 10,
    low: 8.6,
    unit: "mg/dL",
  },
  ionized_calcium: {
    high: 1.32,
    low: 1.16,
    unit: "mmol/L",
  },
  fasting_glucose: {
    high: 110,
    low: 70,
    unit: "mg/dL",
  },
  blood_uric_acid: {
    high: 7.2,
    low: 3.5,
    unit: "mg/dL",
  },
  blood_na: {
    high: 145,
    low: 136,
    unit: "mmol/L",
  },
  blood_potassium: {
    high: 5.1,
    low: 3.5,
    unit: "mmol/L",
  },

  magnesium: {
    high: 2.6,
    low: 1.6,
    unit: "mEq/L",
  },
  phosphate: {
    high: 4.5,
    low: 2.7,
    unit: "Mg/dl",
  },
  chloride: {
    unit: "mmol/L",
    high: 98,
    low: 107,
  },
  parathyroid_hormone: {
    unit: "pg/ml",
    high: 65,
    low: 15,
  },
  calcitriol: {
    unit: "ng/ml",
    high: 100,
    low: 20,
  },
  blood_bicarbonate: {
    unit: "mmol/L",
    high: 29,
    low: 22,
  },
  triglycerides: {
    unit: "mg/dl",
    high: 150,
    low: 0,
  },
  cholesterol: {
    high: 200,
    low: 1,
    unit: "mg/dl",
  },
};

const urine_units = {
  urine_creatininura: {
    unit: "mg/24hrs",
    low: (w) => w * 15,
    high: (w) => w * 20,
  },
  last_urine_volume: {
    low: 800,
    high: 1900,
    unit: "ml/24hrs",
  },
  urine_calcium: {
    low: 100,
    high: 300,
    unit: "mg/24hrs",
  },
  urine_sodium: {
    low: 40,
    high: 150,
    unit: "mEq/24hrs",
  },
  urine_oxalate: {
    low: 0,
    high: 40,
    unit: "mg/24hrs",
  },
  urine_urea_levels: {
    low: 12,
    high: 20,
    unit: "g/24hrs",
  },
  urine_uric_acid: {
    low: 250,
    high: 750,
    unit: "mg/24hrs",
  },
  citraturia: {
    low: 0,
    high: 300,
    unit: "mg",
  },
  cystinuria: {
    low: 24,
    high: 184,
    unit: "μmol/24hrs",
  },
};

module.exports = { blood_units, urine_units };
