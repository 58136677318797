import Input from "../Input/input";

const UrineSurvey = ({ user, handleChange }) => {
  console.log(user.glucosuria);
  return (
    //
    <>
      <div className="container px-5 py-4 mx-auto flex sm:flex-nowrap flex-wrap items-center">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <h2 className="text-gray-900 text-xl mb-2 font-semibold title-font">
            Morning Spot Urine
          </h2>
          <Input
            isRequired={true}
            htmlFor={"urine_ph"}
            TextVal={"Urine pH (6-6.4)"}
            Type={"number"}
            Id={"urine_ph"}
            Name={"urine_ph"}
            Value={user.urine_ph}
            OnChange={handleChange}
            Required={true}
          />

          <Input
            htmlFor={"morning_urine_specific_gravity"}
            TextVal={"Morning Urine Specific Gravity (< 1.020)"}
            Type={"number"}
            Id={"morning_urine_specific_gravity"}
            Name={"morning_urine_specific_gravity"}
            Value={user.morning_urine_specific_gravity}
            OnChange={handleChange}
            Required={true}
          />

          <Input
            htmlFor={"urine_crystals"}
            TextVal={"Urine Crystals (Preferably morning fresh urine)"}
            Type={"text"}
            Id={"urine_crystals"}
            Name={"urine_crystals"}
            Value={user.urine_crystals}
            OnChange={handleChange}
            Required={true}
          />
          <div className="relative mb-8">
            <label
              htmlFor="urine_culture"
              className="leading-7 text-sm text-gray-600"
            >
              Urine Culture (Negative)
              {/* <span className="text-red-500">*</span> */}
            </label>
            <select
              name="urine_culture"
              value={user.urine_culture}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="">None</option>

              <option value="-1">Negative</option>
              <option value="0">Positive</option>
            </select>
          </div>

          {user.urine_culture == "0" && (
            <Input
              isRequired={true}
              htmlFor={"urine_culture_bacteria"}
              TextVal={"Enter the name of the bacteria for urine culture"}
              Type={"text"}
              Id={"urine_culture_bacteria"}
              Name={"urine_culture_bacteria"}
              Value={user.urine_culture_bacteria}
              OnChange={handleChange}
              Required={true}
            />
          )}
          <div className="relative mb-8">
            <label
              htmlFor="urine_culture"
              className="leading-7 text-sm text-gray-600"
            >
              Glucosuria (Negative)
            </label>
            <select
              name="glucosuria"
              value={user.glucosuria}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="">None</option>

              <option value="-1">Negative</option>
              <option value="0">Positive</option>
            </select>
          </div>
        </div>
        <div className="md:w-1/3 bg-gray-300 rounded-lg p-3 flex items-center justify-center relative">
          <div className="bg-white relative py-6 rounded shadow-md px-6">
            <div className="">
              <h2 className="text-justify text-gray-700 text-xs">
                <ul className="list-disc">
                <li className="py-1">
                    Optimally, this analysis is performed separately, on fresh
                    urine, immediately after collection.
                  </li>
                  <li className="py-1">
                    The Urine Crystal analysis under a microscope may identify
                    active stone formation
                  </li>
                </ul>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UrineSurvey;
