import { getAuth } from "firebase/auth";
import { Navigate, Route, useLocation } from "react-router-dom";
const ProtectedRoute = ({ component: Component, ...restofProps }) => {
  const location = useLocation();
  if(location.pathname[0] == '/') location.pathname = location.pathname.substring(1);
  return (
   
        getAuth().currentUser ? (
          Component
        ) : (
          <Navigate state={{}} to={"/login?previous=" + location.pathname} />
        )
      
  );
};

export default ProtectedRoute;
