import { Checkbox, Input } from "@material-ui/core";
import React from "react";

function StoneSelector(props) {
  return (
    <div
      className="w-40 h-40 bg-black-100 relative flex justify-center items-center"
      id={props.text}
      // onClick={props.onClick}
    >
      <Checkbox
        type={"checkbox"}
        onClick={props.onClick}
        id={props.text}
      />

      <img
        id={props.text}
        className="opacity-100 object- w-40 h-40"
        src={props.image}
      ></img>
      <div
        id={props.text}
        className=" duration-300 absolute inset-0 z-3 flex justify-center content-end items-end mb-2 text-center text-white font-semibold"
      >
        <p
          // onClick={props.onClick}
          id={props.text}
          className="ml-9 w-full hover:bg-black"
        >
          {props.text}
        </p>
      </div>
    </div>
  );
}

export default StoneSelector;
