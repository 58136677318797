import React from "react";
import Input from "../Input/input";
import Grid from "@material-ui/core/Grid";
import Item from "@material-ui/core/ListItem";

import Multiselect from "multiselect-react-dropdown";
import StoneSelector from "../stone_selector";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import DietrySurvey from "./DietrySurvey";
import UrineSurvey from "./UrineSurvey";
import { comment } from "postcss";
import { blood_units, urine_units } from "./units";
import './form.css'
import { useEffect } from "react";
import { getAuth } from "firebase/auth";
// import global_user from "./global_data";
// import store from "../home/global_state";
const abdominalOptions = {
  options: [
    { name: "URS", id: 1 },
    { name: "PCNL", id: 2 },
    { name: "ESWL", id: 3 },
    { name: "GI Surgery", id: 4 },
    { name: "ESWL", id: 5 },
    { name: "Nephrectomy/Partial", id: 6 },
  ],
};



const parseNameAndMeaning = (name, meaning) => {
  return (
    <>
      <p>{name}</p> <i>{meaning}</i>
    </>
  );
};
const background_conditions = {
  options: [
    { name: "Hypertension", id: 1, comment: "" },
    {
      name: "Type 2 diabetes mellitus ",
      comment: "- Increased risk mainly for uric acid stones",

      id: 2,
    },
    {
      name: "Gout",
      comment: " - Increased risk mainly for uric acid stones",
      id: 3,
    },
    {
      name: "Obesity",
      comment: "- Increased risk mainly for uric acid stones",
      id: 4,
    },
    { name: "Hyperlipidemia", id: 5, comment: "" },
    { name: "Coronary artery disease", id: 6, comment: "" },
    {
      name: "Chronic kidney disease",
      comment: "and end-stage kidney disease",
      id: 7,
    },
    {
      name: "Inflammatory Bowel disease",
      comment:
        "- and intestinal surgery- If terminal ileal disease or resection then Risk for increased urine oxalate levels",
      id: 8,
    },
    {
      name: "Renal and bladder anatomic anomalies",
      comment: "- Risk for stone formation due to urinary stasis",
      id: 9,
    },
    {
      name: "HyperParathyroidism",
      comment:
        " - Risk for increased urine calcium levels and mainly Brushite stone formation",
      id: 10,
    },
    {
      name: "Bone disorders and fractures",
      comment: "- Risk for increased urine calcium levels",
      id: 11,
    },
    {
      name: "Oncologic condition with bone metastasis",
      comment: "- Risk for increased urine calcium levels",
      id: 12,
    },
    { name: "Aortic calcification", id: 13, comment: "" },
    {
      name: "Sarcoidosis",
      comment: "- Risk for increased urine calcium levels",
      id: 14,
    },
    {
      name: "Metabolic Syndrome",
      comment: "- Increased risk mainly for uric acid stones",
      id: 15,
    },
  ],
};

const geneticAbnormalitiesOptions = {
  options: [
    { name: "Cystinuria", id: 1 },
    { name: "Primary Hyperoxaluria", id: 2 },
    // { name: "Other", id: 3 },
  ],
};

const medicationOptions = {
  options: [
    {
      name: "Vitamin C",
      comment: "- Risk for increased urine oxalate levels",
      id: 1,
    },
    {
      name: "Vitamin D",
      comment: "- Risk for increased urine calcium levels",
      id: 2,
    },
    {
      name: "Indinavir",
      comment: "- Risk for drug precipitation and crystallization in the urine",
      id: 3,
    },
    {
      name: "Thiazide",
      comment: "- Risk for increased urine calcium levels",
      id: 4,
    },
    {
      name: "Furosemide",
      comment: "- Risk for dehydration",
      id: 5,
    },
    {
      name: "Chronic antibiotic use",
      comment:
        " - Increased risk for calcium oxalate stones due to eradication of oxalobacter\n formigenes in the intestines and resulting increased oxalate resorption",
      id: 6,
    },
    //  { name: "Others", id: 7 },
  ],
};

// onSelect(selectedList, selectedItem) {

// }

// onRemove(selectedList, removedItem) {

// }
function Form(props) {
  const [user, setUser, handleChange] = [
    props.user,
    props.setUser,
    props.handleChange,
  ];


  const navigate = useNavigate();
  useEffect(() => {
    if (!getAuth().currentUser) {
      navigate('/login?previous=form');
    }
  }, [])

  const [id, setId] = React.useState("");
  const [popup, setPopup] = React.useState(false);
  const [content, setContent] = React.useState("");

  const [popOpen, setpopOpen] = React.useState(false);

  const [image, setImage] = React.useState(null);

  const handleAbdominalChange = (selectedList, selectedItem) => {
    user.previous_abdominal = [];
    for (let val = 0; val < selectedList.length; val++) {
      user.previous_abdominal.push(selectedList[val].name);
    }
    user.previous_abdominal = user.previous_abdominal;

  };

  const handleMedicationsChnage = (selectedList, selectedItem) => {
    user.medications = [];
    for (let val = 0; val < selectedList.length; val++) {
      user.medications.push(selectedList[val].name);
    }
    user.medications = user.medications;

  };

  const handleGeneticAbnormalitiesChange = (selectedList, selectedItem) => {
    user.genetic_abnormalities = [];
    for (let val = 0; val < selectedList.length; val++) {
      user.genetic_abnormalities.push(selectedList[val].name);
    }
    user.genetic_abnormalities = user.genetic_abnormalities;

  };
  const handleMetabolicChnage = (selectedList, selectedItem) => {
    user.background_conditions = [];
    for (let val = 0; val < selectedList.length; val++) {
      user.background_conditions.push(selectedList[val].name);
    }
    user.background_conditions = user.background_conditions;

  };

  const PostData = async (e) => {
    //   e.preventDefault();

    const {
      amount_water_intake,
      specify_salt_one,
      salt_intake,

      type_of_stone,
      age,
      sex,
      weight,
      height,
      history_stone_disease,
      allergies,
      previous_abdominal,
      metabolic_sundrome,
      tobacco_use,
      blood_creatinine,
      blood_calcium,
      ionized_calcium,
      fasting_glucose,
      blood_uric_acid,
      blood_na,
      blood_potassium,
      magnesium,
      phosphate,
      chloride,
      parathyroid_hormone,
      calcitriol,
      blood_bicarbonate,
      triglycerides,
      cholesterol,
      urine_creatininura,
      last_urine_volume,
      urine_calcium,
      urine_sodium,
      urine_oxalate,
      urine_urea_levels,
      urine_uric_acid,
      citraturia,
      cystinuria,
      urine_ph,
      morning_urine_specific_gravity,
      urine_crystals,
      urine_culture,
      glucosuria,
      calcium_intake,
      oxalate_intake,
      specify_dietary_one,
      carbohydrate_intake,
      causes_for_hyperUricosuria,
      protein_intake,
      for_cystinuria_patients,
      specify_dietary_two,
      medications,
      genetic_abnormalities,
    } = user;

    // const response = await fetch(
    //   "https://globalstoneclinic.com/api/submitpatientinfo",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       amount_water_intake,

    //       salt_intake,

    //       type_of_stone,
    //       age,
    //       sex,
    //       weight,
    //       height,
    //       history_stone_disease,
    //       allergies,
    //       previous_abdominal,
    //       tobacco_use,
    //       blood_creatinine,
    //       blood_calcium,
    //       ionized_calcium,
    //       fasting_glucose,
    //       blood_uric_acid,
    //       blood_na,
    //       blood_potassium,
    //       magnesium,
    //       phosphate,
    //       chloride,
    //       parathyroid_hormone,
    //       calcitriol,
    //       blood_bicarbonate,
    //       triglycerides,
    //       cholesterol,
    //       urine_creatininura,
    //       last_urine_volume,
    //       urine_calcium,
    //       urine_sodium,
    //       urine_oxalate,
    //       urine_urea_levels,
    //       urine_uric_acid,
    //       citraturia,
    //       cystinuria,
    //       urine_ph,
    //       morning_urine_specific_gravity,
    //       urine_crystals,
    //       urine_culture,
    //       glucosuria,
    //       calcium_intake,
    //       oxalate_intake,
    //       specify_dietary_one,
    //       carbohydrate_intake,
    //       causes_for_hyperUricosuria,
    //       protein_intake,
    //       for_cystinuria_patients,
    //       specify_dietary_two,
    //     }),
    //   }
    // );
    // const body = await response.json();
    // if (response.status === 200) {
    // alert("Registered Successfully");
    console.log(user);

    //navigate('/doctoradvice', {state: {'data': [user, handleChange], 'hello': "HELLO`}})
    //Navigate("/doctoradvice", { state: { user, handleChange } });

    //  setId(body.id);
    //} else {
    // alert("Registration Failed");
    //}
  };

  const onClickStoneHandler = (e) => {
    if (e.target.checked) {
      if (user.type_of_stone.indexOf(e.target.id) === -1) {
        user.type_of_stone.push(e.target.id);

        document.getElementById(e.target.id).checked = false;
      }
    } else {
      user.type_of_stone = user.type_of_stone.filter((b) => b !== e.target.id);
      document.getElementById(e.target.id).checked = true;
    }
  };

  return (
    <section className="text-gray-600 body-font relative md:px-6 lg:px-16">

      <div className="container px-5 pt-12">
        <p className="font-bold text-4xl">METABOLIC WORKUP SECTION</p>
        <div className="border-b-8 border-logoBlue py-6">
          <div className="w-96 container px-5">
            {/* <Link to="/getpatient">
              <button
                onClick={() => { }}
                className="w-full text-white bg-indigo-500 border-0 py-2 focus:outline-none hover:bg-indigo-600 rounded mb-10 text-lg"
              >
                LOGIN TO FETCH PATIENT RECORD(S)
              </button>
            </Link> */}
          </div>
          <p className="font-bold text-3xl">STEP 1</p>
          <p className="text-2xl">DEMOGRAPHIC DATA</p>
        </div>
      </div>
      <form>

      </form>
      <div className="container px-5 py-12 mx-auto flex sm:flex-nowrap flex-wrap items-center">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <h2 className="text-gray-900 text-xl mb-2 font-semibold title-font">
            Patient's General Data
          </h2>
          <Input
            isRequired={true}
            htmlFor={`age`}
            TextVal={`Age`}
            Type={`number`}
            Id={`age`}
            Name={`age`}
            Value={user.age}
            OnChange={handleChange}
          />

          <div className="relative mb-8">
            <label htmlFor="sex" className="leading-7 text-sm text-gray-600">
              Sex
            </label>
            <span className="text-red-500">*</span>
            <select
              name="sex"
              value={user.sex}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select your gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <Input
            isRequired={true}
            htmlFor={`weight`}
            TextVal={`Weight (in kg)`}
            Type={`number`}
            Id={`weight`}
            Name={`weight`}
            Value={user.weight}
            OnChange={(e) => {
              // ADD THE RANGE FACTOR
              handleChange(e);

              console.log(e.target.value);
              user.urine_creatininura = 150 * parseFloat(e.target.value);
              user.urine_urea_levels = 5 * parseFloat(e.target.value);
            
              user.urine_urea_upper = 6 * parseFloat(e.target.value);
              user.urine_creatininura_upper = 200 * parseFloat(e.target.value);
            }}
          />

          <Input
            isRequired={true}
            htmlFor={`height`}
            TextVal={`Height (in cm)`}
            Type={`number`}
            Id={`height`}
            Name={`height`}
            Value={user.height}
            OnChange={handleChange}
          />
        </div>
        <div className="md:w-[40%]  bg-gray-300 rounded-lg p-3 flex items-center justify-center relative">
          <div className="bg-white relative py-6 rounded shadow-md px-6">
            {/* <div className="border-b-2 border-indigo-500 pb-4"> */}
            <h2 className="text-gray-700 text-xs text-justify ">
              <ul className="list-disc">
                <li className="py-1">

                  The metabolic evaluation should be performed in the patient's
                  usual setting, with the usual diet and lifestyle, to best
                  reflect the conditions of stone formation.
                </li>{" "}
                <li className="py-1">

                  {" "}
                  Avoid performing the evaluation immediately during the days
                  following a stone passage / procedure.
                </li>
                <li className="py-1">
                  Instead, waiting for 6 to 8 weeks is recommended.</li>
                <li className="py-1">

                  The evaluation can be performed in the presence of
                  asymptomatic renal stones.
                </li>
              </ul>
            </h2>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="container px-5 py-4 mx-auto flex sm:flex-nowrap flex-wrap items-center">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <h2 className="text-gray-900 text-xl mb-2 font-semibold title-font">
            Clinical Data
          </h2>

          <div className="relative mb-2">
            <Input
              htmlFor={`history_family_stone_disease`}
              TextVal={`Family History of Stone Disease`}
              Type={`text`}
              Id={`history_family_stone_disease`}
              Name={`history_family_stone_disease`}
              Value={user.history_family_stone_disease}
              OnChange={handleChange}
            />
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="history_stone_disease"
              className="leading-7 text-sm text-gray-600"
            >
              Personal History of Stone Disease{` `}
            </label>
            <select

              name="history_stone_disease"
              value={user.history_stone_disease}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>

          {/* Making sure that there's history */}
          {user.history_stone_disease != "" &&
            user.history_stone_disease != "No" && (
              <div className="relative mb-4">
                <label
                  htmlFor={user.type_of_stone}
                  className="leading-7 text-sm text-gray-600"
                >
                  If the stone is of mixed composition, please select the
                  different component types from the list
                </label>
                <button
                  className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  onClick={() => {
                    user.type_of_stone = [];
                    setpopOpen(true);
                  }}
                >
                  {user.type_of_stone.length == 0
                    ? "Click here to select"
                    : user.type_of_stone.join(",  ")}
                </button>
                {popOpen && (
                  <div>
                    {
                      <div className=" px-10 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 z-40">
                        <div
                          className=" mx-auto relative px-12 py-6 w-auto md:w-auto bg-white rounded-2xl text-viodeep h-auto overflow-y-auto"
                          style={{ maxHeight: "90%", maxWidth: "800px" }}
                        >
                          <button
                            onClick={() => {
                              setpopOpen(false);
                            }}
                            className=" absolute top-4 right-10 font-extrabold"
                          >
                            CLOSE
                          </button>
                          <div
                            id="ldiv"
                            className="ml-50% mr-50% mt-10  self-stretch"
                          >
                            <Grid
                              container
                              justifyContent="center"
                              spacing={1}
                              className="overflow-y-auto h-1/2 self-stretch"
                            >
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={
                                      "Calcium Oxalate Monohydrate (Whewellite)"
                                    }
                                    image={`./assets/stones/com.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={
                                      "Calcium Oxalate Dihydrate (Weddellite)"
                                    }
                                    image={`./assets/stones/cod.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={`Uric Acid`}
                                    image={`./assets/stones/uric.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    text={`Brushite`}
                                    onClick={onClickStoneHandler}
                                    image={`./assets/stones/brushite.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={`Carbapatite`}
                                    image={`./assets/stones/carbapatite.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={`Struvite`}
                                    image={`./assets/stones/struvite.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={`Cystine`}
                                    image={`./assets/stones/cystine.png`}
                                  />
                                </Item>
                              </Grid>
                              <Grid item>
                                <Item>
                                  <StoneSelector
                                    tos={user.type_of_stone}
                                    onClick={onClickStoneHandler}
                                    text={`Variable / Others`}
                                    image={`./assets/stones/variable.png`}
                                  />
                                </Item>
                              </Grid>
                            </Grid>
                          </div>
                          <p className="text-right font-bold mt-2">
                            Credit for images goes to Dr Michel Daudon, Paris,
                            France.
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          <Input
            htmlFor={`allergies`}
            TextVal={`Allergies`}
            Type={`text`}
            Id={`allergies`}
            Name={`allergies`}
            Value={user.allergies}
            OnChange={handleChange}
          />

          <div className="relative mb-8">
            <label
              htmlFor="previous_abdominal"
              className="leading-7 text-sm text-gray-600"
            >
              Previous Abdominal / Urologic Procedure{` `}
            </label>
            <div className="bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out">
              {/* todo:: multiselectr for abdominal */}

              <Multiselect
                id="previous_abdominal"
                className="  border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                options={abdominalOptions.options}
                selectedValues={abdominalOptions.selectedValue}
                onSelect={handleAbdominalChange}
                onRemove={handleAbdominalChange}
                displayValue="name"
              />
            </div>
          </div>
          <div className="relative mb-8">
            <label
              htmlFor="tobacco_use"
              className="leading-7 text-sm text-gray-600"
            >
              Tobacco Use
            </label>
            <select
              name="tobacco_use"
              value={user.tobacco_use}
              onChange={handleChange}
              className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="" disabled hidden>
                Select from dropdown
              </option>
              <option value="No">No</option>
              <option value="Less than 10 Pack-Years  - Smoking cessation strongly encouraged if patient still smoking">
                Less than 10 Pack-Years
              </option>
              <option value="Less than 20 Pack-Years  - Smoking cessation strongly encouraged if patient still smoking">
                Less than 20 Pack-Years
              </option>
              <option value="Less than 30 Pack-Years  - Smoking cessation strongly encouraged if patient still smoking">
                Less than 30 Pack-Years
              </option>
            </select>
          </div>

          {/* //matabbolic syndrome----------------------------------------------------- */}

          <div className="relative mb-8">
            <label
              htmlFor="background_conditions"
              className="leading-7 text-sm text-gray-600"
            >
              Background Conditions / Diseases
            </label>
            <div className="bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out">
              {/* todo:: multiselectr for abdominal */}

              <Multiselect
                id="background_conditions" // CHANGE
                className="  border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                options={background_conditions.options}
                selectedValues={background_conditions.selectedValue}
                onSelect={handleMetabolicChnage}
                onRemove={handleMetabolicChnage}
                displayValue="name"
              />
            </div>
          </div>

          {/* //end=-------------------------------------------------------------- */}

          {/* medication-------------------------------------------------------- */}

          <div className="relative mb-8">
            <label
              htmlFor="medications"
              className="leading-7 text-sm text-gray-600"
            >
              Medications
            </label>
            <div className="bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out">
              <Multiselect
                id="medications"
                className="break-words border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                options={medicationOptions.options}
                selectedValues={medicationOptions.selectedValue}
                onSelect={handleMedicationsChnage}
                onRemove={handleMedicationsChnage}
                displayValue="name"
              />
            </div>
          </div>

          <Input
            htmlFor={`medications_others`}
            TextVal={`Other Medications`}
            Type={`text`}
            Id={`medications_others`}
            Name={`medications_others`}
            Value={user.medications_others}
            OnChange={handleChange}
            Placeholder="Enter other medications"
          />
          {/* end-------------------------------------------------------------- */}

          {/* genetic_abnormalities------------------------------------------------- */}

          <div className="relative mb-8">
            <label
              htmlFor="genetic_abnormalities"
              className="leading-7 text-sm text-gray-600"
            >
              Genetic Abnormalities
            </label>
            <div className="bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out">
              <Multiselect
                id="genetic_abnormalities"
                className="  border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out"
                options={geneticAbnormalitiesOptions.options}
                selectedValues={geneticAbnormalitiesOptions.selectedValue}
                onSelect={handleGeneticAbnormalitiesChange}
                onRemove={handleGeneticAbnormalitiesChange}
                displayValue="name"
              />
            </div>
          </div>
          <Input
            htmlFor={`genetic_abnormalities_others`}
            TextVal={`Other Genetic Abnormalities`}
            Type={`text`}
            Id={`genetic_abnormalities_others`}
            Name={`genetic_abnormalities_others`}
            Value={user.genetic_abnormalities_others}
            OnChange={handleChange}
            Placeholder="Enter Other Genetic Abnormalities"
          />

          {/* end-------------------------------------------------------------- */}
        </div>
        <div className="md:w-1/2 bg-gray-300 rounded-lg p-3 flex items-center justify-center relative lg:mt-96">
          <div className="bg-white relative py-6 rounded shadow-md px-6">
            <div className="">
              <h2 className="text-justify text-gray-700 text-xs">
                <p className="font-bold">
                  The metabolic syndrome is defined by
                </p>
                <br />
                <ul className="list-disc">
                  <li className="py-1">
                    Elevated blood pressure (systolic ≥130 mm Hg or diastolic
                    ≥85 mm Hg) or anti hypertensive drug treatment
                  </li>
                  <li className="py-1">
                    Elevated glucose [fasting plasma glucose ≥100 mg/dl (6
                    mmol/l)] or drug treatment for hyperglycemia
                  </li>
                  <li className="py-1">
                    High waist circumference (≥80 cm for women and ≥94 cm for
                    men)
                  </li>
                  <li className="py-1">
                    Elevated triglyceride levels [≥150 mg/dl (1.7 mmol/l)] or
                    drug treatment for elevated triglycerides levels
                  </li>
                  <li className="py-1">
                    Reduced HDL cholesterol [{`<`}40 mg/dl (1.0 mmol/l) for men
                    and {`<`}50 mg/dl (1.3 mmol/l) for women] or drug treatment
                    for reduced HDL-cholesterol level
                  </li>
                </ul>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-5 py-4 mx-auto flex sm:flex-nowrap flex-wrap items-start">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <h2 className="text-gray-900 text-xl mb-2 font-semibold title-font">
            Blood Results
          </h2>
          <Input
            isRequired={true}
            htmlFor={`blood_creatinine`}
            TextVal={`Creatinine (${blood_units.blood_creatinine.unit})`}
            Type={`number`}
            Id={`blood_creatinine`}
            Name={`blood_creatinine`}
            Value={user.blood_creatinine}
            OnChange={handleChange}
            Placeholder="Enter Creatinine"
          />

          <Input
            isRequired={true}
            htmlFor={`blood_calcium`}
            TextVal={`Blood Calcium (${blood_units.blood_calcium.unit})`}
            Type={`number`}
            Id={`blood_calcium`}
            Name={`blood_calcium`}
            Value={user.blood_calcium}
            OnChange={handleChange}
            Placeholder="Enter Calcium"
          />

          <Input
            isRequired={true}
            htmlFor={`ionized_calcium`}
            TextVal={`Ionized Calcium (${blood_units.ionized_calcium.unit})`}
            Type={`number`}
            Id={`ionized_calcium`}
            Name={`ionized_calcium`}
            Value={user.ionized_calcium}
            OnChange={handleChange}
            Placeholder="Enter Ionized Calcium"
          />

          <Input
            isRequired={true}
            htmlFor={`fasting_glucose`}
            TextVal={`Fasting Glucose (${blood_units.fasting_glucose.unit})`}
            Type={`number`}
            Id={`fasting_glucose`}
            Name={`fasting_glucose`}
            Value={user.fasting_glucose}
            OnChange={handleChange}
            Placeholder="Enter Fasting Glucose"
          />

          <Input
            isRequired={true}
            htmlFor={`blood_uric_acid`}
            TextVal={`Blood Uric Acid (${blood_units.blood_uric_acid.unit})`}
            Type={`number`}
            Id={`blood_uric_acid`}
            Name={`blood_uric_acid`}
            Value={user.blood_uric_acid}
            OnChange={handleChange}
            Placeholder="Enter Blood Uric Acid"
          />

          <Input
            htmlFor={`blood_na`}
            TextVal={`Blood Sodium (Na)  (${blood_units.blood_na.unit})`}
            Type={`number`}
            Id={`blood_na`}
            Name={`blood_na`}
            Value={user.blood_na}
            OnChange={handleChange}
            Placeholder="Enter Blood Sodium Na"
          />

          <Input
            htmlFor={`blood_potassium`}
            TextVal={`Blood Potassium  (${blood_units.blood_potassium.unit})`}
            Type={`number`}
            Id={`blood_potassium`}
            Name={`blood_potassium`}
            Value={user.blood_potassium}
            OnChange={handleChange}
            Placeholder="Enter Blood Potassium"
          />

          <Input
            htmlFor={`magnesium`}
            TextVal={`Magnesium  (${blood_units.magnesium.unit})`}
            Type={`number`}
            Id={`magnesium`}
            Name={`magnesium`}
            Value={user.magnesium}
            OnChange={handleChange}
            Placeholder="Enter Magnesium"
          />

          <Input
            htmlFor={`phosphate`}
            TextVal={`Phosphate (${blood_units.phosphate.unit})`}
            Type={`number`}
            Id={`phosphate`}
            Name={`phosphate`}
            Value={user.phosphate}
            OnChange={handleChange}
            Placeholder="Enter Phosphate"
          />

          <Input
            htmlFor={`chloride`}
            TextVal={`Chloride  (${blood_units.chloride.unit})`}
            Type={`number`}
            Id={`chloride`}
            Name={`chloride`}
            Value={user.chloride}
            OnChange={handleChange}
            Placeholder="Enter Chloride"
          />

          <Input
            htmlFor={`parathyroid_hormone`}
            TextVal={`Parathyroid Hormone  (${blood_units.parathyroid_hormone.unit})`}
            Type={`number`}
            Id={`parathyroid_hormone`}
            Name={`parathyroid_hormone`}
            Value={user.parathyroid_hormone}
            OnChange={handleChange}
            Placeholder="Enter Parathyroid Hormone"
            Required={true}
          />

          <Input
            htmlFor={`calcitriol`}
            TextVal={`Calcitriol (${blood_units.calcitriol.unit})`}
            Type={`number`}
            Id={`calcitriol`}
            Name={`calcitriol`}
            Value={user.calcitriol}
            OnChange={handleChange}
            Placeholder="Enter calcitriol"
            Required={true}
          />

          <Input
            htmlFor={`blood_bicarbonate`}
            TextVal={`Blood Bicarbonate (${blood_units.blood_bicarbonate.unit})`}
            Type={`number`}
            Id={`blood_bicarbonate`}
            Name={`blood_bicarbonate`}
            Value={user.blood_bicarbonate}
            OnChange={handleChange}
            Placeholder="Enter Blood Bicarbonate"
            Required={true}
          />

          <Input
            htmlFor={`triglycerides`}
            TextVal={`Triglycerides (${blood_units.triglycerides.unit})`}
            Type={`number`}
            Id={`triglycerides`}
            Name={`triglycerides`}
            Value={user.triglycerides}
            OnChange={handleChange}
            Placeholder="Enter Triglycerides"
            Required={true}
          />

          <Input
            htmlFor={`cholesterol`}
            TextVal={`cholesterol (${blood_units.cholesterol.unit})`}
            Type={`number`}
            Id={`cholesterol`}
            Name={`cholesterol`}
            Value={user.cholesterol}
            OnChange={handleChange}
            Placeholder="Enter Cholesterol"
            Required={true}
          />
        </div>
      </div>
      {` `}
      <div className="container px-5 py-4 mx-auto flex sm:flex-nowrap flex-wrap items-start">
        <div className="md:w-1/2 bg-white flex flex-col w-full md:mt-0 sm:mr-10">
          <h2 className="text-gray-900 text-xl mb-2 font-semibold title-font">
            Urine Results
          </h2>
          <Input
            isRequired={true}
            htmlFor={`urine_creatininura`}
            TextVal={`Creatininura (${urine_units.urine_creatininura.unit})`}
            Type={`number`}
            Id={`urine_creatininura`}
            Name={`urine_creatininura`}
            Value={user.urine_creatininura}
            OnChange={handleChange}
            Placeholder="Enter your Creatininuria"
            Required={true}
          />

          <Input
            isRequired={true}
            htmlFor={`last_urine_volume`}
            TextVal={`24hr Urine Volume (${urine_units.last_urine_volume.unit})`}
            Type={`number`}
            Id={`last_urine_volume`}
            Name={`last_urine_volume`}
            Value={user.last_urine_volume}
            OnChange={handleChange}
            Placeholder="Enter your 24hr Urine Volume"
            Required={true}
          />

          <Input
            isRequired={true}
            htmlFor={`urine_calcium`}
            TextVal={`Urine Calcium (${urine_units.urine_calcium.unit})`}
            Type={`number`}
            Id={`urine_calcium`}
            Name={`urine_calcium`}
            Value={user.urine_calcium}
            OnChange={handleChange}
            Placeholder="Enter your Urine Calcium"
            Required={true}
          />

          <Input
            isRequired={true}
            htmlFor={`urine_sodium`}
            TextVal={`Urine Sodium (${urine_units.urine_sodium.unit})`}
            Type={`number`}
            Id={`urine_sodium`}
            Name={`urine_sodium`}
            Value={user.urine_sodium}
            OnChange={handleChange}
            Placeholder="Enter your Urine Sodium"
            Required={true}
          />

          <Input
            htmlFor={`urine_oxalate`}
            TextVal={`Urine Oxalate (${urine_units.urine_oxalate.unit})`}
            Type={`number`}
            Id={`urine_oxalate`}
            Name={`urine_oxalate`}
            Value={user.urine_oxalate}
            OnChange={handleChange}
            Placeholder="Enter your Urine Oxalate"
            Required={true}
          />

          <Input
            isRequired={true}
            htmlFor={`urine_urea_levels`}
            TextVal={`Urine Urea Levels (${urine_units.urine_urea_levels.unit})`}
            Type={`number`}
            Id={`urine_urea_levels`}
            Name={`urine_urea_levels`}
            Value={user.urine_urea_levels}
            OnChange={handleChange}
            Placeholder="Enter your Urine Urea Levels"
            Required={true}
          />

          <Input
            isRequired={true}
            htmlFor={`urine_uric_acid`}
            TextVal={`Urine Uric Acid (${urine_units.urine_uric_acid.unit})`}
            Type={`number`}
            Id={`urine_uric_acid`}
            Name={`urine_uric_acid`}
            Value={user.urine_uric_acid}
            OnChange={handleChange}
            Placeholder="Enter your Urine Uric Acid"
            Required={true}
          />

          <Input
            htmlFor={`citraturia`}
            TextVal={`Urine Citrate (${urine_units.citraturia.unit})`}
            Type={`number`}
            Id={`citraturia`}
            Name={`citraturia`}
            Value={user.citraturia}
            OnChange={handleChange}
            Placeholder="Enter your Urine Citrate"
            Required={true}
          />

          <Input
            htmlFor={`cystinuria`}
            TextVal={`Urine Cystine (${urine_units.cystinuria.unit})`}
            Type={`number`}
            Id={`cystinuria`}
            Name={`cystinuria`}
            Value={user.cystinuria}
            OnChange={handleChange}
            Placeholder="Enter your Urine Cystine"
            Required={true}
          />
        </div>
        <div className="md:w-1/3 bg-gray-300 rounded-lg p-3 flex items-center justify-center mt-14 relative">
          <div className="bg-white relative py-6 rounded shadow-md px-14">
            <div className="">
              <h2 className="text-justify text-gray-700 text-xs">
                <ul className="list-disc">
                  <li className="py-1">

                    {" "}
                    Creatininuria is used here as a quality control test for the
                    urine collection.
                  </li>
                </ul>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <UrineSurvey user={user} handleChange={handleChange}></UrineSurvey>
      {/* <DietrySurvey user={user} handleChange={handleChange}></DietrySurvey> */}
      {/* Button Section */}
      <div className="w-full md:w-6/12 container px-5">
        <Link
          to={`/doctoradvice`}
        // params={{ user: user, handleChange: handleChange }}
        // state={{ user: user, handleChange: handleChange }}
        // to="/doctoradvice"
        >
          <button
            className="w-full text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            onClick={PostData}
            disabled={
              // user.name === "" ||
              user.age === "" ||
                user.sex === "" ||
                user.weight === "" ||
                user.height === "" ||
                user.blood_creatinine === "" ||
                user.blood_calcium === "" ||
                user.ionized_calcium === "" ||
                user.fasting_glucose === "" ||
                user.blood_uric_acid === "" ||
                user.urine_creatininura === "" ||
                user.last_urine_volume === "" ||
                user.urine_calcium === "" ||
                user.urine_sodium === "" ||
                user.urine_urea_levels === "" ||
                user.urine_uric_acid === "" ||
                user.urine_ph === ""
                ? // user.previous_abdominal === "" ||
                // user.blood_creatinine === "" ||
                // user.blood_calcium === "" ||
                // user.ionized_calcium === "" ||
                // user.fasting_glucose === "" ||
                // user.blood_uric_acid === "" ||
                // user.blood_na === "" ||
                // user.blood_potassium === "" ||
                // user.magnesium === "" ||
                // user.phosphate === "" ||
                // user.chloride === "" ||
                // user.urine_creatininura === "" ||
                // user.last_urine_volume === "" ||
                // user.urine_calcium === "" ||
                // user.urine_sodium === "" ||
                // user.urine_oxalate === "" ||
                // user.urine_urea_levels === "" ||
                // user.urine_uric_acid === "" ||
                // user.citraturia === "" ||
                // user.cystinuria === "" ||
                // user.urine_ph === "" ||
                // user.morning_urine_specific_gravity === "" ||
                // user.urine_crystals === "" ||
                // user.urine_culture === "" ||
                // user.glucosuria === "" ||
                // user.calcium_intake === "" ||
                // user.oxalate_intake === "" ||
                // user.carbohydrate_intake === "" ||
                // user.causes_for_hyperUricosuria === "" ||
                // user.protein_intake === "" ||
                // user.for_cystinuria_patients === "" ||
                // user.parathyroid_hormone === "" ||
                // user.calcitriol === "" ||
                // user.blood_bicarbonate === "" ||
                // user.triglycerides === "" ||
                // user.cholesterol === ""
                true
                : false
            }
          >
            Input Summary and Workup
          </button>
        </Link>

        {
          //user.name === "" ||
          user.age === "" ||
            user.sex === "" ||
            user.weight === "" ||
            user.height === "" ||
            user.blood_creatinine === "" ||
            user.blood_calcium === "" ||
            user.ionized_calcium === "" ||
            user.fasting_glucose === "" ||
            user.blood_uric_acid === "" ||
            user.urine_creatininura === "" ||
            user.last_urine_volume === "" ||
            user.urine_calcium === "" ||
            user.urine_sodium === "" ||
            user.urine_urea_levels === "" ||
            user.urine_uric_acid === "" ||
            user.urine_ph === "" ? (
            // user.blood_creatinine === "" ||
            // user.blood_calcium === "" ||
            // user.ionized_calcium === "" ||
            // user.fasting_glucose === "" ||
            // user.blood_uric_acid === "" ||
            // user.blood_na === "" ||
            // user.blood_potassium === "" ||
            // user.magnesium === "" ||
            // user.phosphate === "" ||
            // user.chloride === "" ||
            // user.urine_creatininura === "" ||
            // user.last_urine_volume === "" ||
            // user.urine_calcium === "" ||
            // user.urine_sodium === "" ||
            // user.urine_oxalate === "" ||
            // user.urine_urea_levels === "" ||
            // user.urine_uric_acid === "" ||
            // user.citraturia === "" ||
            // user.cystinuria === "" ||
            // user.urine_ph === "" ||
            // user.morning_urine_specific_gravity === "" ||
            // user.urine_crystals === "" ||
            // user.urine_culture === "" ||
            // user.glucosuria === "" ||
            // user.calcium_intake === "" ||
            // user.oxalate_intake === "" ||
            // user.carbohydrate_intake === "" ||
            // user.causes_for_hyperUricosuria === "" ||
            // user.protein_intake === "" ||
            // user.for_cystinuria_patients === "" ||
            // user.parathyroid_hormone === "" ||
            // user.calcitriol === "" ||
            // user.blood_bicarbonate === "" ||
            // user.triglycerides === "" ||
            // user.cholesterol === ""
            <p className="text-xs text-gray-500 mt-3">
              <span className="text-red-600 font-bold">* </span> Some fields are
              mising
            </p>
          ) : (
            <p></p>
          )
        }
      </div>
      {popup && (
        <div className="h-full bg-black bg-opacity-50 flex items-center justify-center absolute left-0 top-0 w-full">
          <div className="p-12 bg-white text-black text-center font-bold rounded-lg">
            {content} <br />
            <br />
            {content === "Registered Successfully" && (
              <button
                onClick={() => {
                  //   setStep(step + 1);
                }}
                className="px-6 py-1 rounded-lg text-white bg-indigo-700"
              >
                {` `}
                OK
              </button>
            )}
          </div>

        </div>

      )}
    </section>
  );
}

export default Form;
