import React from "react";
import Data from "../popup/data";
import ResultStoneData from "./resultStoneData";
import ResultUrineData from "./resultUrineData";
export default function ResultPH(props) {
  const { user, handleChange, urinedata, setuc } = props;
  const [popup, setPopup] = React.useState(false);
  const [value, setValue] = React.useState("");
  console.log(user.glucosuria);
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-2 mx-auto">
        <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
          Urine pH Results
        </div>
        <div className="w-full mx-auto overflow-auto mt-5">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500 rounded-tl rounded-bl">
                  Result Type
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Report Value
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium text-white text-sm bg-gray-500">
                  Doctor{"'"}s comment
                </th>
              </tr>
            </thead>
            <tbody>
              {user.urine_ph !== "" && (
                <tr>
                  <td className="font-bold px-4 py-3"> Urine pH</td>
                  <td className="px-4 py-3">{user.urine_ph} </td>
                  <td className="px-4 py-3">
                    {user.urine_ph < 6 && (
                      <div>
                        <p>Urine pH is low</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_ph_low");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.urine_ph > 6.5 && (
                      <div>
                        <p>
                          Urine pH is high- Increased risk for formation of
                          Calcium phosphate stones (Brushite, Carbapatite,
                          Struvite)
                        </p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_ph_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.urine_ph >= 6 && user.urine_ph < 6.5 && (
                      <div>
                        <p>Normal urine pH</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.morning_urine_specific_gravity != "" && (
                <tr>
                  <td className="font-bold border-t-2 border-gray-200 px-4 py-3">
                    Urine Specific Gravity
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.morning_urine_specific_gravity}{" "}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.morning_urine_specific_gravity < 1.02 && (
                      <div>
                        <p>Normal urine specific gravity </p>
                      </div>
                    )}
                    {user.morning_urine_specific_gravity >= 1.02 && (
                      <div>
                        <p>High Urine specific gravity</p>{" "}
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_gravity_high");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.urine_culture !== "" && (
                <tr>
                  <td className="font-bold border-t-2 border-gray-200 px-4 py-3">
                    Urine Culture Level
                  </td>

                  <td className=" border-t-2 border-gray-200 px-4 py-3">
                    {user.urine_culture == "0" ? "Postive" : " Negative"}{" "}
                    {"( " + user.urine_culture_bacteria + " )"}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {user.urine_culture >= 0 && (
                      <div>
                        <p>Positive urine culture</p>
                        <button
                          className="bg-gray-500 text-white p-2 rounded-md text-xs"
                          onClick={() => {
                            setPopup(true);
                            setValue("urine_culture_up");
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    )}
                    {user.urine_culture < 0 && (
                      <div>
                        <p>Urine Culture Negative</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
              {user.glucosuria !== "" &&
                (user.glucosuria != undefined && (
                  <tr>
                    <td className="font-bold border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      Glucosuria
                    </td>
                    <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      {user.glucosuria == "0" ? "Postive" : " Negative"}
                    </td>
                    <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                      {user.glucosuria >= 0 && (
                        <div>
                          <p>Positive urine glucosuria</p>
                          <button
                            className="bg-gray-500 text-white p-2 rounded-md text-xs"
                            onClick={() => {
                              setPopup(true);
                              setValue("urine_glucosuria_up");
                            }}
                          >
                            Click here
                          </button>
                        </div>
                      )}
                      {user.glucosuria < 0 && (
                        <div>
                          <p>Urine Glucosuria Negative</p>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Data
        popup={popup}
        setPopup={setPopup}
        value={value}
        user={user}
        handleChange={props.handleChange}
      />
    </section>
  );
}
