import { ListItem } from "@material-ui/core";
import React from "react";

function parseNameAndMeaning(a, b) {
  return "";
}

const background_conditions = {
  options: [
    { name: "Hypertension", id: 1, comment: "" },
    {
      name: "Type 2 diabetes mellitus ",
      comment: "- Increased risk mainly for uric acid stones",

      id: 2,
    },
    {
      name: "Gout",
      comment: " - Increased risk mainly for uric acid stones",
      id: 3,
    },
    {
      name: "Obesity",
      comment: "- Increased risk mainly for uric acid stones",
      id: 4,
    },
    { name: "Hyperlipidemia", id: 5, comment: "" },
    { name: "Coronary artery disease", id: 6, comment: "" },
    {
      name: "Chronic kidney disease",
      comment: "and end-stage kidney disease",
      id: 7,
    },
    {
      name: "Inflammatory Bowel disease",
      comment:
        "- and intestinal surgery- If terminal ileal disease or resection then Risk for increased urine oxalate levels",
      id: 8,
    },
    {
      name: "Renal and bladder anatomic anomalies",
      comment: "- Risk for stone formation due to urinary stasis",
      id: 9,
    },
    {
      name: "HyperParathyroidism",
      comment:
        " - Risk for increased urine calcium levels and mainly Brushite stone formation",
      id: 10,
    },
    {
      name: "Bone disorders and fractures",
      comment: "- Risk for increased urine calcium levels",
      id: 11,
    },
    {
      name: "Oncologic condition with bone metastasis",
      comment: "- Risk for increased urine calcium levels",
      id: 12,
    },
    { name: "Aortic calcification", id: 13, comment: "" },
    {
      name: "Sarcoidosis",
      comment: "- Risk for increased urine calcium levels",
      id: 14,
    },
    {
      name: "Metabolic Syndrome",
      comment: "- Increased risk mainly for uric acid stones",
      id: 15,
    },
  ],
};

const medicationOptions = {
  options: [
    {
      name: "Vitamin C",
      comment: "- Risk for increased urine oxalate levels",
      id: 1,
    },
    {
      name: "Vitamin D",
      comment: "- Risk for increased urine calcium levels",
      id: 2,
    },
    {
      name: "Indinavir",
      comment: "- Risk for drug precipitation and crystallization in the urine",
      id: 3,
    },
    {
      name: "Thiazide",
      comment: "- Risk for increased urine calcium levels",
      id: 4,
    },
    {
      name: "Furosemide",
      comment: "- Risk for dehydration",
      id: 5,
    },
    {
      name: "Chronic antibiotic use",
      comment:
        " - Increased risk for calcium oxalate stones due to eradication of oxalobacter\n formigenes in the intestines and resulting increased oxalate resorption",
      id: 6,
    },
    //  { name: "Others", id: 7 },
  ],
};

function PatientDetails(props) {
  const ParseListWithSummary = (l) => {};

  const ParseStringFromList = (l) => {
    let a = [];

    for (let i = 0; i < l.length; i++) {
      a.push(<p>{l[i]}</p>);
      if (l[i] === "GI Surgery") {
        a.push(
          <p>
            "(Pay attention to the possibility of Short Bowel Syndrome due to
            previous GI Surgery. Short Bowel Syndrome can affect Oxalate
            intestinal absorption and lead to the formation of Calcium Oxalate
            Monohydrate stones.)";
          </p>
        );
      }
    }

    return <div>{a}</div>;
  };

  const { user } = props;

  let BMI = (user.weight / (((user.height / 100) * user.height) / 100)).toFixed(
    3
  );
  let BMIComment = "";
  let bmiDict = [
    [40, "Obesity Class III"],
    [35, "Obesity Class II"],

    [30, "Obesity Class I"],
    [25, "Pre-Obesity"],
    [20, "Normal Weight"],
  ];

  for (let data of bmiDict)
    if (BMI > data[0]) {
      BMIComment = data[1];
      break;
    }
  if (BMIComment === "") {
    BMIComment = "Underweight";
  }

  return (
    <div>
      <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75">
        <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
          Patient's General Data
        </div>
        <table className="table-fixed text-left w-full md::w-8/12 mt-2">
          <tbody>
            {/* <tr>
                <td className="py-2 font-bold">Name</td>
                <td className="py-2 pl-4 lg:pl-0">{user.name}</td>
              </tr> */}
             {props.id && <tr>
              <td className="pt-2 font-bold">Patient ID</td>
              <td className="pt-2 pl-4 lg:pl-0">{props.id}</td>
            </tr>}
            <tr>
              <td className="pt-2 font-bold">Age</td>
              <td className="pt-2 pl-4 lg:pl-0">{user.age}</td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Sex</td>
              <td className="pt-2 pl-4 lg:pl-0">{user.sex}</td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Weight</td>
              <td className="pt-2 pl-4 lg:pl-0">{user.weight}</td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Height</td>
              <td className="pt-2 pl-4 lg:pl-0">{user.height}</td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Body Mass Index(BMI)</td>
              <td className="pt-2 pl-4 lg:pl-0">
                {BMI} Kg/m<sup>2</sup> {" ("}
                {BMIComment}
                {")"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mt-4">
        <div className="py-2 lg:text-xl text-base font-bold border-b-2 w-full lg:w-3/12 border-indigo-500">
          Clinical Data
        </div>
        <table className="table-fixed text-left w-full md::w-8/12 mt-2">
          <tbody>
            <tr>
              <td className="pt-2 font-bold">
                Personal or Family History of Stone Disease
              </td>
              <td className="pt-2 pl-4 lg:pl-0">
                {user.history_stone_disease}
              </td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Medical Treatment / Allergies </td>
              <td className="pt-2 pl-4 lg:pl-0">{user.allergies}</td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">
                Previous Abdominal / Urologic Procedure
              </td>
              <td className="pt-2 pl-4 lg:pl-0">
                {ParseStringFromList(user.previous_abdominal)}
              </td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Background Conditions</td>
              <td className="pt-2 pl-4 lg:pl-0">
                {user.background_conditions.map((l) => {
                  let e = background_conditions.options.find(
                    (b) => b.name == l
                  );
                  return (
                    <>
                      {" "}
                      <p>{e.name}</p>
                      {props.disabledComment == undefined && <i>{e.comment}</i>}{" "}
                    </>
                  );
                })}{" "}
              </td>
            </tr>
            <tr>
              <td className="pt-2 font-bold">Medical Conditions</td>
              <td className="pt-2 pl-4 lg:pl-0">
                {user.medications.map((l) => {
                  let e = medicationOptions.options.find((b) => b.name == l);
                  return (
                    <>
                      {" "}
                      <p>{e.name}</p>
                      <i>{e.comment}</i>
                    </>
                  );
                })}{" "}
              </td>
            </tr>

            <tr>
              <td className="pt-2 font-bold">Tobacco Use</td>
              <td className="pt-2 pl-4 lg:pl-0">{user.tobacco_use}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PatientDetails;
