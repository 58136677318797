import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Link, Navigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { shallowEqual, useSelector } from "react-redux";

function AdminNav() {
  const items = [
    { id: 1, superAdmin: false, name: "Home", link: "/" },

    { id: 2, superAdmin: false, name: "Patients", link: "patients" },

    { id: 3, superAdmin: true, name: "Create User", link: "create-user" },
    { id: 4, superAdmin: true, name: "Create Center", link: "create-center" },
    { id: 5, superAdmin: true, name: "Users", link: "users" },
    {
      id: 6,
      superAdmin: false,
      name: "Profile",
      link: "",
    },
  ];

  const [b, setB] = useState(false);
  const fade = useSpring({
    opacity: b ? 1 : 0,
    transform: b ? "translate3d(0%,0,0)" : "translate3d(100%,0,0)",
    animationDelay: -6,
  });
  const auth = getAuth();

  const [loggedIn, setloggedIn] = useState(auth.currentUser != null);

  useEffect(() => {
    auth.onAuthStateChanged((e) => {
      setloggedIn(e == null ? false : true);
    });
  }, []);

  const admin = useSelector((e) => e.super_admin, shallowEqual);
  console.log('RERENDER');
  console.log(admin);

  return (
    <div className="font-sans flex flex-col items-center mx-auto bg-logoBlue">
      <header className="w-full px-3 py-3 flex justify-around z-50">
        <div className="w-full md:flex justify-between items-center">
          <div className="flex justify-between items-center text-secondary-default">
            <Link to={"/"}>
              <img
                src="./assets/finalLogo.jpg"
                className="w-16 ml-4"
                alt="logo"
              />
            </Link>
            <button
              type="button"
              onClick={() => setB(!b)}
              className="text-white md:hidden focus:outline-none"
            >
              {b === false ? (
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 329.26933 329"
                  width="329pt"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
                </svg>
              )}
            </button>
          </div>
          <div className="hidden md:inline-flex md:flex-row">
            {items.map((e) => {
              return e.superAdmin && !admin ? (
                <></>
              ) : (
                <Link
                  key={Math.random()}
                  to={e.link}
                  className="ml-2 px-3 py-1 rounded text-white font-bold cursor-pointer"
                >
                  <span>{e.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </header>
      <animated.div
        style={fade}
        className="fixed top-16 w-full h-full bg-white flex flex-col justify-center items-center md:hidden z-50"
      >
        {items.map((e) =>
          e.superAdmin && !admin ? (
            <></>
          ) : (
            <Link
              to={e.link}
              onClick={() => setB(!b)}
              className="ml-2 px-3 py-1 rounded text-secondary-default font-bold hover:text-gray-400 hover:bg-neon-default cursor-pointer"
            >
              <span>{e.name}</span>
            </Link>
          )
        )}
      </animated.div>
    </div>
  );
}

export default AdminNav;
