const IntroductionRational = () => {
  return (
    <div className="lg:w-full mx-auto">
      <div className="w-full h-full bg-gray-900 py-32 px-10 relative z-0 mb-4">
        <img
          style={{ height: "100%" }}
          className="w-full object-cover object-center opacity-90 block absolute inset-0 "
          src="./assets/home/great_wall.jpg"
        />
        <div className="text-center relative z-10 w-full lg:pt-28">
          <h2 className=" text-lg md:text-2xl lg:text-5xl text-white font-bold title-font mb-2">
            THE RATIONALE
            <br />
            ​FOR AN ONLINE ASSISTED
            <br />
            ​METABOLIC EVALUATION
          </h2>
        </div>
      </div>
      <div className="m-3">
        The human body is a marvelous, self-correcting machine, that has the
        capacity to fix abnormalities and maintain an equilibrium through
        various regulatory organs and functions.
        <br />
        <br />
        For urinary stone disease, which affects in total around 8-10% of the
        population, it is mainly the renal, digestive and endocrinologic systems
        that keep the balances and should be checked for abnormalities when
        stones are formed.
        <br />
        <br />
        Thus when performing a metabolic evaluation to understand why the
        patients had stones in the first place, and how to prevent future stones
        from recurring, our detective work needs to focus on these three
        systems.
        <br />
        <br />
        The different scenarios are either:
        <ul className="list-disc ml-10">
          <li>
            <strong>Renal problem</strong> (Concentrating/Absorption defects,
            Acid-Base imbalances)
          </li>
          <li>
            {" "}
            An <strong>Endocrinologic problem</strong> (Mainly
            HyperParathyroidism) detected in the blood (and/or urine results)
          </li>
        </ul>
        And above all, always in the background,&nbsp;
        <strong>
          Dietary excesses (calcium, salt, protein, oxalate etc.) or
          deficiencies (Fluids, citrate etc.)&nbsp;
        </strong>{" "}
        can always add to the risk, with normal/abnormal blood and/or urine
        results.
        <br />
        Thus, for&nbsp;<u>each stone</u> there is a focus for certain
        potential&nbsp;<u>laboratory abnormalities</u>, and how to fix them, in
        addition to a corresponding&nbsp;
        <u>focus on specific dietary risk factors</u>, in addition to the&nbsp;
        <u>general dietary evaluation</u>. &nbsp;
        <br />
        <br />
        Hence, the job of this site is to provide a systematic checklist to
        cover &gt;90% of all stone cases and scenarios, based on vast amounts of
        literature and research about this subject.
        <br />
        <br />
        In addition to the metabolic evaluation tool performed by the treating
        physician (Urologist or non-Urologist), there exists three other
        applications to the site:
        <ol className="ml-10 list-disc">
          <li>
            {" "}
            A partial evaluation tool for a dedicated nurse/assistant/staff
            member in the metabolic clinic, to begin the questionnaire while the
            patient is waiting her/his appointment with the doctor.
          </li>{" "}
          <li>
            {" "}
            A dietary calculator for the patient to perform her/his own
            evaluation and keep the needed dietary balances, in addition to
            providing a &quot;closer to real-life&quot; image of the daily
            dietary intake, than a &quot;one time questionnaire&quot; in the
            clinic.
          </li>
          <li>
            {" "}
            Provides information regarding urinary stone disease, in simple and
            clear terms.
          </li>
        </ol>
        <br />
        
        In the future, when necessary approvals are acquired, this tool can
        become a wide-use, online application used by clinicians and patients,
        thus making a wide online data-base to understand trends and guide
        treatment better, in addition to having each patient&apos;s medical
        history available for future treatment planning. However, many
        regulations &nbsp;and conditions should be met before this step, in
        order to ensure Privacy and Security of the data provided.
        <br />
        <br />
        So Let&apos;s Get to Work.
      </div>
    </div>
  );
};

export default IntroductionRational;
