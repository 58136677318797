const Background = () => {
  return (
    
      <div  className="m-10  overflow-auto " style={{ height: "100vh"  }}>
      <h2 className="text-3xl mb-10">Introduction</h2>
      <div className="text-xl">
        <p>
          Urinary stone disease is a very common condition, in which the
          combination of dietary, lifestyle and sometimes, genetic factors, lead
          to the creation of stones in the urinary tract.&nbsp;
        </p>
        <p>
          The origin of these stones is due to the precipitation of salt and
          minerals in the urine, which can crystallize and grow to create solid
          masses. These stones can grow silently in the kidneys or bladder, and
          can be symptomatic if they block the flow of urine.&nbsp;
        </p>
        <p>
          To diagnoses urinary stones, doctors usually use CT scans, ultrasound
          or radiography imaging. &nbsp;
        </p>
        <p>
          If the stone is small enough, it can pass spontaneously with the
          urine. However, if the stone is too big to pass in the urine, it might
          block the kidney or ureters, and cause a very painful state called a
          &ldquo;renal colic&rdquo;, which may necessitate urgent decompression
          with a ureteral stent or a nephrostomy tube through the kidney.{" "}
        </p>
        <p>
          Treatment of urinary stones involves breaking the stones through
          different energy sources, and evacuation of the fragments from the
          urinary system. There are three main techniques to achieve this. The
          first one is a Ureteroscopy which is be performed endoscopically
          through the urethra and the bladder all the way up to the kidney . The
          second is Percutaneous Nephrolithotomy, which involves a puncture
          through the skin and into the kidney to reach the stone . The third,
          called Extracorporal Shockwave Lithotripsy, involves breakage of the
          stone into smaller pieces that evacuate in the urine, with the help of
          an energy source outside the patient&rsquo;s body, sending shochwaves
          to break the stone. The choice for the technique to use depends on
          many factors like the stone type, size, location and the
          patient&rsquo;s preferences.
        </p>
        <p>
          In all cases, the patient needs to undergo a metabolic work up
          process, to identify the type and cause of the stones. This work up
          also serves to reduce the risk for stone recurrence, estimated at 50
          to 70 percent in 5 years, if the metabolic abnormality is not
          corrected.
        </p>
        <p>
          This mandatory and critical metabolic work up, is achieved by a full
          dietary, lifestyle, metabolic, laboratory evaluation of the patient in
          the clinic, with the treating urologists and sometimes, treating
          nephrologist.
        </p>
        <p>
          <br />
        </p>
        <p>
          Links to explanatory videos from EAU website &ndash;
          {/* [We need to ask
          Bhaskar for connection to authorization to use the videos] */}
        </p>
        <div className="font-bold underline text-blue-500">
          TO BE ADDED <br></br>
          {/* <p>
          <a href="https://patients.uroweb.org/videos/ureteroscopy-urs-video/">
            https://patients.uroweb.org/videos/ureteroscopy-urs-video/
          </a>
        </p>
        <p>
          <a href="https://patients.uroweb.org/videos/percutaneous-nephrolithotomy-pcnl-video/">
            https://patients.uroweb.org/videos/percutaneous-nephrolithotomy-pcnl-video/
          </a>
        </p>
        <p>
          <a href="https://patients.uroweb.org/videos/shock-wave-lithotripsy-swl-video/">
            https://patients.uroweb.org/videos/shock-wave-lithotripsy-swl-video/
          </a>
        </p> */}
        </div>
        <p>
          <br />
        </p>
      </div>
      </div>
  
  );
};
export default Background;
