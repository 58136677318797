import React, { useEffect } from "react";
import PatientDetails from "./patientDetails";
import ResultBlood from "./resultBlood";
import ResultUrine from "./resultUrine";
import { Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import ResultStone from "./ResultStone";
import ResultPH from "./ResultPH";
import { getAuth } from "firebase/auth";

function Submit(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getAuth().currentUser) {
      navigate('/login?previous=form');
    }
  }, [])
  const params = useLocation();
  const [user, handleChange, urinedata, setuc] = [
    props.user,
    props.handleChange,
    props.urinedata,
    props.setuc,
  ];
  const SendData = async (e) => {
    //  e.preventDefault();
    const {
      blood_creatinine_p,
      blood_calcium_p,
      ionized_calcium_p,
      fasting_glucose_p,
      blood_uric_acid_p,
      blood_na_p,
      blood_potassium_p,
      magnesium_p,
      phosphate_p,
      chloride_p,
      parathyroid_hormone_p,
      calcitriol_p,
      blood_bicarbonate_p,
      triglycerides_p,
      cholesterol_p,
      urine_creatininura_p,
      last_urine_volume_p,
      urine_calcium_p,
      urine_sodium_p,
      urine_oxalate_p,
      urine_urea_levels_p,
      urine_uric_acid_p,
      citraturia_p,
      cystinuria_p,
    } = user;

    // const response = await fetch(
    //   "https://globalstoneclinic.com/api/submitdoctoradvice",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       id,
    //       blood_creatinine_p,
    //       blood_calcium_p,
    //       ionized_calcium_p,
    //       fasting_glucose_p,
    //       blood_uric_acid_p,
    //       blood_na_p,
    //       blood_potassium_p,
    //       magnesium_p,
    //       phosphate_p,
    //       chloride_p,
    //       parathyroid_hormone_p,
    //       calcitriol_p,
    //       blood_bicarbonate_p,
    //       triglycerides_p,
    //       cholesterol_p,
    //       urine_creatininura_p,
    //       last_urine_volume_p,
    //       urine_calcium_p,
    //       urine_sodium_p,
    //       urine_oxalate_p,
    //       urine_urea_levels_p,
    //       urine_uric_acid_p,
    //       citraturia_p,
    //       cystinuria_p,
    //     }),
    //   }
    // );
    // if (response.status === 200) {
    //   alert("Valuable data submitted");
    // } else {
    //  alert("Data submission Failed");
    //}

    navigate("/patientadvice");
  };

  return (
    <section className="text-gray-600 body-font relative md:px-6 lg:px-16">
      <div className="container px-5 py-6 mx-auto flex flex-wrap justify-center">
        <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mb-4">
          <p className="font-bold border-black font-mono lg:text-3xl text-lg">
            Doctor Summary
          </p>
        </div>

        <PatientDetails user={user} />

        <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mt-4">
          <ResultBlood 
          urinedata={urinedata}
          setuc={setuc}
          user={user} handleChange={handleChange} />
        </div>
        
       
        <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mt-4">
          <ResultUrine
            user={user}
            handleChange={handleChange}
            urinedata={urinedata}
            setuc={setuc}
          />
           <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mt-4">
          <ResultPH 
          urinedata={urinedata}
          setuc={setuc}
          user={user} handleChange={handleChange} />
        </div>
        </div>
        {user.history_stone_disease === "Yes" && (
          <div className="w-full rounded-lg shadow-lg p-8 bg-gray-100 bg-opacity-75 mt-4">
            <ResultStone
              user={user}
              urinedata={urinedata}
              setuc={setuc}
            />
          </div>
        )}
      </div>
      <button
        className="ml-4 text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
        onClick={SendData}
      >
        Check Patient Summary
      </button>
    </section>
  );
}

export default Submit;
