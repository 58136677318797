const UserGuide = () => {
  return (
    <div className="m-10">
      <p>
        GlobalStoneClinic.com is an online software application designed to aid
        urologists and nephrologists in the process and procedures of
        effectively managing urinary stone patients, and most importantly,
        reduce the risk for stone recurrence.
      </p>
      <p>
        GlobalStoneClinic provides a complete evaluation of the patient&rsquo;s
        clinical data, blood and urine results, stone composition, and patient
        diet, to summarize and provide patient-specific diet recommendations.
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            GlobalStoneClinic is based on the vast knowledge accumulated in the
            literature, and the rich experience of leading urologists in the
            field to offer a systematic, easy-to-use approach for conducting the
            metabolic workup for urinary stone patients.&nbsp;
          </p>
        </li>
        <li>
          <p>
            This online application will save time, money, and energy for both
            clinician and patient, and most importantly, will make the knowledge
            of how to perform this important evaluation process, accessible to
            all clinics caring for stone patients, worldwide.
          </p>
        </li>
      </ul>
      <p></p>
      <p>
        GlobalStoneClinic can be used by visiting the website or it can be
        installed and used as a software application service in the
        clinic&rsquo;s informatics system.
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            The process begins by clicking on&nbsp;<strong>Start Now</strong>{" "}
            and choosing an identifier code for the patient.&nbsp;
          </p>
        </li>
        <li>
          <p>
            This code is anonymous and does not disclose the patient&rsquo;s
            name or identity, to make sure the patient&rsquo;s data stays
            private and secured, as well as to save and keep a record of the
            patient&rsquo;s data for future follow-up consultations.
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        <strong>This can be done by clicking on the button:&nbsp;</strong>
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            Generate a new Patient&rsquo;s Identifier Code and keep this code
            secured for future use.&nbsp;
          </p>
        </li>
        <li>
          <p>
            If the patient already has an Identifier Code, it can be submitted
            to enter to the patient&rsquo;s records.
          </p>
        </li>
      </ul>
      <p></p>
      <p>
        <br />
        &nbsp;
      </p>
      <p>
        The metabolic workup of the stone patient is performed at least 6 weeks
        after the last intervention or stone passage, with the patient in his or
        her usual dietary and lifestyle setting, to best reflect the conditions
        of stone formation.
      </p>
      <p>
        <strong>Step 1:</strong> investigate the patient&rsquo;s general
        clinical data, to identify systemic disorders that might influence the
        urinary stone formation, such as the metabolic syndrome for example
        which might predispose the patient to uric acid stones, or previous
        gastrointestinal surgery in the ileum, which might cause absorption
        disorders and predispose the patient for calcium oxalate monohydrate
        stones.
      </p>
      <p>
        <strong>Step 2:</strong> Filling the patient&rsquo;s blood results, with
        the indispensable values being Creatinine, Calcium and ionized Calcium,
        uric acid, and if available, fasting blood glucose. Other values can
        also be filled if provided.
      </p>
      <p>
        While creatinine is used to identify renal insufficiency and is a basic
        test for any urologic patient with stone disease, fasting glucose is
        used to search for diabetes or suspicion of a metabolic syndrome. Blood
        uric acid helps in identifying gout, and blood calcium help to rule out
        hypercalcemia which would orient us toward possible hyperparathyroidism.
      </p>
      <p>
        <strong>Step 3:</strong> The urine results, with{" "}
        <strong>six mandatory values</strong> that need to be filled.
      </p>
      <p>These includes:</p>
      <ol className="list-decimal ml-10">
        <li>
          <p>Urine volume</p>
        </li>
        <li>
          <p>Urine creatinine or Creatininuria</p>
        </li>
        <li>
          <p>Urine calcium</p>
        </li>
        <li>
          <p>Urine sodium,&nbsp;</p>
        </li>
        <li>
          <p>Urine urea (or Urine urea nitrogen) levels</p>
        </li>
        <li>
          <p>Urine uric acid levels.&nbsp;</p>
        </li>
      </ol>
      <p>
        *If available, Urine oxalate, Citrate, and Cysteine can also be entered.
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            Urinary volume is important because a value of fewer than two liters
            will signify a risk factor for stone recurrence.
          </p>
        </li>
        <li>
          <p>
            Urine creatinine is used as a quality control test for the
            completion of the 24 hours urine collection test. It is a function
            of the body&rsquo;s muscle mass and the value needs to be between
            15-20 milligram per kilogram of bodyweight if the urine collection
            test was performed properly.
          </p>
        </li>
        <li>
          <p>
            Urine calcium is important to look for hyper-calciuria, the most
            common urinary abnormality
          </p>
        </li>
        <li>
          <p>
            Urine sodium is important because it reflects the dietary intake of
            salt in the diet, a risk factor because a high salt intake will
            eventually cause higher urine calcium.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Urine uric acid is a risk factor for uric acid stone
            formation.&nbsp;
          </p>
        </li>
      </ul>
      <p>
        Urine urea or Nitrogen-urea is important because it reflects the dietary
        consumption of protein in the last 24 hours. A high value will reflect
        an excess of protein intake, which is an important risk factor for stone
        formation if it exceeds 1 gram per kilogram per day.&nbsp;
      </p>
      <p>
        Other elements that might also be used in the metabolic workup but not
        systematically incorporated are urine oxalate, urine cysteine, or
        urinary citrate.
      </p>
      <p>
        <strong>Step 4:</strong> To fill the data of morning spot urine, which
        can show us the urine pH, presence of urine crystals that can suggest
        certain stone types and metabolic abnormalities, a urine bacteriologic
        culture to rule out urinary infection, and also the specific gravity to
        show the patient&rsquo;s hydration level.
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            Urine pH needs to be between 6-6.5 and this recommendation can
            change according to the specific stone type. Keeping track of urine
            pH is also a way to make sure the patient is adhering to medical
            therapy with potassium citrate for example.
          </p>
        </li>
        <li>
          <p>Urine culture is important to rule out a urinary infection</p>
        </li>
        <li>
          <p>
            Urine specific gravity reflects the hydration status of the patient
            and if it exceeds 1.012, it can show that the patient is in
            dehydration and there is a risk for new stone formation.
          </p>
        </li>
      </ul>
      <p>
        Urine crystals are an optional test that can be performed in specialized
        trained laboratories to identify certain urine crystals suggestive of
        certain stone diseases. This is an important test in case the stone was
        expelled or not sent for analysis.
      </p>
      <p></p>
      <p>
        <br />
        &nbsp;
      </p>
      <p></p>
      <p>Once the above sections are completed,</p>
      <p>
        The Doctor&rsquo;s Summary Page appears, with a recap of the
        patient&rsquo;s clinical and lab data, and the corresponding comments
        for the doctor concerning each data component if there is an
        abnormality.
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            If urine abnormalities are found, then a corresponding dietary
            questionnaire for that urine abnormality will appear, to target the
            dietary imbalance causing this abnormality to appear, and risk the
            patient for new stone formation.
          </p>
        </li>
        <li>
          <p>
            If the patient&rsquo;s blood and urine results are normal, then the
            general recommendations will appear in the bottom section of the
            Patient&rsquo;s Final Summary Page. If there is an abnormality
            found, specific recommendations for this patient will be given,
            according to the abnormality found.
          </p>
        </li>
        <li>
          <p>
            If the Stone Type is known, then the specific recommendation
            according to the stone type will also appear in addition to the
            general recommendations.
          </p>
        </li>
      </ul>
      <p>
        <br />
        &nbsp;
      </p>
      {/* <p> */}
      {/* For <strong>Calcium Oxalate Monohydrate&nbsp;</strong>stones,
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            The goal is to diagnose excess dietary intake of oxalate from eating
            large quantities of oxalate-rich foods like spinach, rhubarb,
            chocolate, and cashew nuts for example.
          </p>
        </li>
      </ul>
      <p>
        For <strong>Calcium Oxalate dehydrate stones and Brushite</strong>,
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            The goal is to diagnose excess calcium intake in the diet from
            overconsumption of dairy products for example.
          </p>
        </li>
      </ul>
      <p>
        <br />
        &nbsp;
      </p>
      <p>
        Patients with uric acid stones need to go through a dietary evaluation
        to rule out excess intake of purine-rich foods like certain types of
        seafood (for example anchovies or sardines or mussels), in addition to
        red meat and alcohol. In addition, these patients need an evaluation of
        the risk for metabolic syndrome that might promote risk factors for uric
        acid stones.
      </p>
      <p>
        <br />
        &nbsp;
      </p>
      <p>
        For{" "}
        <strong>
          Struvite and other Infection-related stones like Carbapatite
        </strong>
        ,
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            The goal is to diagnose the infection source and control the urinary
            pH to prevent the recurrence of these stone types.
          </p>
        </li>
      </ul>
      <p>
        For <strong>Cystine stones</strong>,
      </p>
      <ul className="list-disc ml-10">
        <li>
          <p>
            The goal is to verify an adequate urine output of at least 3 liters
            per day, control the urine pH to 7.5 or higher if possible, and last
            but not the least, reduce the intake of cysteine in the diet through
            less consumption of eggs and parmesan cheese for example.
          </p>
        </li>
      </ul> */}
      <p>
        The described work flow significantly reduces the time to conduct the
        metabolic evaluation process, and thus solves one of the main limiting
        factors for the application of this process to all stone patients.
      </p>
      <p>
        The systematic questionnaire offers to the clinician all the necessary
        components in the metabolic work up to cover more than 95% of possible
        stone types.
      </p>
      <p></p>
      <p></p>
      <p>GlobalStoneClinic offers two additional interfaces,</p>
      <ol
        className="ml-10"
        style={{
          listStyleType: "lower-alpha",
        }}
      >
        <li>
          <p>
            The first one allows an assistant or a nurse in the clinic to
            systematically fill all the patient&rsquo;s data and leave only the
            summary and recommendation validation task to the clinician, thus
            saving even more precious time for the doctor.
          </p>
        </li>
        <li>
          <p>
            The second interface is the full dietary questionnaire, which can be
            filled by the patient or a dietician in the clinic.&nbsp;
          </p>
        </li>
      </ol>
      <ol className="list-item">
        <ul className="list-disc ml-20">
          <li>
            <p>
              Once the complete dietary questionnaire is filled by the patient
              or dietician without time pressure, it will better represent the
              patient&apos;s diet over a longer period than the usual accepted 3
              days.&nbsp;
            </p>
          </li>
          <li>
            <p>It also saves time during the doctor-patient meeting.</p>
          </li>
        </ul>
      </ol>
      <br />
      <br />
      <div className="w-full flex justify-center items-center">
      <a
  
        className="flex justify-center  text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
href="https://drive.google.com/file/d/1-dNu0IeIW-puM86zae6hctULZYg62XZk/view?usp=sharing"        target={"_blank"}
      >
        {" "}
        Click here to open the visual representation of the user's guide:{" "}
      </a>
    
      </div>
      
     </div>
  );
};
export default UserGuide;
