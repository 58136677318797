import { Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { DataGrid } from '@mui/x-data-grid'
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPatientRecords, fetchUser } from '../../../api_calls/CallList';
export default function PatientsInfo(props) {
    const user = useSelector(e => e);

    const columns = [
        { field: 'patient_id', headerName: 'ID', width: 130, },
        { field: 'doctor', headerName: 'Treating Doctor', width: 160 },
        { field: 'age', headerName: 'Age', type: 'number', width: 60 },
        { field: 'sex', headerName: 'Gender', width: 90 },
        { field: 'createDate', headerName: 'Date of Creation', width: 160 },

        { field: 'country', headerName: 'Center\'s State', width: 130 },
        { field: 'state', headerName: 'Center\'s Country', width: 130 },
        { field: 'center_unique', headerName: 'Center\'s Unique ID', width: 170 },

        {
            field: 'moreInfo', headerName: "Patient's Info", renderCell: (params) => {
                return <div>
                    <Link target={'_blank'} to={`/getpatient?id=${params.row.patient_id}`} >
                        <button className='bg-purple-500 px-3 py-2 rounded-sm text-white font-bold'>More Details</button></Link>
                    <Link target={'_blank'} to={`/getpatient?id=${params.row.patient_id}`} ><button className='bg-purple-500 ml-6 px-3 py-2 rounded-sm text-white font-bold'>Modify Patient</button></Link>

                </div>
            }, flex: 1
        },



    ];

    const [rows, setrows] = useState([]);
    const [selectedButton, setSelectedButton] = useState(0);
    const fetchRecords = async (type) => {
        try {
            const data = await fetchPatientRecords(type);

            return data;

        }
        catch (error) {

        }
    }

    useEffect(() => {
        fetchRecords('my').then(e => {
            e.forEach(element => {
                element.state = user.center.state;
                element.country = user.center.country;
                element.center_unique = user.center.center_unique;
                element.doctor = getAuth().currentUser.displayName;
                const date = new Date(parseInt(element.patient_id));
                element.createDate = date.getDate() + ' / ' + date.getMonth() + ' / ' + date.getFullYear();
            })


            setrows(e);

        })







    }, [])

    const buttons = [
        {
            name: 'My Patients',
            id: 0,
            onClick: async () => {
                if (selectedButton === 0)
                    return;

                setSelectedButton(0);
                fetchPatientRecords('my').then(data => {
                    data.forEach(element => {
                        element.state = user.center.state;
                        element.country = user.center.country;
                        element.center_unique = user.center.center_unique;
                        element.doctor = getAuth().currentUser.displayName
                        const date = new Date(parseInt(element.patient_id));
                        element.createDate = date.getDate() + ' / ' + date.getMonth() + ' / ' + date.getFullYear();

                    })

                });
            },
        },
        {
            name: 'Center Patients',
            id: 1,
            onClick: async () => {
                if (selectedButton === 1)
                    return;

                setSelectedButton(1)

                fetchPatientRecords('center').then(e => {
                    e.forEach(element => {
                        element.state = user.center.state;
                        element.country = user.center.country;
                        const date = new Date(parseInt(element.patient_id));
                        element.createDate = date.getDate() + ' / ' + date.getMonth() + ' / ' + date.getFullYear();
                        element.center_unique = user.center.center_unique;

                    })
                    return e;

                }).then(setrows);


            },
        }, {
            adminRequired: true,
            name: 'All Patients',
            id: 2,
            onClick: async () => {
                if (selectedButton === 2)
                    return;

                setSelectedButton(2)

                fetchPatientRecords('all').then(e => {
                    e.forEach(element => {
                        const date = new Date(parseInt(element.patient_id));
                        element.createDate = date.getDate() + ' / ' + date.getMonth() + ' / ' + date.getFullYear();

                    })
                    return e;

                }).then(setrows);


            },
        }
    ]


    return <div style={{
        height: '70vh'
    }}>
        <div className='mt-10 text-4xl font-bold text-center border-b-2 pb-10'>
            Patient Records
        </div>
        <div className='align-middle justify-center items-center sm:py-4 flex sm:flex-row flex-col' style={{

            justifyContent: 'space-around',
        }}>
            {buttons.map(e => {
                if (e.adminRequired && !user.super_admin) return <></>
                return <button
                    onClick={e.onClick}

                    className={`${selectedButton === e.id ? 'bg-purple-500' : 'bg-gray-400'} w-56 my-3 font-bold  align-middle justify-center  px-5 py-3 rounded-md`}
                >{e.name}</button>

            })}

        </div>
        <div style={{
            height: '70vh'
        }} >
            <DataGrid

                getRowId={params => params.patient_id}
                sx={{
                    textAlign: 'center',
                    padding: '10px 10px 0px 10px'
                }}
                rows={rows}
                columns={columns}

                pageSize={10}
                rowsPerPageOptions={[10]}

            /></div>
    </div>
}