import Input from "../Input/input";
import { Component, useEffect, useState } from "react";
import "./loginpage.css";

import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import LoadingGif from "../../LoadingGif";
function LoginPage() {

  const newAlertObj = { display: false, msg: "", title: "", }
  let navigate = useNavigate();
  let { search } = useLocation();
  const auth = getAuth();

  useEffect(() => {
    setTimeout(() => {
      if (auth.currentUser) {
        navigate("/");
      }
    }, 1000);
  });

  async function login(e) {
    e.preventDefault();

    if (user.email.includes("@") && user.pass.length > 5) {
      try {
        await signInWithEmailAndPassword(auth, user.email, user.pass);

        const query = new URLSearchParams(search);
        const paramField = query.get("previous");
        navigate(paramField ? "/" + paramField : "/");
      } catch (error) {
        if (error.message) {
          error.message = error.message.replace('Firebase: ', '')
        }
        updateAlert({ display: true, 'msg': error.message, 'title': "Error" })
        console.log(error);
        // TODO: ALERT
      }
    } else {
      updateAlert({ display: true, 'msg': "Invalid login ID or Password", 'title': "Error" })

      //TODO:Show alert
    }
  }

  const [alertData, updateAlert] = useState({
    display: false,
    title: "",
    servere: "",
    msg: "",
  });

  const [user, setUser] = useState({ email: "", pass: "", reset_pass: "" });
  const [forgotPassDialog, showForgotPassDialog] = useState({ enabled: false, passSent: "false" });
  return auth.currentUser ? (
    <></>
  ) : (
    <div id="login-parent">
      <Dialog open={forgotPassDialog.enabled}

      ><DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {forgotPassDialog.passSent == "false" ? <>
            <Input
              Name={"reset_pass"}
              isRequired={true}
              Type="email"
              OnChange={(l) => setUser({ ...user, ["reset_pass"]: l.target.value })}
              htmlFor={"reset_pass"}
              TextVal={"Enter your Email Address"}
            />

            <input
              type={"submit"}
              onClick={async (l) => {
                showForgotPassDialog({ ...forgotPassDialog, passSent: 'sending' })

                // console.log(reset_pass);
                await sendPasswordResetEmail(auth, user.reset_pass).then((e => {
                  showForgotPassDialog({ ...forgotPassDialog, passSent: 'sent' })

                })).catch(console.log)
              }}
              value={"Email Link"}
              className="w-full h-12 text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
            /> </>
            : <p>An email has been sent for further process (if the account exists in our database) ( Please check the spam folder as well ) </p>}


        </DialogContent><DialogActions><Button onClick={(e) => {
          showForgotPassDialog({ enabled: false, passSent: 'false' })
        }}>CLOSE</Button></DialogActions>
      </Dialog>
      <Dialog open={alertData.display} ><DialogTitle>{alertData.title}</DialogTitle>
        <DialogContent>{alertData.msg} </DialogContent><DialogActions><Button onClick={(e) => {
          updateAlert(newAlertObj)
        }}>CLOSE</Button></DialogActions>
      </Dialog>
      {/* <Alert severity={alertData.servere}>
        <AlertTitle>{alertData.title}</AlertTitle>
        {alertData.msg}
      </Alert> */}
      <div id="login-card">
        <div id="login-card-inner">
          <p className="font-bold text-center text-3xl">LOGIN</p>
          <br />
          <form>
            <Input
              Name={"email"}
              isRequired={true}
              Type="email"
              htmlFor={"email"}
              TextVal={"Enter your Email Address"}
              OnChange={(e) => (setUser({ ...user, email: e.target.value }))}
            />
            <Input
              Name={"password"}
              isRequired={true}
              Type="password"
              OnChange={(e) => (setUser({ ...user, pass: e.target.value }))}
              htmlFor={"password"}
              TextVal={"Enter your Password"}
            />
            <input
              type={"submit"}
              onClick={login}
              value={"Login"}
              className="w-full h-12 text-xs md:text-base px-6 py-1 bg-indigo-500 rounded-lg text-white font-bold"
            />{" "}
          </form>          <br></br>

          <button onClick={(e) => {

            e.preventDefault();
            showForgotPassDialog({ enabled: true, passSent: 'false' })



          }} className="w-full text-blue-600 text-right">Forgot Password?</button><br />

        </div>
      </div>
    </div>
  );
}

export default LoginPage;
