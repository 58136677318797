import Footer from "./components/footer";
import Navbar from "./components/navbar";
import HomePage from "./pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Submit from "./components/form/submit";
import TermsandCond from "./pages/terms";
import PatientAD from "./components/form/patientAD";
import Form from "./components/form/form";
import React from "react";
import About from "./components/form/About";
import Contact from "./components/Contact";
import Background from "./components/form/Background";
import IntroductionRational from "./components/form/IntroductionRational";
import PakTest from "./components/form/PakTest";
import UserGuide from "./components/form/UserGuide";
import DietrySurvey from "./components/form/DietrySurvey";
import UrineSurvey from "./components/form/UrineSurvey";
import Updates from "./components/form/Updates";
import PatientDetailsFromServer from "./components/form/PatientDetailFromServer";
import LoginPage from "./components/login/LoginPage";
import { getAuth } from "firebase/auth";
import PageNotFound from "./PageNotFound";
import AdminDashBoard from "./components/admin/Dashboard";
import axios, { Axios } from "axios";
import ProtectedRoute from "./ProtectedRoute";

// axios.defaults.baseURL = 'http://localhost:5000'

function App() {
  let [urinedata, setuc] = React.useState({
    calcuria_recommendation: {},
    urea_recommendation: {},

    calcium_oxalate_recommendation: {},
    hyperoxaluria_recommendation: {},
    cystine_recommendation: {},
    citrate_recommendation: {},
    uric_recommendation: {},
    cabapatite_recommendation: {},
    struvite_recommendation: {},
    brushite_recommendation: {},
    intake: {
      // milk: {},
      // protien: {},
      // yog: {},
      // chocolate: {},
      // sugar: {},
      // cheese: {},
      // supp: {},
      // mineral_water_calc: {},
      // tap_water_calc: {},
    },
  });

  const [user, setUser] = React.useState({
    amount_water_intake: "",
    salt_intake: [],
    specify_salt_one: "",
    type_of_stone: [],
    age: "",
    sex: "",
    weight: "",
    height: "",
    history_family_stone_disease: "",
    history_stone_disease: "",
    allergies: "",
    previous_abdominal: [],
    tobacco_use: "",
    blood_creatinine: "",
    blood_calcium: "",
    ionized_calcium: "",
    fasting_glucose: "",
    blood_uric_acid: "",
    blood_na: "",
    blood_potassium: "",
    magnesium: "",
    phosphate: "",
    chloride: "",
    parathyroid_hormone: "",
    calcitriol: "",
    blood_bicarbonate: "",
    triglycerides: "",
    cholesterol: "",
    urine_culture_bacteria: "",
    urine_creatininura: "",
    urine_creatininura_upper: "",
    last_urine_volume: "",
    urine_calcium: "",
    urine_sodium: "",
    urine_oxalate: "",
    urine_urea_levels: "",
    urine_urea_upper: "",
    urine_uric_acid: "",
    citraturia: "",
    cystinuria: "",
    urine_ph: "",
    morning_urine_specific_gravity: "",
    urine_crystals: "",
    urine_culture: "",
    glucosuria: "",
    calcium_intake: "",
    oxalate_intake: "",
    specify_dietary_one: "",
    carbohydrate_intake: "",
    causes_for_hyperUricosuria: "",
    protein_intake: "",
    for_cystinuria_patients: "",
    specify_dietary_two: "",
    // patient_id_blood advise
    blood_creatinine_p: "",
    blood_calcium_p: "",
    ionized_calcium_p: "",
    fasting_glucose_p: "",
    blood_uric_acid_p: "",
    blood_na_p: "",
    blood_potassium_p: "",
    magnesium_p: "",
    phosphate_p: "",
    chloride_p: "",
    parathyroid_hormone_p: "",
    calcitriol_p: "",
    blood_bicarbonate_p: "",
    triglycerides_p: "",
    cholesterol_p: "",
    // patient_urine_advise
    urine_creatininura_p: "",
    last_urine_volume_p: "",
    urine_calcium_p: "",
    urine_sodium_p: "",
    urine_oxalate_p: "",
    urine_urea_levels_p: "",
    urine_uric_acid_p: "",
    citraturia_p: "",
    cystinuria_p: "",
    background_conditions: [],
    medications: [],
    medications_others: "",
    genetic_abnormalities: [],
    genetic_abnormalities_others: "",
  });

  const [load, setLoad] = React.useState(true);

  const auth = getAuth();

  const handleChange = (e) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    if (!isNaN(value) && value.length > 0) {
      value = parseFloat(value);
    }
    setUser({ ...user, [name]: value });
  };
  return (
    <div>
      <Navbar></Navbar>
      <div>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/getpatient" element={<ProtectedRoute component={<PatientDetailsFromServer />} />} />
          <Route exact path="/terms" element={<TermsandCond />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/background" element={<Background />} />
          <Route path="/user-guide" element={<UserGuide />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dietry-survey"
            element={<DietrySurvey user={user} handleChange={handleChange} />}
          />{" "}
          <Route
            path="/urine-survey"
            element={
              <UrineSurvey
                user={user}
                handleChange={handleChange}
              ></UrineSurvey>
            }
          />
          <Route
            path="/introduction-rational"
            element={<IntroductionRational />}
          />
          <Route path="/pak-test" element={<PakTest />} />
          <Route
            path="/form"
            element={
              <Form user={user} handleChange={handleChange} setUser={setUser} />
            }
          />
          <Route
            path="/doctoradvice"
            element={
              <Submit
                user={user}
                handleChange={handleChange}
                urinedata={urinedata}
                setuc={setuc}
              />
            }
          />
          <Route path="/updates" element={<Updates></Updates>} />
          <Route
            path="/patientadvice"
            element={<PatientAD user={user} urinedata={urinedata} />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
