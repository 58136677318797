import gif from "./loading_gif.gif";
export default function LoadingGif() {
  return (
    <div className="flex justify-center items-center h-full align-middle mt-40 m-auto">
      <img src={gif} style={{
        height: '50px',
        width:'50px'
      }} />
    </div>
  );
}
