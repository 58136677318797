import React, { useState } from "react";
import DisplayAd from "../form/DisplayAd";
import Popup from "./popup";

function Data(props) {
  const [data] = useState([
    {
      key: "blood_creatinine",
      content: [
        {
          patientAD:
            "Patient referred to Urinary system Ultrasound to rule out obstruction and nephrologic evaluation",
          name: "blood_creatinine_p",
          popup:
            "Search etiology for Elevated blood creatinine levels with imaging and nephrologic evaluation",
        },
      ],
    },
    {
      title: (
        <div className="font-bold ml-8">
          <p>
            99% of body Calcium is stored in the bones and 1% is in the blood
            (normal 8.5-10.5 mg/dL or 2.1-2.6 mmol/L)
          </p>
          <p>
            If hypercalcemia is present, rule out HyperParathyroidism [{">"}
            85picogram/liter]
          </p>{" "}
          <p>
            Primary Causes: Parathyroid Adenoma ({">"}90% of Primary causes) or
            Parathyroid cancer (rare)
          </p>{" "}
          <p>
            {" "}
            If parathyroid elevated refer to endocrinologic evaluation to rule
            out parathyroid adenoma or cancer
          </p>
        </div>
      ),
      key: "blood_calcium",
      content: [
        {
          patientAD: "None",
          name: "blood_calcium_p",
          popup: "Ignore HyperCalcemia Evaluation",
        },
        {
          patientAD: `Repeat Blood Calcium test and Correct for albumin if needed\n -Perform a blood test for ParaThyroid levels\n-Perform a Chest x-ray to rule out a lung mass/ sarcoidosis)\n-Refer to Nephrologist for the performance of Pak Test if ParaThyroid levels normal - to diagnose early stage of HyperParathyroidism`,
          name: "blood_calcium_p",
          popup: (
            <>
              <p>Confirm HyperCalcemia Evaluation</p>

              <ul>
                <li>
                  {
                    " - Repeat Blood Calcium test and Correct for albumin if needed"
                  }
                </li>
                <li> - Perform a blood test for ParaThyroid levels</li>
                <li>
                  - Perform a Chest x-ray to rule out a lung mass/ sarcoidosis)
                </li>
                <li>
                  - Refer to Nephrologist for the performance of Pak Test if
                  ParaThyroid levels normal - to diagnose early stage of
                  hyperParathyroidism
                </li>
              </ul>
            </>
          ),
        },
      ],
    },

    {
      title: (
        <div className="font-bold ml-8">
          <p>
            99% of body Calcium is stored in the bones and 1% is in the blood
            (normal 8.5-10.5 mg/dL or 2.1-2.6 mmol/L)
          </p>
          <p>
            If hypercalcemia is present, rule out HyperParathyroidism [{">"}
            85picogram/liter]
          </p>{" "}
          <p>
            Primary Causes: Parathyroid Adenoma ({">"}90% of Primary causes) or
            Parathyroid cancer (rare)
          </p>{" "}
          <p>
            {" "}
            If parathyroid elevated refer to endocrinologic evaluation to rule
            out parathyroid adenoma or cancer
          </p>
        </div>
      ),
      key: "ionized_calcium",
      content: [
        {
          patientAD: "None",
          name: "ionized_calcium_p",
          popup: "Ignore HyperCalcemia Evaluation",
        },
        {
          patientAD: `Repeat Blood Calcium test and Correct for albumin if needed\n-Perform a blood test for ParaThyroid levels\n-Perform a Chest x-ray to rule out a lung mass/ sarcoidosis)\n-Refer to Nephrologist for the performance of Pak Test if ParaThyroid levels normal - to diagnose early stage of HyperParathyroidism`,
          name: "ionized_calcium_p",
          popup: (
            <>
              <p>Confirm HyperCalcemia Evaluation</p>

              <ul>
                <li>
                  {
                    " - Repeat Blood Calcium test and Correct for albumin if needed"
                  }
                </li>
                <li> - Perform a blood test for ParaThyroid levels</li>
                <li>
                  - Perform a Chest x-ray to rule out a lung mass/ sarcoidosis)
                </li>
                <li>
                  - Refer to Nephrologist for the performance of Pak Test if
                  ParaThyroid levels normal - to diagnose early stage of
                  hyperParathyroidism
                </li>
              </ul>
            </>
          ),
        },
      ],
    },

    {
      key: "fasting_glucose",
      content: [
        {
          patientAD:
            "Patient referred to treating physician to evaluate high blood glucose levels and rule out Diabetes Mellitus",
          name: "fasting_glucose_p",
          popup:
            "Patient referred to treating physician to evaluate high blood glucose levels and rule out Diabetes Mellitus",
        },
      ],
    },
    {
      key: "ionized_calcium_low",
      content: [
        {
          patientAD: "Repeat test for blood calcium levels",
          name: "ionized_calcium_low",
          popup: "Repeat test for blood calcium levels",
        },
      ],
    },
    {
      key: "blood_calcium_low",
      content: [
        {
          patientAD: "Repeat test for blood calcium levels",
          name: "blood_calcium_low",
          popup: "Repeat test for blood calcium levels",
        },
      ],
    },
    {
      key: "blood_uric_acid",
      content: [
        {
          patientAD:
            "Avoid intake of foods with high uric acid content:\n-  Animal Meat Products/Eggs/Fish/Anchovie\n-  Alcoholic beverage\n-  Avoid excessive carbohydrate intake / Fructose / Sweetened beverage\n-  Keep urine pH in normal value and not acidic (Acidic urine can promote the formation of uric acid stones)",
          //   "Avoid intake of foods with high uric acid content:\n - Animal Meat Products\n - Alcoholic beverages Avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)\n Patient referred to personal physician to explore elevated uric acid levels and consider treatment with allopurinol",
          name: "blood_uric_acid_p",
          popup:
            "Refer Patient to personal physician to explore elevated uric acid levels and consider treatment with allopurinol ",
        },
      ],
    },
    {
      key: "blood_uric_acid_normal",
      content: [
        {
          patientAD:
            "No additional comment (instructions already in general recommendations)",
          name: "blood_uric_acid_p",
          popup:
            "Instruct patient to avoid intake of foods with high uric acid content:- Animal Meat Products - Alcoholic beverages Instruct patient to avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
        },
      ],
    },
    {
      key: "blood_na_down",
      content: [
        {
          patientAD: "Patient referred to repeat blood sodium level test",
          name: "blood_na_p",
          popup:
            "Repeat blood sodium levels In emergency if patient symptomatic (headache-nausea)",
        },
      ],
    },
    {
      key: "blood_na_high",
      content: [
        {
          patientAD: "Patient referred to repeat blood sodium level test",
          name: "blood_na_p",
          popup:
            "Repeat blood sodium levels In emergency if patient symptomatic (headache-nausea)",
        },
      ],
    },
    {
      key: "blood_potassium_low",
      content: [
        {
          patientAD: "Patient referred to repeat blood potassium level test",
          name: "blood_potassium_p",
          popup:
            "Repeat blood sodium levels In emergency if patient symptomatic (headache-nausea-muscle cramps)",
        },
      ],
    },
    {
      key: "blood_potassium_high",
      content: [
        {
          patientAD: "Patient referred to repeat blood potassium level test",
          name: "blood_potassium_p",
          popup: "Repeat blood Potassium levels In emergency department",
        },
      ],
    },
    {
      key: "magnesium_low",
      content: [
        {
          patientAD: "Patient referred to repeat blood magnesium level test",
          name: "magnesium_p",
          popup:
            "Repeat blood sodium levels In emergency if patient symptomatic (headache-nausea-muscle cramps)",
        },
      ],
    },
    {
      key: "magnesium_high",
      content: [
        {
          patientAD: "Patient referred to repeat blood magnesium level test",
          name: "magnesium_p",
          popup: "Repeat blood Potassium levels",
        },
      ],
    },
    {
      key: "phosphate_low",
      content: [
        {
          patientAD:
            "Patient referred to repeat blood phosphate level test And Parathyroid testing",
          name: "phosphate_p",
          popup:
            "Repeat blood phosphate levels Consider the diagnosis of Primary HyperParathyroidism if also hypercalcemia found",
        },
      ],
    },
    {
      key: "phosphate_high",
      content: [
        {
          patientAD: "Patient referred to repeat blood phosphate level test",
          name: "phosphate_p",
          popup: "Repeat blood phosphate levels",
        },
      ],
    },
    {
      key: "chloride_low",
      content: [
        {
          patientAD: "Patient referred to repeat blood Chloride level test",
          name: "chloride_p",
          popup: "Repeat blood Chloride levels",
        },
      ],
    },
    {
      key: "chloride_high",
      content: [
        {
          patientAD: "Patient referred to repeat blood Chloride level test",
          name: "chloride_p",
          popup: "Repeat blood Chloride levels",
        },
      ],
    },
    {
      key: "blood_bicarbonate_low",
      content: [
        {
          patientAD: "Patient referred to repeat blood Bicarbonate level test",
          name: "blood_bicarbonate_p",
          popup: "Repeat blood Bicarbonate levels",
        },
      ],
    },
    {
      key: "blood_bicarbonate_high",
      content: [
        {
          patientAD: "Patient referred to repeat blood Bicarbonatelevel test",
          name: "blood_bicarbonate_p",
          popup: "Repeat blood Bicarbonate levels",
        },
      ],
    },
    {
      key: "calcitriol",
      content: [
        {
          patientAD:
            "Patient referred to repeat blood Calcitriol hormone level test and continue follow up with endocrinologist",
          name: "calcitriol_p",
          popup:
            "Repeat Calcitriol hormone levels Rule out excessive Vitamin D intake ",
        },
      ],
    },
    {
      key: "triglycerides",
      content: [
        {
          patientAD:
            "Patient referred to repeat blood Triglycerides level test and continue follow up with personal physician",
          name: "triglycerides_p",
          popup:
            "Repeat Triglycerides levels Balance metabolic syndrome risk factors",
        },
      ],
    },
    {
      key: "parathyroid_hormone",
      content: [
        {
          patientAD:
            "Patient referred to repeat blood parathyroid hormone level test and continue follow up with endocrinologist",
          name: "parathyroid_hormone_p",
          popup: "Repeat Parathyroid hormone levels",
        },
      ],
    },
    {
      key: "cholesterol",
      content: [
        {
          patientAD:
            "Patient referred to repeat blood Cholesterol level test and continue follow up with personal physician",
          name: "cholesterol_p",
          popup:
            "Repeat Cholesterol levels Balance metabolic syndrome risk factors",
        },
      ],
    },

    // urine result popup
    {
      key: "urine_creatininura_normal",
      content: [
        {
          patientAD: "Normal urine collection",
          name: "urine_creatininura_p",
          popup: "Normal urine collection",
        },
      ],
    },
    {
      key: "urine_creatininura_low",
      content: [
        {
          patientAD: "Valid urine collection",
          name: "urine_creatininura_p",
          popup: "Verified",
        },
        {
          patientAD: "Repeat 24 hours urine collection",
          name: "urine_creatininura_p",
          popup: "Repeat 24 hours urine collection",
        },
      ],
    },
    {
      key: "urine_creatininura_high",
      content: [
        {
          patientAD: "Repeat 24 hours urine collection",
          name: "urine_creatininura_p",
          popup: "Repeat 24 hours urine collection",
        },
        {
          patientAD: "Valid urine collection",
          name: "urine_creatininura_p",
          popup: "Verified",
        },
      ],
    },
    {
      key: "last_urine_volume_up",
      content: [
        {
          patientAD:
            "Keep sufficient fluid intake to acquire >1900ml urine/day",
          name: "last_urine_volume_p",
          popup:
            "Validate Keep sufficient fluid intake to acquire >1900ml urine/day",
        },
      ],
    },
    {
      key: "last_urine_volume_down",
      content: [
        {
          patientAD: "Increase fluid intake to acquire >1900ml urine/day",
          name: "last_urine_volume_p",
          popup: "Validate Increase fluid intake to acquire >1900ml urine/day",
        },
      ],
    },
    {
      key: "urine_sodium_up",
      content: [
        {
          patientAD:
            "Avoid foods and beverages with high salt content: \n-Table Salt\n-Sparkling water: Vichy, St Yorre, San Peligrino\n-Sauces (Ketchup, Soya)",
          name: "urine_sodium_p",
          popup:
            "Verify cause of high daily salt intake (>7gr daily) :- Table Salt - Sparkling water: Vichy, St Yorre, San Peligrino - Sauces (Ketchup, Soya) - Other- Please Specify",
        },
      ],
    },
    {
      key: "urine_sodium_down",
      content: [
        {
          patientAD:
            "Instruct patient to avoid high salt intake: Table Salt Sparkling water: Vichy, St Yorre, San Peligrino Sauces (Ketchup, Soya)",
          name: "urine_sodium_p",
          popup:
            "No additional comment (instructions already in general recommendations)",
        },
      ],
    },
    {
      key: "urine_oxalate_up",
      content: [
        {
          patientAD:
            "Avoid Excessive consumption of foods/beverages with high oxalate content\n-Spinach\n- Rhubarb\n- Cacao\n- Cashew / Nuts/ Hazelnuts/ Pistachios\n- Sesame\n- Tea (mainly Black Tea) \n- (Orange) Juice\n- Avoid excess Vitamin C Consumption\nMaintain the recommended daily Calcium intake (1gr/d)",
          name: "urine_oxalate_p",
          popup:
            "Verify cause of high oxalate content in urine: 1) Is patient taking the recommended daily Ca (1gr/d)? Low calcium intake will cause more oxalate to be excreted in the urine instead of through the intestines. 2) Check for Excess Dietary Oxalate Intake in the dietary survey. Spinach Rhubarb Cacao Cashew / Nuts/ Hazelnuts/ Pistachios Sesame Tea (mainly Black Tea) (Orange) Juice (glasses/day) Validate-don’t validate. 3) Rule out excess Vitamin C Consumption? Vitamin C metabolism increases oxalate levels in the body. 4) Check for possible Short bowel syndrome or previous Gastric bypass surgery? If positive make sure there is: sufficient Calcium intake per day and give Ca supplement with meals if necessary (warn patients against Constipation) Keep patient on Low fat diet. If positive-add comment in others. 5) Rule out long term Antibiotic treatment recently? Long term antibiotic treatment eradicates the bacteria Oxalobacter formigenes in the intestine and might indirectly cause increased oxalate resorption. If positive-add comment in others       6 Rule out known history of Primary HyperOxaluria in the family: Search for family members with early calcium oxalate stone disease and renal insufficiency due to stone disease. If positive-add comment in others",
        },
      ],
    },
    {
      key: "urine_oxalate_down",
      content: [
        {
          patientAD:
            "No additional comment (instructions already in general recommendations",
          name: "urine_oxalate_p",
          popup:
            "Instruct patient to avoid Excessive consumption of foods/beverages with high oxalate content Spinach Rhubarb Cacao Cashew / Nuts/ Hazelnuts/ Pistachios Sesame Tea (mainly Black Tea) (Orange) Juice Avoid excess Vitamin C Consumption (Vitamin C metabolism increases oxalate levels in the body) ",
        },
      ],
    },
    {
      key: "urine_urea_levels_up",
      content: [
        {
          patientAD:
            "Avoid high daily protein intake (<0.8gr/kg/day): Animal Meat Products, Excessive intake of Dairy Products and Vegetables rich in Protein, Eggs",
          name: "urine_urea_levels_p",
          popup:
            "Verify cause of high urine urea- Probably high daily protein intake (>1.1gr/kg/day): - Animal Meat Products - A 100 gram steak has ~25% protein in it - Dairy Products and Vegetable Protein - Eggs ( 1 egg is ~30 grams of Protein)",
        },
      ],
    },
    {
      key: "urine_urea_levels_down",
      content: [
        {
          patientAD:
            "No additional comment (instructions already in general recommendations",
          name: "urine_urea_levels_p",
          popup:
            "Instruct patient to avoid high protein intake (<0.8gr/kg/day): - Animal Meat Products - Dairy Products and Vegetable Protein - Eggs",
        },
      ],
    },
    {
      key: "urine_uric_acid_up",
      content: [
        {
          patientAD:
            "Avoid intake of foods with high uric acid content: - Animal Meat Products - Alcohol Avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
          name: "urine_uric_acid_p",
          popup:
            "Verify causes for high urine uric acid levels and check urine pH. Causes for HyperUricosuria (High Urine Uric Acid) High Animal Protein Intake, High Fish/Anchovies consumption Alcohol - Carbohydrate Intake Soda Drinks (e.g. Coca-Cola 330ml=36.25grams) Juices (~22gram/200mL->4-5 sugar pieces) Chocolate (Pieces/day) Table Sugar (grams/day)",
        },
        {
          patientAD:
            "Avoid intake of foods with high uric acid content: - Animal Meat Products - Alcohol Avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
          name: "urine_uric_acid_p",
          popup:
            "Verify causes for high urine uric acid levels and check urine pH. 1-Rule out Myeloproliferative Disease / Treatment as cause to high urine uric acid (High turnover-destruction of body cells) Other Factors: 2- Insulin Resistance? 3-Acidic urine pH (can promote formation of uric acid stones): check urine pH levelin morning spot urine and keep it between 6-6.5 4-Gout? 5-Medications: Thiazide Diuretics, Aspirin",
        },
        {
          patientAD:
            "Avoid intake of foods with high uric acid content: - Animal Meat Products - Alcohol Avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
          name: "urine_uric_acid_p",
          popup:
            "Verify causes for high urine uric acid levels and check urine pH. HyperUricemia & Uricosuria can result from: Increased consumption: Seafood/Animal Meat (uric acid from catabolism of cells) High Alcohol consumption Increased Production of uric acid from High Fructose intake: High corn syrup beverages consumption, uncontrolled Diabetes Mellitus",
        },
        {
          patientAD:
            "Avoid intake of foods with high uric acid content: - Animal Meat Products - Alcohol Avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
          name: "urine_uric_acid_p",
          popup:
            "Verify causes for high urine uric acid levels and check urine pH. High turnover of Purines (Chemo/RadioTx), Genetic Predisposition Factors promoting uric acid crystallization in urine:- Decreased Clearance [Dehydration- Low fluid intake- low diuresis, Diarrhea (Loss of fluid volume) etc.]- Increased Acid load in the body[(Insulin Resistance, Diarrhea (loss of Bicarbonate)]",
        },
      ],
    },
    {
      key: "urine_uric_acid_down",
      content: [
        {
          patientAD:
            "No additional comment (instructions already in general recommendations)",
          name: "urine_uric_acid_p",
          popup:
            "Instruct patient to avoid intake of foods with high uric acid content: - Animal Meat Products - Instruct patient to avoid excessive carbohydrate intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
        },
      ],
    },
    {
      key: "citraturia_down",
      content: [
        {
          patientAD: "Continue follow up with nephrologist",
          name: "citraturia_p",
          popup:
            "If there is low urine citrate and renal insufficiency  or acidic urine pH- refer to nephrologist.",
        },
        {
          patientAD:
            "Treatment with Potassium Citrate as prescribed and follow up of urine pH.",
          name: "citraturia_p",
          popup:
            "If urine pH<6 without other abnormalities treat with Potassium Citrate",
        },
      ],
    },
    {
      key: "citraturia_up",
      content: [
        {
          patientAD:
            "No additional comment (instructions already in general recommendations)",
          name: "citraturia_p",
          popup:
            "Instruct patient to keep sufficient intake of fruits and vegetables",
        },
      ],
    },
    {
      key: "cystinuria_down",
      content: [
        {
          patientAD:
            "No additional comment (instructions already in general recommendations)",
          name: "cystinuria_p",
          popup: "If patient without background of cystinuria- no pop up",
        },
        {
          patientAD:
            "Avoid intake of foods with high cystine content: - Parmesan Cheese - Eggs Keep Fluid Intake > 3.5 Liters/day Avoid High protein Intake/ High salt intake Keep urine pH in normal value and not acidic (can promote Cystine stones)",
          name: "cystinuria_p",
          popup:
            "If patient known to have Cystinuria and previous cystine stones:Instruct patient to avoid intake of foods with high cystine content:\n - Parmesan Cheese \n- Eggs \nKeep Fluid Intake > 3.5 Liters/day. \nAvoid High protein Intake/ High salt intake Keep urine pH in normal value and not acidic (can promote Cystine stones)",
        },
      ],
    },
    {
      key: "cystinuria_up",
      content: [
        {
          patientAD:
            "Avoid intake of foods with high cystine content: - Parmesan Cheese - Eggs Keep Fluid Intake > 3.5 Liters/day (urine output>3 Liters/day) Avoid High protein Intake/ High salt intake Keep urine pH in normal value and not acidic (can promote uric acid stones)",
          name: "cystinuria_p",
          popup:
            "Check urine pH (needs to be>6.5) and warn against calcium potential development of calcium phosphate stones Check dietary intake responsible for high urine Cystine: - Parmesan Cheese - Eggs - Fluid Intake < 3.5 Liters day - High protein Intake/ High salt intake",
        },
      ],
    },
    {
      key: "urine_calcium_normal",
      content: [
        {
          patientAD: "No specific recommendation",
          name: "urine_calcium_p",
          popup:
            "Always verify patient is not eliminating calcium from diet and keeping the recommended 800-1200 mg per day intake of calcium (~3 milk products/day).",
        },
      ],
    },
    {
      key: "HyperCalcuria_alone",
      content: [
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more thanotn 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary",
          name: "urine_calcium_p",
          popup:
            "Check Dietary calcium intake and make sure the limit of 1200 mg per day is not exceeded: Dairy Products: - Milk [glass (~200mg)/day] - Yogurt [Pieces(~200mg)/day] - Water Ca (mg)/Liter/day [Vittel, Badoit (~200mg/L) Calcium Rich mineral water] - Tap Water Ca (mg)/day (~100mg/L) - Cheese [200mg Ca per ~30gr cheese (1 Product)] - Calcium Supplements ** If Hypercalciuria continuous despite good control of calcium intake: consider performing the Pak test to find cause or refer to nephrologist for Pak test performance.",
        },
        {
          patientAD: "Patient referred to perform Pak test",
          name: "urine_calcium_p",
          popup:
            "If HyperCalciuria with Normocalcemia: Consider performing Pak Test (Calcium Load Test) to diffrentiate between: Intestinal Level (Absorptive) or Renal Level (Renal leak) or masked 1°HyperPTH Control Calcium Intake",
        },
        {
          patientAD: "Treatment with Hydrochlorothiazide 25mgX1/day",
          name: "urine_calcium_p",
          popup:
            "•If Hypercalciuria continues- After Pak test - Treat with Thiazides (e.g. HCTZ 25-50mg/d) To reduce HyperCalciuria Warn pts against Side Effects: headache, lethargy & Hypokalemia",
        },
        {
          patientAD:
            "Patient referred to rheumatologist to consider performance of DEXA scan to rule out osteoporosis",
          name: "urine_calcium_p",
          popup:
            "If long-term hypercalcuria don't neglect risk of Osteoporosis/ Osteopenia and consider checking DEXA scan ",
        },
        {
          patientAD:
            "Treatment with Potassium Citrate 20 mEq X3/day and follow up urine pH",
          name: "urine_calcium_p",
          popup:
            "• Check urine pH: If necessary (pH is <5.9-6.0): supplement K-Citrate (50-200mEg/d) * Maintain pH in normal limits (6-6.5)",
        },
      ],
    },
    {
      key: "HyperCalciuria_HyperNatruria",
      content: [
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •Avoid consumption of more thanotn 3 milk products/day •Avoid calcium rich mineral water (e.g. Vittel-Badoit) •Avoid calcium supplements if not necessary •Avoid foods and beverages with high salt content: •Table Salt •Sparkling water: Vichy, St Yorre, San Peligrino •Sauces (Ketchup, Soya)",
          name: "urine_calcium_p",
          popup:
            "Check Dietary calcium intake and make sure the limit of 1200 mg per day is not exceeded:Dairy Products: - Milk [glass (~200mg)/day] - Yogurt [Pieces(~200mg)/day] - Water Ca (mg)/Liter/day [Vittel, Badoit (~200mg/L)Calcium Rich mineral water] - Tap Water Ca (mg)/day (~100mg/L) - Cheese [200mg Ca per ~30gr cheese (1 Product)] - Calcium Supplements Verify cause of high daily salt intake (>7gr daily) : - Table Salt - Sparkling water: Vichy, St Yorre, San Peligrino - Sauces (Ketchup, Soya) - Other- Please Specify",
        },
      ],
    },
    {
      key: "urine_ph_high",
      content: [
        {
          patientAD: "None",
          name: "urine_ph_high",
          popup: "Ignore high urine pH",
        },
        {
          patientAD:
            "Refer to Nephrologist to Rule out possibility of Renal tubular acidosis and Consider urine acidification with oral treatment. Follow up urine pH in next visit",
          name: "urine_ph_high",
          popup:
            "Confirm recommendation to Refer to Nephrologist to Rule out possibility of Renal tubular acidosis and Consider urine acidification with oral treatment and Follow up urine pH in next visit",
        },
      ],
    },
    {
      key: "urine_glucosuria_up",
      content: [
        {
          patientAD:
            "Patient referred to Check blood glucose test and to treating physician for the evaluation of Glucosuria",
          name: "urine_glucosuria_up",
          popup:
            "Check blood glucose levels and refer patient to treating physician for the evaluation of Glucosuria",
        },
      ],
    },
    {
      key: "urine_culture_up",
      content: [
        {
          patientAD: "Patient referred to repeat urine culture exam",
          name: "urine_culture_up",
          popup:
            "Refer to patient to repeat urine culture exam and antibiotic treatment",
        },
      ],
    },
    {
      key: "urine_gravity_high",
      content: [
        {
          patientAD:
            "Increase daily urine output through increased fluid intake",
          name: "urine_gravity_high",
          popup:
            "Recommend patient to increase daily urine output through increased fluid intake",
        },
      ],
    },
    {
      key: "urine_ph_low",
      content: [
        {
          patientAD:
            "Consider adding treatment with K-Citrate to increase urine pH",
          name: "urine_ph_low",
          popup:
            "Consider adding treatment with K-Citrate to increase urine pH",
        },
      ],
    },
    {
      key: "HyperCalciuria_High_urine_urea",
      content: [
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more than 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary •AND •Avoid high daily protein intake (<0.8gr/kg/day): •- Animal Meat Products •- Excessive intake of Dairy Products and Vegetables rich in Protein •- Eggs",
          name: "urine_calcium_p",
          popup:
            "Check Dietary calcium intake and make sure the limit of 1200 mg per day is not exceeded: Dairy Products: - Milk [glass (~200mg)/day] - Yogurt [Pieces(~200mg)/day] - Water Ca (mg)/Liter/day [Vittel, Badoit (~200mg/L)Calcium Rich mineral water] - Tap Water Ca (mg)/day (~100mg/L) - Cheese [200mg Ca per ~30gr cheese (1 Product)] - Calcium Supplements Verify cause of high urine urea- Probably high daily protein intake (>1.1gr/kg/day): - Animal Meat Products - A 100 gram steak has ~25% protein in it - Dairy Products and Vegetable Protein - Eggs ( 1 egg is ~30 grams of Protein)",
        },
      ],
    },
    {
      key: "HyperCalciuria_Hypercalcemia",
      content: [
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more than 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary Suspected hyper-parathyroidism: Patient referred to endocrinologist for further follow up and treatment.",
          name: "urine_calcium_p",
          popup:
            "Rule out HyperParathyroidism [>85picogram/liter] •(by far the most common cause for resorptive hypercalciruia and hypercalcemia): •Primary Causes: •- Parathyroid Adenoma >90% of Primary causes •or •- Parathyroid cancer (rare) If parathyroid elevated refer to endocrinologic evaluation to rule out parathyroid adenoma or cancer (rare).",
        },
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day Avoid consumption of more than 3 milk products/day calcium rich mineral water (e.g. Vittel-Badoit) ium supplements if not necessary Increased Bone resorption as a possible cause for hypercalciuria: Patient Referred to treating physician and Rheumatologist for further follow up and treatment of possible bone resorption",
          name: "urine_calcium_p",
          popup:
            "Increased Bone resorption as a cause for hypercalciuria : •- Prolonged immobilization •- Increased constant acid load in the body - (Insulin resistance/high protein intake..) -> The suggested mechanism is that it causes the release of Calcium from bone to buffer the acid •- Oncologic patients: Osteolytic bone Metastasis •- Oncologic patients: Parathyroid-related protein secreting malignancy •- Chronic steroid use •- HyperThyroidism •- Paget’s disease of bone (accelerated bone remodeling -> deformities, bone pain) •If one of these reasons suspected to cause hypercalciuria : Refer to treating physician and Rheumatologist for further follow up and treatment of possible bone resorption.",
        },
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more than 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary Referred to laboratory testing of vitamin D levels and continue follow up with treating physician and nephrologist",
          name: "urine_calcium_p",
          popup:
            "Excessive Vitamin D ssive intake opic Production: Granulamatous Disorders: Sarcoidosis, Tuberculosis, lymphoma atient takes vitamin D supplement or patient with known tumor diagnosed: Check vitamin D levels and if high refer to treating physician and nephrologist",
        },
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more than 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary Potential Lithium Treatment toxicity: referred to treating physician for further follow up and treatment.",
          name: "urine_calcium_p",
          popup:
            "Lithium Treatment toxicity (Increases suppression threshold of PTH by calcium -> takes a higher Calcium level to suppress PTH) If patient on chronic lithium treatment- refer to treating physician for further follow up and treatment.",
        },
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more than 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary Referred to nephrologist for further follow up and treatment due to possible Thiazide-induced hypercalcemia.",
          name: "urine_calcium_p",
          popup:
            "If Chronic Thiazide use - Chronic treatment with thiazide diuretic might be a cause for hypercalcemia- refer to nephrologist and consider stopping thiazide.",
        },
        {
          patientAD:
            "Keep calcium intake within normal limits (not higher than 1200mg/day) and not lower than 800mg/day •- Avoid consumption of more than 3 milk products/day •- Avoid calcium rich mineral water (e.g. Vittel-Badoit) •- Avoid calcium supplements if not necessary Suspected Familial Hypocalciuria-Hypercalcemia syndrome– Referred to nephrologist for further work up and diagnosis.",
          name: "urine_calcium_p",
          popup:
            "Very rare- If patient has family members with urinary stones and hypercalcemia: consider the diagnosis of Familial Hypocalciuria-Hypercalcemia syndrome (Increases suppression threshold of PTH by calcium) – If suspected refer to nephrologist for further work up and diagnosis. ",
        },
      ],
    },
  ]);
  return (
    <div>
      {data.map(({ key, ...otherprops }) => (
        <div>
          {key === props.value && (
            <Popup
              key={key}
              {...otherprops}
              popup={props.popup}
              setPopup={props.setPopup}
              user={props.user}
              handleChange={props.handleChange}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default Data;
