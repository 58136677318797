import React from "react";
import PropTypes from "prop-types";

function Input({
  htmlFor,
  TextVal,
  Type,
  IsDisabled ,
  Id,
  Name,
  Value,
  OnChange,
  isRequired,
  Placeholder,
  Required,
  defaultValue
}) {
  return (
    <div className="relative mb-4 text-left ">
      <label htmlFor={htmlFor} className="leading-7 text-sm text-gray-600">
        {TextVal} {(isRequired && <span className="text-red-500">*</span>)}
      </label>
      <input
        type={Type}
        id={Id}
     
        onKeyDown={(e) => {
          if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
          }
            }}
      name={Name}
        onWheel={(e) => {
          return e.target.blur();
        }}
        
        disabled={IsDisabled}
        value={Value}
        onChange={OnChange}
        defaultValue={defaultValue}
        required={Required}
        placeholder={Placeholder}
        className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    </div>
  );
}

// Input.PropTypes = {
//   htmlFor: PropTypes.string,
//   TextVal: PropTypes.string,
//   Type: PropTypes.string,
//   Id: PropTypes.string,
//   Name: PropTypes.string,
//   Value: PropTypes.string,
//   OnChange: PropTypes.func,
//   Placeholder: PropTypes.string,
// };
export default Input;
